import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const RedirectToShopify = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.location.href = 'https://60e595-b6.myshopify.com';
  }, [navigate]);

  return null;
};

export default RedirectToShopify;
