import React, { useState, useEffect } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import SlideItem from './SlideItem';
import './EditSlideshowModal.css';
import FileSelectionModal from './FileSelectionModal';

function EditSlideshowModal({ slideshow, onClose, onSave }) {
    const [slides, setSlides] = useState(slideshow.slides || []);
    const [slideshowName, setSlideshowName] = useState(slideshow.name);
    const [fadeDuration, setFadeDuration] = useState(slideshow.fade_duration || 1);
    const [loading, setLoading] = useState(false);
    const [loadingText, setLoadingText] = useState('Uploading, please wait...');
    const [showFileModal, setShowFileModal] = useState(false);
    const [slidesChanged, setSlidesChanged] = useState(false);
    

    useEffect(() => {
        const fetchSlides = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/slideshows/${slideshow.id}/slides/`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                });
                const data = await response.json();
                
                if (response.ok) {
                    setSlides(data);
                } else {
                    console.error("Error fetching slides:", data);
                }
            } catch (error) {
                console.error("Error fetching slides:", error);
            }
        };
        
        fetchSlides();
    }, [slideshow.id]);

    const handleSaveChanges = async () => {
        console.log("Slides before saving:", slides);
        try {
            for (let index = 0; index < slides.length; index++) {
                const slide = slides[index];
                await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/slides/${slide.id}/`, {
                    method: 'PUT',
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        duration: slide.duration,
                        image_url: slide.image_url,
                        order: index,
                        start_date: slide.start_date,
                        end_date: slide.end_date,
                    })
                });
            }

            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/slideshows/${slideshow.id}/`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    name: slideshowName,
                    profile: slideshow.profile,
                    slides: slides.map(slide => slide.id),
                    fade_duration: fadeDuration
                })
            });
            const data = await response.json();
            if (response.ok) {
                console.log("Slideshow updated successfully:", data);
                return data;
            } else {
                console.error("Error updating slideshow:", data);
            }
        } catch (error) {
            console.error("Error updating slideshow:", error);
        }
    };

    const handleSave = async () => {
        setLoadingText('Saving, please wait...'); // Set the loading text to "Saving"
        setLoading(true); // Show loading spinner
    
        const updatedSlideshow = await handleSaveChanges();
        
        if (onSave) {
            onSave(updatedSlideshow);
        }
        
        setLoading(false); // Hide loading spinner
        onClose();
    };

    const handleAddSlide = () => {
        setShowFileModal(true); // Only open the modal
    };

    /*const handleAddSlide = async () => {
        setShowFileModal(true);
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = '.jpeg,.jpg,.png,.mp4,.avi,.mkv,.mov';
        fileInput.multiple = true; // Allow multiple file selection
    
        fileInput.onchange = async (event) => {
            const files = event.target.files;
            setLoading(true); // Show loading spinner
    
            try {
                // Loop through the selected files and upload them one by one
                for (let i = 0; i < files.length; i++) {
                    let file = files[i];
                    console.log("Original file name:", file.name);

                    // Sanitize the file name by replacing + and other special characters
                    let safeFileName = file.name.replace(/[^\w.-]/g, '_'); // Replace non-alphanumeric and dot characters with underscore
                    console.log("Sanitized file name:", safeFileName);

                    // Create a new file with the sanitized name
                    file = new File([file], safeFileName, { type: file.type });

                    console.log("Selected file type:", file.type);
    
                    const allowedTypes = [
                        'image/jpeg', 
                        'image/jpg', 
                        'image/png', 
                        'video/mp4', 
                        'video/x-msvideo', 
                        'video/avi',        
                        'video/x-matroska', 
                        'video/quicktime'   
                    ];
    
                    if (file && allowedTypes.includes(file.type)) {
                        const formData = new FormData();
                        formData.append('file', file);
    
                        const uploadResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/upload_file/`, {
                            method: 'POST',
                            body: formData,
                            headers: {
                                'Authorization': `Bearer ${localStorage.getItem('token')}`
                            }
                        });
    
                        const jsonResponse = await uploadResponse.json();
                        if (uploadResponse.ok) {
                            const now = new Date();
                            const oneMonthFromNow = new Date(new Date().setMonth(now.getMonth() + 12));
                            const timezoneOffset = now.getTimezoneOffset() * 60000;
                            const localNow = new Date(now - timezoneOffset);
                            const localOneMonthFromNow = new Date(oneMonthFromNow - timezoneOffset);
                            const start_date = localNow.toISOString().slice(0, -1);
                            const end_date = localOneMonthFromNow.toISOString().slice(0, -1);
    
                            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/create_slide/`, {
                                method: 'POST',
                                headers: {
                                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify({
                                    slideshow_id: slideshow.id,
                                    order: slides.length + i, // Order the slide properly
                                    start_date,
                                    end_date,
                                    image_url: jsonResponse.fileURL
                                })
                            });
    
                            const newSlide = await response.json();
                            if (response.ok) {
                                setSlides((prevSlides) => [...prevSlides, { ...newSlide, image_url: jsonResponse.fileURL }]);
                            }
                        }
                    }
                }
            } catch (error) {
                console.error("Error uploading files:", error);
            } finally {
                setLoading(false); // Hide loading spinner after upload completes
            }
        };
    
        fileInput.click();
    }; */
    
    
    

    const handleRemoveSlide = async (indexToRemove) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/slides/${slides[indexToRemove].id}/`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });

            if (!response.ok) {
                throw new Error('Failed to delete the slide');
            }

            const updatedSlides = slides.filter((_, index) => index !== indexToRemove);
            setSlides(updatedSlides);
        } catch (error) {
            console.error('Error deleting slide:', error);
        }
    };

    const handleMove = (dragIndex, hoverIndex) => {
        const dragSlide = slides[dragIndex];
        const updatedSlides = [...slides];
        updatedSlides.splice(dragIndex, 1);
        updatedSlides.splice(hoverIndex, 0, dragSlide);
        setSlides(updatedSlides);
    };

    const handleFileSelect = async (selectedFiles) => {
        setLoading(true);
        setShowFileModal(false); // Close modal
        
        try {
            const now = new Date();
            //console.error('Now:', now);
            const nowcopy = new Date(now.getTime());
            const oneMonthFromNow = new Date(nowcopy.setMonth(nowcopy.getMonth() + 12));
            //console.error('onemonthfromnow:', oneMonthFromNow);
            const timezoneOffset = now.getTimezoneOffset() * 60000;
            //console.error('timezoneoffset:', timezoneOffset);
            const start_date = new Date(now - timezoneOffset).toISOString().slice(0, -1);
            //console.error('Start Date:', start_date);
            const end_date = new Date(oneMonthFromNow - timezoneOffset).toISOString().slice(0, -1);
            //console.error('End Date:', end_date);
    
            // Loop through each selected file and create a slide for each
            for (let i = 0; i < selectedFiles.length; i++) {
                const file = selectedFiles[i];
                const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/create_slide/`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        slideshow_id: slideshow.id,
                        order: slides.length + i, // Order each new slide correctly
                        start_date,
                        end_date,
                        image_url: file.url,
                        duration: 10,
                    })
                });
    
                const newSlide = await response.json();
                if (response.ok) {
                    setSlides((prevSlides) => [...prevSlides, { ...newSlide, image_url: file.url }]);
                    setSlidesChanged(true);
                } else {
                    console.error("Error creating slide:", newSlide);
                }
            }
        } catch (error) {
            console.error("Error selecting files:", error);
        } finally {
            setLoading(false);
        }
    };
    
    
    const handleUploadNewFile = async () => {
        // Execute original handleAddSlide logic to upload new files
        setShowFileModal(false); // Close modal
        // call original handleAddSlide functionality
    };

    return (
        <div className="edit-modal">
            {loading && (
                <div className="loading-modal">
                    <div className="loading-modal-spinner"></div>
                    <p className="loading-text">{loadingText}</p>
                </div>
            )}
    
                {showFileModal && (
                <>
                    <div className="modal-overlay" onClick={() => setShowFileModal(false)}></div>
                    <FileSelectionModal 
                        onClose={() => setShowFileModal(false)} 
                        onFileSelect={handleFileSelect} 
                        onUploadNewFile={handleUploadNewFile}
                    />
                </>
            )}
            <h2>Edit Slideshow</h2>
            <div className="modal-header">
                <div className="input-group">
                    <input 
                        type="text" 
                        value={slideshowName} 
                        onChange={(e) => setSlideshowName(e.target.value)} 
                        placeholder="Slideshow Name"
                    />
                </div>
                <div className="input-group single-digit">
                    {/*
                    <label>Fade Duration (s):</label>
                    <input 
                        type="number"
                        value={fadeDuration}
                        onChange={(e) => setFadeDuration(e.target.value)}
                        min="0"
                    />
                    */}
                </div>
                <button className="slideshow-modal-button add-slide" onClick={handleAddSlide}>Add Slide</button>
            </div>
            
            <DndProvider backend={HTML5Backend}>
                <div className="slide-container">
                    {slides.map((slide, index) => (
                        <SlideItem 
                            slide={slide} 
                            index={index} 
                            key={slide.id}
                            onMove={handleMove}
                            onSlideChange={(updatedSlide) => {
                                const updatedSlides = [...slides];
                                updatedSlides[index] = updatedSlide;
                                setSlides(updatedSlides);
                            }}
                            onRemove={() => handleRemoveSlide(index)}
                        />
                    ))}
                </div>
            </DndProvider>
            <div className="modal-footer">
                <button 
                    className="slideshow-modal-button save" 
                    onClick={handleSave}
                    disabled={loading} // Disable when loading
                >
                    <i className="fa fa-floppy-o" aria-hidden="true"></i> Save
                </button>
                
                <button 
                    className="slideshow-modal-button close" 
                    onClick={onClose}
                    disabled={slidesChanged} // Disable close button if slides have changed
                >
                    <i className="fa fa-times" aria-hidden="true"></i> Close
                </button>
            </div>
        </div>
    );
}

export default EditSlideshowModal;
