import React, { useState } from 'react';
import './PricingPage.css';
import { useNavigate } from 'react-router-dom';

function PricingPage() {
    const navigate = useNavigate();
    const [isYearly, setIsYearly] = useState(false);

    // Setting up individual pricing and features for each plan
    const [plans, setPlans] = useState([
        {
            name: 'Enterprise',
            monthlyPrice: '$499',
            yearlyPrice: '$399',
            features: [
                'Advanced Reorder Reporting',
                'Live Customer Queue Line',
                'NextGen Dealsheet Creator',
                { feature: 'Remote Digital Signage', detail: 'unlimited screens' },
                'QR Generator & Tracker',
                'Digital Customer Facing Menu'
            ]
        },
        {
            name: 'Premium',
            monthlyPrice: '$299',
            yearlyPrice: '$249',
            features: [
                'Live Customer Queue Line',
                'NextGen Dealsheet Creator',
                { feature: 'Remote Digital Signage', detail: '5 screens' },
                'QR Generator & Tracker',
                'Digital Customer Facing Menu'
            ]
        },
        {
            name: 'Basic',
            monthlyPrice: '$99',
            yearlyPrice: '$79',
            features: [
                'Digital Customer Facing Menu',
                'QR Generator & Tracker',
                { feature: 'Remote Digital Signage', detail: '2 screens' },
                { feature: 'NextGen Dealsheet Creator', detail: 'LIMITED', limited: true }
            ]
        }
        
    ]);

    const handleSignUp = () => {
        navigate('/waitlist');
    }

    return (
        <div className='scrolltainer'>
        <div className='settings-div-container'>
            <div className="pricing-container">
                
                <div className="plans">
                    {/* This is the new "plan" box for the pricing label and selector */}
                    <div className="plan header-plan">
                        <img src='GreenmindSimpleLogo.png' style={{width: '100%'}} alt="Description of the Image" />
                        <img src='HeaderImg.png' style={{width: '100%'}} alt="Description of the Image" />
                        <h2>Pricing</h2>
                        <div>
                            <button className={`toggle-button ${isYearly ? 'yearly' : ''} greenmind-btn`} onClick={() => setIsYearly(!isYearly)}>
                                {isYearly ? 'Yearly' : 'Monthly'}
                            </button>
                        </div>
                    </div>
    
                    {/* The rest of the plans */}
                    {plans.map(plan => (
                        <div key={plan.name} className="plan">
                            <h2>{plan.name}</h2>
                            <p className='PriceInfoPrice'>{isYearly ? plan.yearlyPrice : plan.monthlyPrice}</p>
                            <p>{isYearly ? "/month (Billed Yearly)" : "/month"}</p>
                            <p>Per Store</p>
                            <button className='greenmind-btn' onClick={handleSignUp}>Sign Up</button>
                            <ul>
                                {plan.features.map(item => {
                                    const featureText = typeof item === 'object' ? item.feature : item;
                                    return <li key={featureText}>{featureText}</li>;
                                })}
                            </ul>
                            
                        </div>
                    ))}
                </div>
                    <table className="feature-table">
                    <thead>
                        <tr>
                            <th>Features</th>
                            {plans.map(plan => <th key={plan.name}>{plan.name}</th>)}
                        </tr>
                    </thead>
                    <tbody>
                    {[

                        'QR Generator & Tracker',
                        'Digital Customer Facing Menu',
                        'Remote Digital Signage',
                        'NextGen Dealsheet Creator',
                        'Live Customer Queue Line',
                        'Advanced Reorder Reporting'

                    ].map(featureName => (
                        <tr key={featureName}>
                            <td>{featureName}</td>
                            {plans.map(plan => {
                                const feature = plan.features.find(f => (typeof f === 'object' ? f.feature : f) === featureName);
                                const isFeatureObject = feature && typeof feature === 'object';
                                return (
                                    <td key={plan.name} className={feature ? 'feature-yes' : 'feature-no'}>
                                        {feature ? (isFeatureObject ? (feature.limited ? <span style={{ color: 'grey' }}>{feature.detail}</span> : `✓ ${feature.detail}`) : '✓') : '×'}
                                    </td>
                                );
                            })}
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
        </div>
    );
}

export default PricingPage;
