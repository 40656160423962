import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './StoreSelectionPage.css';

function SlideshowSelectionPage() {
    const navigate = useNavigate();
    const [slideshows, setSlideshows] = useState([]);
    const slideshowRefs = useRef([]);
    const [focusedButtonIndex, setFocusedButtonIndex] = useState(0);
    const store = JSON.parse(localStorage.getItem('selectedStore'));

    useEffect(() => {
        if (store) {
            fetchSlideshows(store.id);
        } else {
            navigate('/');
        }
    }, [store?.id, navigate]);

    useEffect(() => {
        slideshowRefs.current = slideshowRefs.current.slice(0, slideshows.length);
    }, [slideshows]);

    useEffect(() => {
        const handleKeyDown = (e) => {
            let newIndex;
            if (e.key === 'ArrowDown') {
                newIndex = (focusedButtonIndex + 1) % slideshows.length;
                setFocusedButtonIndex(newIndex);
            } else if (e.key === 'ArrowUp') {
                newIndex = (focusedButtonIndex - 1 + slideshows.length) % slideshows.length;
                setFocusedButtonIndex(newIndex);
            } else if (e.key === 'Enter') {
                handleSlideshowSelect(slideshows[focusedButtonIndex]);
            }

            if (newIndex !== undefined) {
                slideshowRefs.current[newIndex].focus();
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, [slideshows, focusedButtonIndex]);

    const fetchSlideshows = async (storeId) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/slideshows/?store_id=${storeId}`, {
                headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}`, 'Selected-Store-Id': storeId }
            });
            if (!response.ok) {
                throw new Error('Failed to fetch slideshows');
            }
            const slideshowsData = await response.json();
            setSlideshows(slideshowsData);
        } catch (error) {
            console.error("Error fetching slideshows:", error);
        }
    };

    const handleSlideshowSelect = async (slideshow) => {
        localStorage.setItem('currentSlideshowId', slideshow.id);
    
        // Assuming the device info is stored with a key like 'selectedDevice' in localStorage
        const device = JSON.parse(localStorage.getItem('selectedDevice'));
        if (device) {
            device.type = 'slideshow';
            device.target = slideshow.id;
            // Update the device in localStorage
            localStorage.setItem('selectedDevice', JSON.stringify(device));
    
            // Optionally, send this updated device info to your backend
            await updateDevice(device);
        }
        navigate('/slideshowplayer');
    };
    
    const updateDevice = async (device) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/devices/update`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify(device)
            });
            if (!response.ok) {
                throw new Error('Failed to update device');
            }
            const updatedDevice = await response.json();
            console.log('Device updated successfully:', updatedDevice);
        } catch (error) {
            console.error("Error updating device:", error);
        }
    };
    

    return (
        <div className="store-selection-page">
            <div className='tv-wrapper'>
                <h2 className="title select-title">Choose Your Slideshow</h2>
                <div className="select-button-container">
                    {slideshows.map((slideshow, index) => (
                        <button 
                            key={slideshow.id}
                            className={`slideshow-button select-store-button ${index === focusedButtonIndex ? 'focused' : ''}`}
                            onClick={() => handleSlideshowSelect(slideshow)}
                            ref={el => slideshowRefs.current[index] = el}
                            tabIndex={0}
                        >
                            {slideshow.name}
                        </button>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default SlideshowSelectionPage;