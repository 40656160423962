import React, { useState, useEffect } from 'react';
import './SyncModal.css';

function SyncModal({ isOpen, onClose, slideshows, onSync, selectedSlideshow, setSelectedSlideshow,
    startTime, setStartTime,
    endTime, setEndTime,
    durationDays, setDurationDays,
    daysOffset, setDaysOffset,
    smartScheduleEnabled, setSmartScheduleEnabled, startDate, endDate, setStartDate, setEndDate
}) {

    useEffect(() => {
        if (!smartScheduleEnabled) {
          // Set to current date and time in local timezone
          const now = new Date();
          const startDateLocal = formatLocalDateTime(now);
          const oneYearLater = new Date(now.setFullYear(now.getFullYear() + 1));
          const endDateLocal = formatLocalDateTime(oneYearLater);
    
          setStartDate(startDateLocal);
          setEndDate(endDateLocal);
        }
      }, [smartScheduleEnabled, setStartDate, setEndDate]);
    
      useEffect(() => {
        if (smartScheduleEnabled && daysOffset !== '') {
          const startDateTime = calculateStartDateTime(daysOffset, startTime);
          setStartDate(startDateTime);
    
          const endDateTime = calculateEndDateTime(startDateTime, durationDays, endTime);
          setEndDate(endDateTime);
        }
      }, [smartScheduleEnabled, daysOffset, startTime, durationDays, endTime, setStartDate, setEndDate]);
    
      const formatLocalDateTime = (date) => {
        return date.getFullYear() +
          '-' +
          String(date.getMonth() + 1).padStart(2, '0') +
          '-' +
          String(date.getDate()).padStart(2, '0') +
          'T' +
          String(date.getHours()).padStart(2, '0') +
          ':' +
          String(date.getMinutes()).padStart(2, '0');
      };

    const calculateStartDateTime = (offsetDays, time) => {
        // Check if the time string is valid (HH:MM format)
        if (!time.match(/^([01]\d|2[0-3]):([0-5]\d)$/)) {
            return '';
        }
    
        const start = new Date();
        start.setDate(start.getDate() + parseInt(offsetDays, 10));
        const [hours, minutes] = time.split(':');
        start.setHours(hours, minutes, 0, 0); // Reset seconds and milliseconds
    
        // Convert to local ISO string
        const timezoneOffset = start.getTimezoneOffset() * 60000; // offset in milliseconds
        return (new Date(start - timezoneOffset)).toISOString().slice(0, 16);
    };
    
    const calculateEndDateTime = (startDateTime, duration, time) => {
        // Check if the time string is valid (HH:MM format)
        if (!time.match(/^([01]\d|2[0-3]):([0-5]\d)$/)) {
            return '';
        }
    
        // Check if duration is a valid number
        const durationDays = parseInt(duration, 10);
        if (isNaN(durationDays) || durationDays < 1) {
            return ''; // Return empty or a default value
        }
    
        const end = new Date(startDateTime);
        end.setDate(end.getDate() + durationDays);
        const [hours, minutes] = time.split(':');
        end.setHours(hours, minutes, 0, 0); // Reset seconds and milliseconds
    
        // Convert to local ISO string
        const timezoneOffset = end.getTimezoneOffset() * 60000; // offset in milliseconds
        return (new Date(end - timezoneOffset)).toISOString().slice(0, 16);
    };
    
    

    const handleSyncConfirm = () => {
        const syncDetails = {
            startTime,
            endTime,
            durationDays,
            daysOffset
        };
    
        onSync(selectedSlideshow, startDate, endDate, syncDetails, smartScheduleEnabled);
        onClose();
    };
    const handleChange = (e) => {
        const selectedId = e.target.value;
        const selected = slideshows.find(slideshow => slideshow.id.toString() === selectedId);
        setSelectedSlideshow(selected);
    };

    if (!isOpen) return null;

    return (
        <div className="modal-background center-modal">
            <div className="conf-modal-content">
                <h2 className='conf-h2'>Sync to Slideshow</h2>

                <div className='sync-dropdown'>
                    <select value={selectedSlideshow?.id || ''} onChange={handleChange}>
                        <option value="">Select Slideshow</option>
                        {slideshows.map((slideshow) => (
                            <option key={slideshow.id} value={slideshow.id}>{slideshow.name}</option>
                        ))}
                    </select>
                </div>

                <div className='sync-dates'>
                    <input type="datetime-local" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                    <input type="datetime-local" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                </div>

                <label>
                    <input
                        type="checkbox"
                        checked={smartScheduleEnabled}
                        onChange={(e) => setSmartScheduleEnabled(e.target.checked)}
                    />
                    Enable SmartSchedule
                </label>

                {smartScheduleEnabled && (
                    <div>
                        <div>
                            Start in <input type="number" value={daysOffset} onChange={(e) => setDaysOffset(e.target.value)} min="0" /> days at <input type="time" value={startTime} onChange={(e) => setStartTime(e.target.value)} />
                        </div>
                        <div>
                            Play for <input type="number" value={durationDays} onChange={(e) => setDurationDays(e.target.value)} min="1" /> days until <input type="time" value={endTime} onChange={(e) => setEndTime(e.target.value)} />
                        </div>
                    </div>
                )}

                <div className="modal-buttons">
                    <button className="confirm-button conf-button" onClick={handleSyncConfirm}>Sync</button>
                    <button className="cancel-button conf-button" onClick={onClose}>Cancel</button>
                </div>
            </div>
        </div>
    );
}

export default SyncModal;
