import React, { useState } from 'react';
import './MinMaxPriceModal.css';

const MinMaxPriceModal = ({ isOpen, onClose, onApply }) => {
  const [minPrice, setMinPrice] = useState('');
  const [maxPrice, setMaxPrice] = useState('');

  const handleSubmit = () => {
    onApply(minPrice, maxPrice);
    onClose();
  };

  return (
    isOpen && (
      <div className="minmax-modal-overlay">
        <div className="minmax-modal">
          <h2>Filter by Unit Cost</h2>
          <div className="minmax-modal-content">
            <label>
              Min Price:
              <input
                type="number"
                value={minPrice}
                onChange={(e) => setMinPrice(e.target.value)}
              />
            </label>
            <label>
              Max Price:
              <input
                type="number"
                value={maxPrice}
                onChange={(e) => setMaxPrice(e.target.value)}
              />
            </label>
            <button onClick={handleSubmit}>Submit</button>
            <button onClick={onClose}>Cancel</button>
          </div>
        </div>
      </div>
    )
  );
};

export default MinMaxPriceModal;
