import React, { useState } from 'react';
import './IndividualDealInfoModal.css';  // Add appropriate styling

const IndividualDealInfoModal = ({ deal, dealInfo, onClose, maxHitsInCategory }) => {
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
    const [viewMode, setViewMode] = useState('sku'); // Toggle between 'sku' and 'brand'

    // Sort function based on the sort configuration
    const sortedSkuList = [...dealInfo.SkuList].sort((a, b) => {
        if (sortConfig.key) {
            if (a[sortConfig.key] < b[sortConfig.key]) {
                return sortConfig.direction === 'ascending' ? -1 : 1;
            }
            if (a[sortConfig.key] > b[sortConfig.key]) {
                return sortConfig.direction === 'ascending' ? 1 : -1;
            }
        }
        return 0;
    });

    const sortBrandList = (brandList) => {
        return [...brandList].sort((a, b) => {
            if (sortConfig.key) {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
            }
            return 0;
        });
    };

    // Function to handle sorting on click
    const requestSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    // Group SKUs by brand and calculate aggregated values
    const getBrandList = () => {
        const brandMap = {};
        let totalUsedWeek = 0; // To track the total used/week across all brands

        dealInfo.SkuList.forEach(sku => {
            const brand = sku.brandName || 'Unknown Brand'; // Use a fallback if no brand is provided
            if (!brandMap[brand]) {
                brandMap[brand] = {
                    totalQty: 0,
                    totalUsedWeek: 0,
                    totalUnitCost: 0,
                    totalPrice: 0,
                    skuCount: 0
                };
            }

            brandMap[brand].totalQty += sku.qtyAvailable;
            brandMap[brand].totalUsedWeek += sku.usedThisWeek;
            brandMap[brand].totalUnitCost += sku.UnitCost;
            brandMap[brand].totalPrice += sku.price;
            brandMap[brand].skuCount += 1;

            totalUsedWeek += sku.usedThisWeek;
        });

        return {
            brandList: Object.keys(brandMap).map(brand => ({
                brandName: brand,
                avgUnitCost: (brandMap[brand].totalUnitCost / brandMap[brand].skuCount).toFixed(2),
                avgPrice: (brandMap[brand].totalPrice / brandMap[brand].skuCount).toFixed(2),
                totalQty: brandMap[brand].totalQty,
                totalUsedWeek: brandMap[brand].totalUsedWeek
            })),
            totalUsedWeek
        };
    };

    // Function to generate emoji breakdown messages
    const getEmojiMessages = () => {
        const messages = [];

        // Hottest selling deal
        if (dealInfo.UsedWeek >= maxHitsInCategory) {
            messages.push('🔥 This is your hottest selling deal for the category.');
        }

        // Low stock warning
        if (dealInfo.DaysSupply <= 3) {
            messages.push(`⚠️ You have a ${dealInfo.DaysSupply} day(s) supply of this product remaining.`);
        }

        // Low usage (Snowflake)
        if (dealInfo.UsedWeek < 5) {
            messages.push(`❄️ This discount was used ${dealInfo.UsedWeek} time(s) this week.`);
        }

        // Expiring deals
        if (dealInfo.ExpirationStatus !== "") {
            messages.push(`💀 This deal expires in less than 3 days.`);
        }

        return messages;
    };

    const emojiMessages = getEmojiMessages();
    const { brandList, totalUsedWeek } = getBrandList();
    const sortedBrandList = sortBrandList(brandList);

    return (
        <div className="individual-modal-backdrop">
            <div className="individual-modal-content">
                <button className="individual-modal-close-btn" onClick={onClose}>X</button>
                <div className="individual-modal-terminal-content">
                    {/* Title */}
                    <h3 className="deal-title">{deal.description}</h3>

                    {/* Emoji breakdown section */}
                    {emojiMessages.length > 0 && (
                        <div className="emoji-breakdown">
                            <ul>
                                {emojiMessages.map((message, index) => (
                                    <li key={index}>{message}</li>
                                ))}
                            </ul>
                        </div>
                    )}

                    {/* Horizontally spaced deal-wide information */}
                    <div className="deal-info-horizontal">
                        <p><strong>Active:</strong> {dealInfo.DaysRunning} Days</p>
                        <p><strong>Avg Cost:</strong> ${dealInfo.AvgCost.toFixed(2)}</p>
                        <p><strong>Supply:</strong> {dealInfo.DaysSupply.toFixed(0)} Days</p>
                        <p><strong>Supply:</strong> {dealInfo.DiscountSupply.toFixed(0)} Ct</p>
                        <p><strong>Used/Wk:</strong> {dealInfo.UsedWeek}</p>
                    </div>

                    {/* Label for SKU List / Brand List */}
                    <div className="view-toggle-label" onClick={() => setViewMode(viewMode === 'sku' ? 'brand' : 'sku')}>
                        <h3>
                            <span className={`toggle-label ${viewMode === 'sku' ? 'active' : 'inactive'}`}>SKU List</span>/
                            <span className={`toggle-label ${viewMode === 'brand' ? 'active' : 'inactive'}`}>Brand List</span>
                        </h3>
                    </div>


                    {/* Display either SKU List or Brand List */}
                    <div className="sku-list-container">
                        {viewMode === 'sku' ? (
                            sortedSkuList.map((sku, index) => {
                                // Cap the usage percentage at 100%
                                const usagePercentage = Math.min((sku.usedThisWeek / dealInfo.UsedWeek) * 100, 100);

                                return (
                                    <div key={index} className="sku-item">
                                        <div className="sku-name-and-progress">
                                            <p className="sku-name" onClick={() => requestSort('skuName')}>
                                                <strong>{sku.skuName}</strong>
                                            </p>
                                            <div 
                                                className="progress-container" 
                                                title={`SKU appeared ${sku.usedThisWeek} times in ${dealInfo.UsedWeek} discounted transactions this week`}
                                                onClick={() => requestSort('usedThisWeek')}
                                            >
                                                <div className="progress-bar" style={{ width: `${usagePercentage}%` }}></div>
                                            </div>
                                        </div>
                                        {/* Horizontally spaced SKU data */}
                                        <div className="sku-details">
                                            <p onClick={() => requestSort('UnitCost')}>Cost: ${sku.UnitCost.toFixed(2)}</p>
                                            <p onClick={() => requestSort('price')}>Price: ${sku.price.toFixed(2)}</p>
                                            <p onClick={() => requestSort('qtyAvailable')}>Qty: {sku.qtyAvailable.toFixed(0)}</p>
                                            <p onClick={() => requestSort('usedThisWeek')}>Used/Wk: {sku.usedThisWeek.toFixed(0)}</p>
                                        </div>
                                    </div>
                                );
                            })
                        ) : (
                            sortedBrandList.map((brand, index) => {
                                const brandUsagePercentage = (brand.totalUsedWeek / totalUsedWeek) * 100;
                                return (
                                    <div key={index} className="sku-item">
                                        <div className="sku-name-and-progress">
                                            <p className="sku-name" onClick={() => requestSort('brandName')}>
                                                <strong>{brand.brandName}</strong>
                                            </p>
                                            {/* Progress bar for brand usage */}
                                                <div className="progress-container" title={`Brand used ${brand.totalUsedWeek} times this week`} onClick={() => requestSort('totalUsedWeek')}>   
                                                <div className="progress-bar" style={{ width: `${brandUsagePercentage}%`}}></div>
                                            </div>
                                        </div>
                                        {/* Horizontally spaced Brand data */}
                                        <div className="sku-details">
                                            <p onClick={() => requestSort('avgUnitCost')}>Avg Cost: ${brand.avgUnitCost}</p>
                                            <p onClick={() => requestSort('avgPrice')}>Avg Price: ${brand.avgPrice}</p>
                                            <p onClick={() => requestSort('totalQty')}>Stock: {brand.totalQty.toFixed(0)} ct</p>
                                        </div>
                                    </div>
                                );
                            })
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default IndividualDealInfoModal;
