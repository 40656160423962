import React, { useState, useEffect } from 'react';
import './ConfirmationModal.css'; // Assuming the same CSS can be used

function SecureConfirmationModal({ isOpen, onClose, onConfirm, title, message }) {
  const [inputValue, setInputValue] = useState('');
  const confirmEnabled = inputValue === "permanently delete";

  useEffect(() => {
    if (isOpen) {
      setInputValue('');
    }
  }, [isOpen]);

  if (!isOpen) return null;

  

  const handleConfirmClick = () => {
    if (confirmEnabled) {
      onConfirm();
    }
  };

  return (
    <div className="modal-background center-modal">
      <div className="conf-modal-content">
        <h2 className='conf-h2'>{title}</h2>
        <p className='conf-p'>{message}</p>
        <p>Please type <strong>permanently delete</strong> to confirm.</p>
        <input
          type="text"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          className="confirm-input"
          placeholder="Type here"
        />
        <div className="modal-buttons">
          <button className="confirm-button conf-button" onClick={handleConfirmClick} disabled={!confirmEnabled}>
            Permanently Delete
          </button>
          <button className="cancel-button conf-button" onClick={onClose}>Cancel</button>
        </div>
      </div>
    </div>
  );
}

export default SecureConfirmationModal;
