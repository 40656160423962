import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Signup.css';

const ResetPassword = () => {
    const [email, setEmail] = useState('');
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Step 1: Authenticate the user
        try {
            const loginResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/jwt-login/`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ username: email, password: currentPassword }),
            });
            const loginData = await loginResponse.json();

            if (!loginResponse.ok) {
                throw new Error('Authentication failed.');
            }

            // Step 2: Reset the password
            const resetResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/reset-password/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${loginData.access}` // Use the token from login
                },
                body: JSON.stringify({ current_password: currentPassword, new_password: newPassword }),

            });

            const resetData = await resetResponse.json();

            if (!resetResponse.ok) {
                throw new Error(resetData.detail || 'Failed to reset password');
            }

            // Redirect to login page after successful password reset
            navigate('/login');
        } catch (error) {
            console.error('Error:', error);
            setError(error.message || 'Error resetting password');
        }
    };

    return (
        <div className="div-container">
            <div className="page-container">
                <h1 className="signup-title">Reset Password</h1>
                <form onSubmit={handleSubmit} className="signup-form">
                    <div className="email-password-container">
                        <input
                            type="email"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="name-input"
                        />
                        <input
                            type="password"
                            placeholder="Current Password"
                            value={currentPassword}
                            onChange={(e) => setCurrentPassword(e.target.value)}
                            className="name-input"
                        />
                        <input
                            type="password"
                            placeholder="New Password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            className="name-input"
                        />
                    </div>
                    <button type="submit" className="submit-button">Reset Password</button>
                </form>
                {error && <div className="error-message">{error}</div>}
            </div>
        </div>
    );
};

export default ResetPassword;
