import React, { useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import ImagePreviewModal from './ImagePreviewModal';
import './SlideItem.css';

// Function to check if the file is a video
const isVideoFile = (url) => {
    const videoExtensions = ['.mp4', '.avi', '.mkv', '.mov'];
    return videoExtensions.some(ext => url.toLowerCase().endsWith(ext));
};

// Get the video type based on the file extension
const getVideoType = (url) => {
    const extension = url.split('.').pop().toLowerCase();
    if (extension === 'mp4') {
        return 'video/mp4';
    }
    return ''; // Return empty string for unsupported video types
};

// Component to display a placeholder video icon, now sized to 50x50
const VideoIcon = () => (
    <div style={{ width: '50px', height: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#f0f0f0' }}>
        <i className="fa fa-video-camera" style={{ fontSize: '20px', color: '#888' }}></i>
    </div>
);

const SlideItem = ({ slide, index, onSlideChange, onRemove, onMove }) => {
    const [duration, setDuration] = useState(slide.duration);
    const [imageUrl, setImageUrl] = useState(slide.image_url);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const ref = useRef(null);

    const formatDate = (date) => {
        if (typeof date === 'string') {
            return date.slice(0, 16);
        }
        return date.toISOString().slice(0, 16);
    };

    const defaultStartDate = formatDate(slide.start_date || new Date());
    const defaultEndDate = formatDate(slide.end_date || new Date(new Date().setMonth(new Date().getMonth() + 1)));

    const [startDate, setStartDate] = useState(defaultStartDate);
    const [endDate, setEndDate] = useState(defaultEndDate);

    const handleStartDateChange = (e) => {
        const newStartDate = e.target.value;
        setStartDate(newStartDate);
        onSlideChange({ ...slide, start_date: newStartDate });
    };

    const handleEndDateChange = (e) => {
        const newEndDate = e.target.value;
        setEndDate(newEndDate);
        onSlideChange({ ...slide, end_date: newEndDate });
    };

    const handleDurationChange = (e) => {
        const newDuration = e.target.value;
        setDuration(newDuration);
        onSlideChange({ ...slide, duration: newDuration });
    };

    const [, drop] = useDrop({
        accept: 'SLIDE',
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;

            if (dragIndex === hoverIndex) {
                return;
            }

            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;

            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }

            onMove(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: 'SLIDE',
        item: { type: 'SLIDE', index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    drag(drop(ref));

    const handlePreviewClick = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    // Check if the file is a video but not mp4 (to show the video icon)
    const isNotMp4Video = isVideoFile(imageUrl) && !imageUrl.toLowerCase().endsWith('.mp4');

    // Extract the file name and extension from the image URL
    const fileName = imageUrl.split('/').pop();
    const fileNameWithoutExt = fileName.substring(0, fileName.lastIndexOf('.'));
    const fileExtension = fileName.substring(fileName.lastIndexOf('.'));

    // Limit file name to 15 characters and add ellipsis
    const truncatedFileName = fileNameWithoutExt.length > 15 
        ? `${fileNameWithoutExt.substring(0, 15)}...${fileExtension}` 
        : fileName;

    return (
        <>
            <div ref={ref} className="slide-item" style={{ opacity: isDragging ? 0.5 : 1 }}>
                <div className="slide-item-left">
                    <span>{index + 1}</span>
                    {isVideoFile(imageUrl)  
                        ? (isNotMp4Video
                            ? <VideoIcon />  // Render video icon for non-mp4 formats
                            : <div onClick={handlePreviewClick} style={{ cursor: 'pointer' }}>
                                <video width="50" height="50" muted>
                                    <source src={imageUrl} type={getVideoType(imageUrl)} />
                                    Your browser does not support the video tag.
                                </video>
                              </div>
                          )
                        : <img src={imageUrl} alt={`Slide ${index + 1}`} onClick={handlePreviewClick} />}
                    
                    {isVideoFile(imageUrl) 
                        ? <div className='video-name'>{truncatedFileName}</div>  // Display truncated video name for videos
                        : (
                            <>
                                <label className='slide-duration' htmlFor={`duration-${index}`}>Slide Duration (s):</label>
                                <input 
                                    id={`duration-${index}`}
                                    type="number" 
                                    value={duration} 
                                    onChange={handleDurationChange} 
                                    placeholder="Seconds"
                                    className="duration-input input-group single-digit"
                                />
                            </>
                          )}
                </div>
                <div className="slide-item-right">
                    <div className="date-selectors-container">
                        <div className="date-selector">
                            <label htmlFor={`start-date-${index}`}>Start Date:</label>
                            <input 
                                id={`start-date-${index}`}
                                type="datetime-local" 
                                value={startDate} 
                                onChange={handleStartDateChange} 
                            />
                        </div>
                        <div className="date-selector">
                            <label htmlFor={`end-date-${index}`}>End Date:</label>
                            <input 
                                id={`end-date-${index}`}
                                type="datetime-local" 
                                value={endDate} 
                                onChange={handleEndDateChange} 
                            />
                        </div>
                    </div>
                    <button className="remove-button" onClick={onRemove}>
                        <i className="fa fa-trash-o" aria-hidden="true"></i>
                    </button>
                </div>
            </div>

            <ImagePreviewModal isOpen={isModalOpen} onClose={handleCloseModal}>
                {isVideoFile(imageUrl)
                    ? (isNotMp4Video
                        ? <VideoIcon />  // Render video icon in the modal for non-mp4 formats
                        : <video width="100%" controls>
                            <source src={imageUrl} type={getVideoType(imageUrl)} />
                            Your browser does not support the video tag.
                          </video>
                      )
                    : <img src={imageUrl} alt={`Slide ${index + 1}`} style={{ width: '100%' }} />}
            </ImagePreviewModal>
        </>
    );
};

export default SlideItem;
