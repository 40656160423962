import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './WaitList.css';

const WaitList = () => {
    const htmlCode = `
    <div id="mc_embed_shell">
    <link href="//cdn-images.mailchimp.com/embedcode/classic-061523.css" rel="stylesheet" type="text/css">
<style type="text/css">
      #mc_embed_signup{background:#fff; false;clear:left; font:14px Helvetica,Arial,sans-serif; width: 600px; padding: 20px; border-radius:20px;}
      /* Add your own Mailchimp form style overrides in your site stylesheet or in this style block.
         We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. */
</style>
<div id="mc_embed_signup">
  <form action="https://studio.us21.list-manage.com/subscribe/post?u=6db5e789fac0d3cc3bfb8363a&amp;id=7b9b8ae35b&amp;f_id=005be4e6f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank">
      <div id="mc_embed_signup_scroll">
      <h2>Dont miss our next wave of Onboarding!</h2>
      <p>And get an EXCLUSIVE DISCOUNT, expected Q1 2024🚀<p>
          <div class="indicates-required"><span class="asterisk">*</span> indicates required</div>
          <div class="mc-field-group"><label for="mce-EMAIL">Email Address <span class="asterisk">*</span></label><input type="email" name="EMAIL" class="required email" id="mce-EMAIL" required="" value=""><span id="mce-EMAIL-HELPERTEXT" class="helper_text"></span></div>
      <div id="mce-responses" class="clear foot">
          <div class="response" id="mce-error-response" style="display: none;"></div>
          <div class="response" id="mce-success-response" style="display: none;"></div>
      </div>
  <div style="position: absolute; left: -5000px;" aria-hidden="true">
      /* real people should not fill this in and expect good things - do not remove this or risk form bot signups */
      <input type="text" name="b_6db5e789fac0d3cc3bfb8363a_7b9b8ae35b" tabindex="-1" value="">
  </div>
      <div class="optionalParent">
          <div class="clear foot">
              <input type="submit" name="subscribe" id="mc-embedded-subscribe" class="button" value="Notify Me">
              
          </div>
      </div>
  </div>
</form>
</div>
<script type="text/javascript" src="//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js"></script><script type="text/javascript">(function($) {window.fnames = new Array(); window.ftypes = new Array();fnames[0]='EMAIL';ftypes[0]='email';fnames[1]='FNAME';ftypes[1]='text';fnames[2]='LNAME';ftypes[2]='text';fnames[3]='ADDRESS';ftypes[3]='address';fnames[4]='PHONE';ftypes[4]='phone';fnames[5]='BIRTHDAY';ftypes[5]='birthday';}(jQuery));var $mcj = jQuery.noConflict(true);</script></div>

    `;

    return (
        <div className="background-image-container wl-conts">
            <div className="form-container" dangerouslySetInnerHTML={{ __html: htmlCode }} />
            <img src='https://d27oebqltxtb0s.cloudfront.net/DashboardBG2.png' alt="Background" />
        </div>
    );
};

export default WaitList;
