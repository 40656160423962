import React, { useState } from 'react';
import styles from './ImageSlider.module.css';
import backgroundImage from './DemoQueue.png';
import foregroundImage from './DemoQueue2.png';

function ImageSlider2() {
  const [sliderPos, setSliderPos] = useState(50);

  const handleSliderChange = (e) => {
    setSliderPos(e.target.value);
  };

  return (
    <div className={styles.container}>
      <div className={styles.slideimg} style={{ backgroundImage: `url(${backgroundImage})` }}></div>
      <div className={styles.slideimg} style={{ backgroundImage: `url(${foregroundImage})`, width: `${sliderPos}%` }}></div>
      <input
        type="range"
        min="1"
        max="100"
        value={sliderPos}
        className={styles.slider}
        onChange={handleSliderChange}
        style={{ '--slider-pos': `${sliderPos}%` }} /* Custom CSS variable */
      />
      <div
        className={styles.sliderButton}
        style={{ left: `calc(${sliderPos}% - 15px)` }}
      ></div>
    </div>
  );
}

export default ImageSlider2;
