import React, { useEffect } from 'react';
import './CalendlyModal.css'; // Make sure to create a CSS file for the modal styles

const CalendlyModal = ({ isOpen, onClose }) => {
  useEffect(() => {
    if (isOpen) {
      const script = document.createElement('script');
      script.src = 'https://assets.calendly.com/assets/external/widget.js';
      script.async = true;
      document.body.appendChild(script);
      return () => {
        document.body.removeChild(script);
      };
    }
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="cal-modal-content">
        <button className="cal-close-button" onClick={onClose}>
          &times;
        </button>
        <div
          className="calendly-inline-widget"
          data-url="https://calendly.com/lark-ujif/30min?hide_event_type_details=1&hide_gdpr_banner=1"
          style={{ minWidth: '320px', height: '700px' }}
        ></div>
      </div>
    </div>
  );
};

export default CalendlyModal;
