import React, { useState, useMemo, useEffect, useRef } from 'react';
import './VendorList.css';
import ExportModal from './ExportModal';
import AIInsightsModal from './AIInsightsModal';
import ActualMarginModal from './ActualMarginModal'; 
import MinMaxPriceModal from './MinMaxPriceModal';



const AltVendorList = ({ inventoryData, onTotalsUpdate }) => {
  const [selectedVendorId, setSelectedVendorId] = useState(null);
  const [sortMethod, setSortMethod] = useState({ field: 'remainingCost', direction: 'desc' });
  const [filterVendor, setFilterVendor] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [productSearchTerm, setProductSearchTerm] = useState('');
  const [favoriteVendors, setFavoriteVendors] = useState(new Set());
  const [showFavorites, setShowFavorites] = useState(false);
  const [selectedItems, setSelectedItems] = useState(new Set());
  const selectedVendorRef = useRef(null);
  const [expandedVendors, setExpandedVendors] = useState(new Set());  // New state to track expanded vendors
  const [expandedOrders, setExpandedOrders] = useState(new Set());    // New state to track expanded orders
  const selectedStore = JSON.parse(localStorage.getItem('selectedStore'));
  const selectedStoreId = selectedStore ? selectedStore.id : null;
  const [hiddenOrders, setHiddenOrders] = useState([]);
  const [showHiddenOrders, setShowHiddenOrders] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [isActualMarginModalOpen, setIsActualMarginModalOpen] = useState(false);
  const [selectedProductForMargin, setSelectedProductForMargin] = useState(null);
  const [isMinMaxPriceModalOpen, setIsMinMaxPriceModalOpen] = useState(false);
  const [priceRange, setPriceRange] = useState({ min: 0, max: Infinity });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [categories, setCategories] = useState([]);
  const [isAIInsightsModalOpen, setIsAIInsightsModalOpen] = useState(false);

  const [productSortMethod, setProductSortMethod] = useState({ field: 'product', direction: 'asc' });

  
  const [exportFields, setExportFields] = useState({
    vendor: false,
    product: false,
    sku: false,
    packageId: false,
    totalQuantityPerSKU: false,
    gp: false,
    percentageRemaining: false,
    daysOfSupplyRemaining: false,
    // ... other fields
  });

  const handleReceivedMarginClick = (product, receivedDate) => {
    setSelectedProductForMargin({ 
      ...product, 
      receivedDate, 
      productId: product.productId, 
      packageId: product.packageId, 
      sku: product.sku 
    });
    setIsActualMarginModalOpen(true);
  };

  const sortProducts = (items, { field, direction }) => {
    const multiplier = direction === 'asc' ? 1 : -1;
  
    return [...items].sort((a, b) => {
      let aValue = a[field];
      let bValue = b[field];
  
      // Handle sorting by percentage remaining (totalRemaining / totalQuantity)
      if (field === 'percentageRemaining') {
        aValue = (a.totalRemaining / a.totalQuantity) * 100;
        bValue = (b.totalRemaining / b.totalQuantity) * 100;
      }
  
      // Handle sorting by date
      if (field === 'mostRecentOrderDate') {
        aValue = new Date(a.mostRecentOrderDate);
        bValue = new Date(b.mostRecentOrderDate);
        return (aValue - bValue) * multiplier;
      }
  
      // Handle sorting by name (strings)
      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return aValue.localeCompare(bValue) * multiplier;
      }
  
      // Handle sorting by cost or other numeric values
      if (typeof aValue === 'number' && typeof bValue === 'number') {
        return (aValue - bValue) * multiplier;
      }
  
      return 0; // Fallback for equal values
    });
  };
  
  
  
  
  
  

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A'; // Handle cases where no date is provided
    
    const date = new Date(dateString); // JS Date object natively parses ISO 8601 strings
    
    // Check for invalid date
    if (isNaN(date.getTime())) {
      return 'Invalid Date'; // Return a fallback for invalid dates
    }
  
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return date.toLocaleDateString(undefined, options);
  };
  

  const csvInventoryData = flattenDataForCSV(inventoryData);

  const exportFieldLabels = {
    vendor: 'Vendor',
    product: 'Product',
    sku: 'SKU #',
    packageId: 'METRC #',
    totalQuantityPerSKU: 'SKU Quantity',
    gp: 'Gross Margin %',
    percentageRemaining: 'Remaining %',
    daysOfSupplyRemaining: 'Days Supply',
    // Add other fields as needed
  };

  const StatusBar = ({ percentage }) => {
    const fillColor = percentage > 50 ? 'green' : 'red';
  
    return (
      <div className="status-bar">
        <div className="status-bar-fill" style={{ width: `${Math.max(0, Math.min(100, percentage))}%`, backgroundColor: fillColor }}>
          {percentage.toFixed(2)}%
        </div>
      </div>
    );
  };

  const calculateVendorTotalPercentage = (orders) => {
    let totalQuantity = 0;
    let totalRemaining = 0;
  
    orders.forEach(order => {
      order.items.forEach(item => {
        totalQuantity += item.quantity;
        totalRemaining += (item.percentageRemaining / 100) * item.quantity;
      });
    });
  
    return totalQuantity > 0 ? (totalRemaining / totalQuantity) * 100 : 0;
  };

  useEffect(() => {
    const extractedCategories = new Set();
    inventoryData.forEach(order => {
      order.items.forEach(item => {
        if (item.category) {
          extractedCategories.add(item.category);
        }
      });
    });
    // Convert Set to array and sort it alphabetically
    const sortedCategories = [...extractedCategories].sort((a, b) => a.localeCompare(b));
    setCategories(sortedCategories);
  }, [inventoryData]);
  

  

  const handleMinMaxPriceApply = (minPrice, maxPrice) => {
    setPriceRange({ min: parseFloat(minPrice) || 0, max: parseFloat(maxPrice) || Infinity });
    setIsMinMaxPriceModalOpen(false); // Close the modal after applying the price range
  };

  const calculateOrderPercentage = (items) => {
    const total = items.reduce((acc, item) => acc + item.quantity, 0);
    const weightedSum = items.reduce((acc, item) => acc + item.quantity * (item.percentageRemaining / 100), 0);
    return (weightedSum / total) * 100;
  };

  const toggleFavorite = async (e, vendor) => {
    e.stopPropagation(); // Stop event bubbling
    
    setFavoriteVendors((prevFavorites) => {
      const newFavorites = new Set(prevFavorites);
      if (newFavorites.has(vendor)) {
        newFavorites.delete(vendor);
      } else {
        newFavorites.add(vendor);
      }
      // Update backend/local storage after state change
      updateFavoritesOnBackend(Array.from(newFavorites)); // Convert Set to Array for API call or storage
      return newFavorites;
    });
  };

  // Function to update favorites on the backend or local storage
  const updateFavoritesOnBackend = async (favorites) => {
    const jwt = localStorage.getItem('token'); // Assuming JWT token is stored in local storage
    try {
      await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/save-favorite-vendors/`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${jwt}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ favorites })
      });
      // Handle successful update
    } catch (error) {
      console.error('Error updating favorited vendors:', error);
      // Handle errors appropriately
    }
  };

  useEffect(() => {
    const fetchFavoritedVendors = async () => {
      const jwt = localStorage.getItem('token'); // Get JWT token from local storage
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/load-favorite-vendors/`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${jwt}`
          }
        });
        if (!response.ok) {
          throw new Error('Failed to fetch favorited vendors');
        }
        const data = await response.json();
        setFavoriteVendors(new Set(data.favorites));
      } catch (error) {
        console.error('Error fetching favorited vendors:', error);
        // Handle errors appropriately
      }
    };

    fetchFavoritedVendors();
  }, []);



  
  const handleExport = (exportType) => {
    console.log("Export function called with type:", exportType);
  
    const dataToExport = filteredAndSortedInventoryData
    .flatMap(order => 
      order.items
        .filter(item => selectedItems.has(item.packageId)) // Filter only selected items
        .map(item => {
          const filteredData = {};
          Object.keys(exportFields).forEach(field => {
            if (exportFields[field]) { // Check if the field is set to true
              if (field === 'vendor') {
                filteredData[field] = order.vendor;
              } else {
                filteredData[field] = item[field] || 'N/A';
              }
            }
          });
          return filteredData;
        })
    );
  
    // Convert data to a CSV string
    const csvData = convertDataToCSV(dataToExport);
  
    if (exportType === 'csv') {
      // Logic to save data as a CSV file
      const csvData = convertDataToCSV(dataToExport);
      saveCSV(csvData);
    } else if (exportType === 'clipboard') {
      // Logic to copy formatted data to clipboard
      const clipboardData = convertDataToFormattedText(dataToExport);
      navigator.clipboard.writeText(clipboardData).then(() => {
        console.log('Data copied to clipboard');
      }).catch(err => {
        console.error('Failed to copy data to clipboard:', err);
      });
    }
  
    setIsModalOpen(false);
  };

  function flattenDataForCSV(inventoryData) {
    let csvRows = [];
  
    // Define the headers
    const headers = [
      'OrderId', 'DeliveryDate', 'VendorName',
      'PackageId', 'ProductName', 'SKU', 'Quantity', 'UnitPrice', 'UnitCost', 
      'PercentageRemaining', 'DaysOfSupplyRemaining', 'TotalQuantityPerSKU', 'GrossProfit'
    ];
  
    // Add the header row
    csvRows.push(headers.join(','));
  
    inventoryData.forEach(order => {
      const orderInfo = {
        OrderId: order.receiveInventoryHistoryId,
        DeliveryDate: formatDate(order.deliveredOn),
        VendorName: escapeCSV(order.vendor),
        // ...other order-level fields...
      };
  
      order.items.forEach(item => {
        const productInfo = {
          PackageId: item.packageId,
          ProductName: escapeCSV(item.product),
          SKU: item.sku,
          Quantity: item.quantity,
          UnitPrice: item.unitPrice,
          UnitCost: item.unitCost,
          PercentageRemaining: item.percentageRemaining,
          DaysOfSupplyRemaining: item.daysOfSupplyRemaining,
          TotalQuantityPerSKU: item.totalQuantityPerSKU,
          GrossProfit: item.gp,
          // ...other product-level fields...
        };
  
        // Combine order info and product info
        const combinedInfo = {...orderInfo, ...productInfo};
        const row = Object.values(combinedInfo).map(value => escapeCSV(checkValue(value))).join(',');
        csvRows.push(row);
      });
    });
  
    return csvRows.join('\n');
  }
  
  function escapeCSV(field) {
    if (field == null || typeof field === 'function') return ''; 
    const stringField = field.toString();
    if (stringField.includes(',') || stringField.includes('"') || stringField.includes('\n')) {
      return `"${stringField.replace(/"/g, '""')}"`;
    }
    return stringField;
  }
  
  function checkValue(value) {
    if (typeof value === 'function' || value === undefined) {
      return '';
    }
    return value;
  }
  
  
  
  const convertDataToCSV = (data) => {
    if (data.length === 0) return '';
  
    // Extracting headers (column names)
    const headers = Object.keys(data[0]);
    const csvRows = [];
  
    // Adding headers row
    csvRows.push(headers.join(','));
  
    // Adding data rows
    for (const row of data) {
      const values = headers.map(header => {
        const escaped = ('' + row[header]).replace(/"/g, '\\"'); // Escape any quotes in data
        return `"${escaped}"`; // Wrap each value in quotes
      });
      csvRows.push(values.join(','));
    }
  
    return csvRows.join('\n');
  };
  
  
  const saveCSV = (csvData) => {
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
  
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', 'export.csv');
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  const convertDataToFormattedText = (data) => {
    if (data.length === 0) return '';
  
    const headers = Object.keys(data[0]);
    const columnWidths = headers.map(header => 
      Math.max(...data.map(row => ('' + row[header]).length), header.length)
    );
  
    const formatRow = (row, isHeader = false) => 
      headers.map((header, index) => {
        const cellContent = isHeader ? header : ('' + row[header]);
        return cellContent.padEnd(columnWidths[index], ' ');
      }).join(' | ');
  
    const formattedRows = [
      formatRow(headers, true), // Pass true to indicate this is a header row
      ...data.map(row => formatRow(row))
    ];
  
    return formattedRows.join('\n');
  };


  const toggleHiddenOrder = async (e, orderId, hideOrder) => {
    e.stopPropagation();
  
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/store/${selectedStoreId}/toggle-hidden-order/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({ order_id: orderId, hide_order: hideOrder }),
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const data = await response.json();
      // Assuming the response contains the updated list of hidden order IDs
      setHiddenOrders(data.hidden_orders);
  
      console.log('Hidden orders updated:', data.hidden_orders);
    } catch (error) {
      console.error('Error toggling hidden order:', error);
    }
  };
  

  
  
  useEffect(() => {
    // Simulate fetching hidden orders from the server
    const fetchHiddenOrders = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/store/${selectedStoreId}/toggle-hidden-order/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
          body: JSON.stringify({ order_id: "999999999" }), // Using "xxxx" to get hidden orders
        });
        if (!response.ok) {
          throw new Error('Failed to fetch hidden orders');
        }
        const data = await response.json();
        setHiddenOrders(data.hidden_orders); // Assuming the response contains an array of hidden order IDs
        console.log("Fetched and set hiddenOrders state:", data.hidden_orders);
      } catch (error) {
        console.error('Error fetching hidden orders:', error);
      }
    };

    fetchHiddenOrders();
  }, [selectedStoreId]); // Rerun this effect if selectedStoreId changes


  const toggleSortDirection = () => {
    setSortMethod((currentSortMethod) => ({
      ...currentSortMethod,
      direction: currentSortMethod.direction === 'asc' ? 'desc' : 'asc',
    }));
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleProductSearchChange = (event) => {
    setProductSearchTerm(event.target.value);
  };

  const handleSortChange = (selectedField) => {
    if (selectedField === 'unitCost') {
      setSortMethod((currentSortMethod) => ({
        ...currentSortMethod,
        field: selectedField,
        direction: 'asc', // or 'desc', depending on your default
      }));
      setIsMinMaxPriceModalOpen(true); // Open the MinMaxPriceModal when "Sort by Unit Cost" is selected
    } else {
      setSortMethod((currentSortMethod) => {
        let newDirection = 'desc';
        if (selectedField === 'date') {
          newDirection = 'asc';
        }
        return {
          field: selectedField,
          direction: newDirection,
        };
      });
    }
  };

  const handleSortChangeForProducts = (field) => {
    setProductSortMethod((prevSortMethod) => {
      const newDirection = prevSortMethod.field === field && prevSortMethod.direction === 'asc' ? 'desc' : 'asc';
  
      console.log(`Sorting by ${field}, direction: ${newDirection}`); // Debugging log
  
      return {
        field,
        direction: newDirection,
      };
    });
  };
  
  
  

  const sortInventoryData = (data, { field, direction }) => {
    const multiplier = direction === 'asc' ? 1 : -1;
  
    return [...data].sort((a, b) => {
      const [vendorA, skuItemsA] = a;
      const [vendorB, skuItemsB] = b;
  
      // Calculate vendor total percentage remaining
      const vendorTotalPercentageA = skuItemsA.reduce(
        (acc, item) => acc + (item.totalRemaining / item.totalQuantity) * 100,
        0
      ) / skuItemsA.length;
      const vendorTotalPercentageB = skuItemsB.reduce(
        (acc, item) => acc + (item.totalRemaining / item.totalQuantity) * 100,
        0
      ) / skuItemsB.length;
  
      // Calculate remaining cost
      const remainingCostA = skuItemsA.reduce(
        (acc, item) => acc + item.unitCost * item.totalRemaining,
        0
      );
      const remainingCostB = skuItemsB.reduce(
        (acc, item) => acc + item.unitCost * item.totalRemaining,
        0
      );
  
      switch (field) {
        case 'percentage':
          return multiplier * (vendorTotalPercentageA - vendorTotalPercentageB);
        case 'remainingCost':
          return multiplier * (remainingCostA - remainingCostB);
        case 'date':
        default:
          return 0; // Default to no sorting for other fields
      }
    });
  };
  

  const toggleVendorExpansion = (vendor) => {
    setExpandedVendors(prevState => {
      const newSet = new Set(prevState);
      if (newSet.has(vendor)) {
        newSet.delete(vendor);
      } else {
        newSet.add(vendor);
      }
      return newSet;
    });
  };

  const toggleOrderExpansion = (orderId) => {
    setExpandedOrders(prevState => {
      const newSet = new Set(prevState);
      if (newSet.has(orderId)) {
        newSet.delete(orderId);
      } else {
        newSet.add(orderId);
      }
      return newSet;
    });
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const toggleItemSelection = (itemId) => {
    setSelectedItems((prevSelectedItems) => {
      const newSelectedItems = new Set(prevSelectedItems);
      if (newSelectedItems.has(itemId)) {
        newSelectedItems.delete(itemId);
      } else {
        newSelectedItems.add(itemId);
      }
      return newSelectedItems;
    });
  };

  const calculateStockForSKU = (orders) => {
    const skuMap = {};
  
    // Group items by SKU
    orders.forEach(order => {
      order.items.forEach(item => {
        if (!skuMap[item.sku]) {
          skuMap[item.sku] = {
            ...item,
            totalQuantity: 0,
            totalRemaining: 0,
          };
        }
        skuMap[item.sku].totalQuantity += item.quantity;
        skuMap[item.sku].totalRemaining += (item.percentageRemaining / 100) * item.quantity;
      });
    });
  
    return Object.values(skuMap);
  };

  const getMostRecentOrderDate = (orders) => {
    if (!orders || orders.length === 0) return null;
    
    console.log("Orders data structure:", orders); // Check the structure of the data to see where `deliveredOn` is

    const mostRecentOrder = orders.reduce((latest, order) => {
      const currentOrderDate = new Date(order.deliveredOn); // Check if this is the correct field
      const latestOrderDate = new Date(latest.deliveredOn);
      
      return currentOrderDate > latestOrderDate ? order : latest;
    });

    return mostRecentOrder.deliveredOn ? mostRecentOrder.deliveredOn : 'N/A'; // Ensure this field exists
};
  
  
  
  

const filteredAndSortedInventoryData = useMemo(() => {
    let data = inventoryData.map(order => {
      const filteredItems = order.items.filter(item => 
        (!productSearchTerm || item.product.toLowerCase().includes(productSearchTerm.toLowerCase())) &&
        (sortMethod.field !== 'unitCost' || (item.unitCost >= priceRange.min && item.unitCost <= priceRange.max))
      );
  
      // Get the most recent deliveredOn date for the order
      const mostRecentOrderDate = formatDate(order.deliveredOn);
  
      // Attach mostRecentOrderDate to each item in this order
      const itemsWithOrderDate = filteredItems.map(item => ({
        ...item,
        mostRecentOrderDate,  // Attach the most recent order date from vendor to each item
      }));
  
      return {
        ...order,
        items: itemsWithOrderDate, // Use items with the mostRecentOrderDate attached
      };
    });
  
    if (filterVendor) {
      data = data.filter(order => order.vendor === filterVendor);
    }
  
    if (searchTerm) {
      data = data.filter(order => order.vendor.toLowerCase().includes(searchTerm.toLowerCase()));
    }
  
    if (showFavorites) {
      data = data.filter(order => favoriteVendors.has(order.vendor));
    }
  
    if (showHiddenOrders) {
      data = data.filter(order => hiddenOrders.includes(order.receiveInventoryHistoryId));
    } else {
      data = data.filter(order => !hiddenOrders.includes(order.receiveInventoryHistoryId));
    }
  
    if (selectedCategory) {
      data = data.map(order => ({
        ...order,
        items: order.items.filter(item => item.category === selectedCategory)
      }));
    }
  
    data = data.filter(order => order.items.length > 0);
  
    const groupedData = data.reduce((acc, order) => {
      if (!acc[order.vendor]) {
        acc[order.vendor] = [];
      }
      acc[order.vendor].push(...calculateStockForSKU([order]));
      return acc;
    }, {});
  
    return sortInventoryData(Object.entries(groupedData), sortMethod);
  }, [inventoryData, sortMethod, filterVendor, searchTerm, productSearchTerm, showFavorites, favoriteVendors, showHiddenOrders, hiddenOrders, selectedCategory, priceRange]);
  
  // Group inventory data by vendor
  const groupedByVendor = useMemo(() => {
    return filteredAndSortedInventoryData.reduce((acc, order) => {
      if (!acc[order.vendor]) {
        acc[order.vendor] = [];
      }
      acc[order.vendor].push(order);
      return acc;
    }, {});
  }, [filteredAndSortedInventoryData]);

  const exportSelectedItems = () => {
    console.log("Export Selected clicked");
    setIsModalOpen(true);
  };

  const getMostRecentOrderDateFromProducts = (items) => {
    if (!items || items.length === 0) return 'N/A';
  
    const mostRecentDate = items.reduce((latestDate, item) => {
      const currentItemDate = new Date(item.mostRecentOrderDate);
      
      if (isNaN(currentItemDate.getTime())) {
        return latestDate; // Skip invalid dates
      }
  
      // Compare the current item's date with the latest found so far
      return currentItemDate > latestDate ? currentItemDate : latestDate;
    }, new Date(0)); // Start with a very old date (epoch)
  
    // Format the most recent date
    return formatDate(mostRecentDate.toISOString());
  };
  

  return (
    <div className="vendor-list">
      <div className="sorting-buttons">
        <div className="group">
          <button onClick={() => toggleSortDirection()}>
            {sortMethod.direction === 'asc' ? '⬇️' : '⬆️'}
          </button>
          <select value={sortMethod.field} onChange={(e) => handleSortChange(e.target.value)}>
            <option value="percentage">📈 Sort by Percentage</option>
            <option value="remainingCost">💵 Sort by Remaining Cost</option>
            <option value="unitCost">💲 Sort by Unit Cost</option>
          </select>
          <button onClick={() => setShowFavorites(!showFavorites)}>
            {showFavorites ? '☆ Show All Vendors' : '⭐️ Show Favorited Vendors'}
          </button>
          {filterVendor && (
            <button onClick={() => setFilterVendor(null)}>Show All Vendors</button>
          )}
          <button onClick={() => setShowHiddenOrders(prev => !prev)}>
            {showHiddenOrders ? "👁️ Hide Hidden Orders" : "👁️‍🗨️ Show Hidden Orders"}
          </button>
        </div>
        <div className="group">
          <input type="text" placeholder="Search Vendor" value={searchTerm} onChange={handleSearchChange} />
          <input type="text" placeholder="Search Product" value={productSearchTerm} onChange={handleProductSearchChange} />
          <select value={selectedCategory} onChange={handleCategoryChange}>
            <option value="">Select Category</option>
            {categories.map((category, index) => (
              <option key={index} value={category}>
                {category}
              </option>
            ))}
          </select>
          <AIInsightsModal
            isOpen={isAIInsightsModalOpen}
            onClose={() => setIsAIInsightsModalOpen(false)}
            inventoryData={csvInventoryData}
          />
          {/*<button onClick={exportSelectedItems}>Export Selected</button>*/}
        </div>
      </div>
  
      {/* Render vendors and expand orders/products under them */}
      {filteredAndSortedInventoryData.map(([vendor, skuItems]) => {
  // Calculate the most recent order date from the products within the vendor
  const mostRecentOrderDate = getMostRecentOrderDateFromProducts(skuItems); // Now based on products
  
  const vendorTotalPercentage = skuItems.reduce((acc, item) => acc + (item.totalRemaining / item.totalQuantity) * 100, 0) / skuItems.length;
  const totalCost = skuItems.reduce((acc, item) => acc + item.unitCost * item.totalQuantity, 0);
  const remainingCost = skuItems.reduce((acc, item) => acc + item.unitCost * item.totalRemaining, 0);

  const sortedSkuItems = sortProducts(skuItems, productSortMethod);


  return (
    <div key={vendor} className="vendor-entry">
      <div className="vendor-summary" onClick={() => toggleVendorExpansion(vendor)}>
        <span className="favorite-icon" onClick={(e) => toggleFavorite(e, vendor)}>
          {favoriteVendors.has(vendor) ? '⭐️' : '☆'}
        </span>
        <span className="vendor-name">{vendor}</span>
        <div className="status-bar-container">
          <StatusBar percentage={vendorTotalPercentage} />
        </div>
        <span className="left-align order-total-cost">Spent: ${totalCost.toFixed(0)}</span>
        <span className="left-align order-remaining-cost">Remaining: ${remainingCost.toFixed(0)}</span>
        {/* Display the most recent date based on the products */}
        <span className="left-align received-date">Ordered: {mostRecentOrderDate}</span>
      </div>

      {expandedVendors.has(vendor) && (
        <div className="product-list">
          {sortedSkuItems.map(item => (
            <div key={`${item.sku}-${item.packageId}`} className="item-detail">
                <input
                type="checkbox"
                checked={selectedItems.has(item.packageId)}
                onChange={() => toggleItemSelection(item.packageId)}
                />
                <span className="reorder-item-name" onClick={() => handleSortChangeForProducts('product')}>
                {item.product}
                </span>
                <span>SKU: {item.sku}</span>
                
                {/* Add sorting by unit cost */}
                <span onClick={() => handleSortChangeForProducts('unitCost')}>
                Unit Cost: ${item.unitCost.toFixed(2)}
                </span>

                {/* Add sorting for Ordered and Remaining */}
                <span onClick={() => handleSortChangeForProducts('totalQuantity')}>
                Ordered: {item.totalQuantity.toFixed(0)}
                </span>
                <span onClick={() => handleSortChangeForProducts('totalRemaining')}>
                Remaining: {item.totalRemaining.toFixed(0)}
                </span>

                <span onClick={() => handleSortChangeForProducts('percentageRemaining')}>
                <StatusBar percentage={(item.totalRemaining / item.totalQuantity) * 100} />
                </span>

                <span onClick={() => handleSortChangeForProducts('mostRecentOrderDate')}>
                Ordered: {item.mostRecentOrderDate}
                </span>
            </div>
            ))}






        </div>
      )}
    </div>
  );
})}

  
      <ActualMarginModal
        isOpen={isActualMarginModalOpen}
        onClose={() => setIsActualMarginModalOpen(false)}
        product={selectedProductForMargin}
        authHeader={localStorage.getItem('token')}
      />
  
      <MinMaxPriceModal
        isOpen={isMinMaxPriceModalOpen}
        onClose={() => setIsMinMaxPriceModalOpen(false)}
        onApply={handleMinMaxPriceApply}
      />
  
      <ExportModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onExport={handleExport}
        fields={exportFields}
        setFields={setExportFields}
        fieldLabels={exportFieldLabels}
      />
    </div>
  );
  
  
};

export default AltVendorList;
