import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './DealSheetVisualization.css';

function DealSheetVisualization() {
    const [dealSheet, setDealSheet] = useState({ categories: [], background_images: [] });
    const [fetchedDeals, setFetchedDeals] = useState([]);
    const [bgImageIndex, setBgImageIndex] = useState(0);
    const [textColors, setTextColors] = useState({ primary: '#000', secondary: '#000' });
    const selectedStore = JSON.parse(localStorage.getItem('selectedStore'));
    const selectedStoreId = selectedStore ? selectedStore.id : null;
    const id = localStorage.getItem('currentDealSheetId');

    const [headerImageIndex, setHeaderImageIndex] = useState(0);

    const fetchWithRefresh = async (url, options) => {
        let response = await fetch(url, options);
        if (response.status === 401) { // If token expired
            const tokenRefreshed = await refreshToken();
            if (tokenRefreshed) {
                options.headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
                response = await fetch(url, options);
            } else {
                // Handle token refresh failure, redirect to login or show error
            }
        }
        return response;
    };

    const cycleHeaderImage = (e) => {
        e.stopPropagation();
        const newHeaderImageIndex = (headerImageIndex + 1) % dealSheet.header_images.length;
        setHeaderImageIndex(newHeaderImageIndex);
    };

    const refreshToken = async () => {
        const url = `${process.env.REACT_APP_API_BASE_URL}/users/jwt-refresh/`;
        const refreshToken = localStorage.getItem('refreshToken');
        const response = await fetch(url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ refresh: refreshToken })
        });
        if (response.ok) {
            const data = await response.json();
            localStorage.setItem('token', data.access);
            localStorage.setItem('refreshToken', data.refresh);
            return true;
        }
        return false;
    };

    const fetchData = async () => {
        try {
            const response = await fetchWithRefresh(`${process.env.REACT_APP_API_BASE_URL}/users/deal-sheets/${id}/?store_id=${selectedStoreId}`, {
                headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
            });
            if (!response.ok) throw new Error('Failed to fetch deal sheet data');
            const data = await response.json();
            setDealSheet(data);
            if (data.background_images && data.background_images.length > 0) {
                setTextColors({
                    primary: data.background_images[0].primary_color,
                    secondary: data.background_images[0].secondary_color
                });
            }
        } catch (error) {
            console.error("Error fetching deal sheet details:", error);
        }
    };

    useEffect(() => {
        fetchData();

        const refreshInterval = setInterval(fetchData, 30 * 60 * 1000); // Refresh every 30 minutes

        return () => clearInterval(refreshInterval); // Cleanup
    }, [id, selectedStoreId]);

    useEffect(() => {
        if (dealSheet && dealSheet.categories) {
            const dutchieDealIds = [];
            
            // Extract all dutchie deal IDs from the deal sheet
            dealSheet.categories.forEach(category => {
                category.linkeddeals.forEach(deal => {
                    if (deal.isDutchieDeal) {
                        dutchieDealIds.push(deal.discountId);
                    }
                });
            });
            
            if (dutchieDealIds.length > 0) {
                fetch(`${process.env.REACT_APP_API_BASE_URL}/users/get-deals-by-ids/`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'Selected-Store-Id': selectedStoreId
                    },
                    body: JSON.stringify({ ids: dutchieDealIds })
                })
                .then(res => res.json())
                .then(data => {
                    setFetchedDeals(data);
                })
                .catch(error => console.error("Error fetching deal details:", error));
            }
        }
    }, [dealSheet, selectedStoreId]);

    const fetchDutchieDeals = () => {
        if (dealSheet && dealSheet.categories) {
            const dutchieDealIds = [];
            
            // Extract all dutchie deal IDs from the deal sheet
            dealSheet.categories.forEach(category => {
                category.linkeddeals.forEach(deal => {
                    if (deal.isDutchieDeal) {
                        dutchieDealIds.push(deal.discountId);
                    }
                });
            });
            
            if (dutchieDealIds.length > 0) {
                fetch(`${process.env.REACT_APP_API_BASE_URL}/users/get-deals-by-ids/`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'Selected-Store-Id': selectedStoreId
                    },
                    body: JSON.stringify({ ids: dutchieDealIds })
                })
                .then(res => res.json())
                .then(data => {
                    setFetchedDeals(data);
                })
                .catch(error => console.error("Error fetching deal details:", error));
            }
        }
    };

    const cycleBackgroundAndTextColor = () => {
        const newBgImageIndex = (bgImageIndex + 1) % dealSheet.background_images.length;
        setBgImageIndex(newBgImageIndex);
        
        // Set text colors based on the new background image
        const newBgImage = dealSheet.background_images[newBgImageIndex];
        setTextColors({
            primary: newBgImage.primary_color,
            secondary: newBgImage.secondary_color
        });
    };

    useEffect(() => {
        fetchDutchieDeals();  // Fetch immediately upon mounting
        const interval = setInterval(fetchDutchieDeals, 5 * 60 * 1000);  // Fetch every 5 minutes

        return () => clearInterval(interval);  // Cleanup on component unmount
    }, [dealSheet, selectedStoreId]);
    

    if (!dealSheet) return <div>Loading...</div>;

    const splitTextAtNearestWord = (text, maxLength) => {
        const words = text.split(' ');
        let currentLine = '';
        let lines = [];

        for (let word of words) {
            if (currentLine.length + word.length + 1 <= maxLength) {
                currentLine += `${word} `;
            } else {
                lines.push(currentLine.trim());
                currentLine = `${word} `;
            }
        }

        if (currentLine) {
            lines.push(currentLine.trim());
        }

        return lines;
    };

    const processDealName = (dealInfo, discount) => {
        // Split the deal info at nearest word boundary if it's longer than 50 characters
        const splitDealInfo = dealInfo.length > 58 ? splitTextAtNearestWord(dealInfo, 58) : [dealInfo];
        return { splitDealInfo, discount };
    };

    return (
        <div className="deal-sheet-visualization-container" style={{ 
            backgroundImage: dealSheet.background_images && dealSheet.background_images.length ? `url(${dealSheet.background_images[bgImageIndex].url})` : '',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
        }}
        onClick={cycleBackgroundAndTextColor}
    >
            <div className="deal-sheet-header" onClick={(e) => cycleHeaderImage(e)}>
                {dealSheet.header_images && dealSheet.header_images.length ? (
                    <img src={dealSheet.header_images[headerImageIndex]} alt="Header" />
                ) : (
                    <img src={dealSheet.mobile_banner} alt="Header" />
                )}
            </div>            
            <div className="deal-sheet-visualization">
            {dealSheet.categories?.map((category) => (
    <div key={category.id} className="category-section">
         <h2 className="cat-names" style={{ color: textColors.primary }}>{category.name}</h2>
        <ul className="dealsheet-deals-list">

            
        {category.linkeddeals?.map((deal, dealIndex) => {
            let dealInfo = deal.isDutchieDeal && fetchedDeals.length
                ? fetchedDeals.find(d => d.discountId === deal.discountId)?.discountName ?? ''
                : deal.description;

            const parts = dealInfo.split('|');
            let dealDescription = parts[0] ? parts[0].trim() : '';
            let discount = parts[1] ? parts[1].trim() : '';

            const { splitDealInfo } = processDealName(dealDescription, discount);

            return (
                <li key={dealIndex} className="deal" style={{ color: textColors.secondary }}>
                    <div className="deal-info-container">
                        {splitDealInfo.map((line, lineIndex) => (
                            <div key={lineIndex} className="deal-info-line">{line}</div>
                        ))}
                    </div>
                    {discount && (
                        <>
                            <div className="deal-divider"></div>
                            <span className="deal-discount">{discount}</span>
                        </>
                    )}
                </li>
            );
        })}
                        </ul>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default DealSheetVisualization;