import React, { useState, useEffect, useRef } from 'react';
import './DutchieDealsModal.css';  // Assuming you'll have separate CSS for this modal

function DutchieDealsModal({ onClose, selectedDealsFromParent }) {
    const [deals, setDeals] = useState([]);
    const [selectedDeals, setSelectedDeals] = useState(selectedDealsFromParent);
    const selectedStore = JSON.parse(localStorage.getItem('selectedStore'));
    const selectedStoreId = selectedStore ? selectedStore.id : null;

    const [categories, setCategories] = useState([]);
    const [selectedCategoryId, setSelectedCategoryId] = useState(null);

    const [searchTerm, setSearchTerm] = useState("");
    const [filteredCategories, setFilteredCategories] = useState([]);

    const [showDropdown, setShowDropdown] = useState(false);
    const [selectedCategories, setSelectedCategories] = useState([]);

    const dropdownRef = useRef(null);

    const [dealSearchTerm, setDealSearchTerm] = useState("");
    const [filteredDeals, setFilteredDeals] = useState([]);


    const [inventoryData, setInventoryData] = useState([]);

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const updatedFilteredDeals = deals.filter(deal => 
            deal.discountName.toLowerCase().includes(dealSearchTerm.toLowerCase()) && 
            isDealRelevantToSelectedCategory(deal)
        );
        setFilteredDeals(updatedFilteredDeals);
    }, [deals, selectedCategories, dealSearchTerm]);

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setShowDropdown(false);
        }
    };

    const handleDealSearchChange = e => {
        setDealSearchTerm(e.target.value);
    };

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_BASE_URL}/users/get-all-deals/`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'Selected-Store-Id': selectedStoreId
            }
        })
        .then(res => res.json())
        .then(data => {
            // Convert the object to an array of deals
            const dealsArray = Object.values(data);
            
            // Filter the deals
            const filteredDeals = dealsArray.filter(deal => {
                const currentDate = new Date();
                const validFromDate = new Date(deal.validFrom);
                {/* isActive seems to return the wrong value sometimes for a discount - specifically if manual */};
                return deal.applicationMethod === "Manual" || deal.isActive || validFromDate > currentDate;
                {/* return validFromDate > currentDate;*/};
            });
            
            setDeals(filteredDeals);
            setFilteredDeals(filteredDeals);
        })
        .catch(err => console.error('Failed to fetch deals:', err));
    }, []);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_BASE_URL}/users/get-product-categories/`, {  // Adjust this URL if needed
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'Selected-Store-Id': selectedStoreId
            }
        })
        .then(res => res.json())
        .then(data => {
            setCategories(data);
            setFilteredCategories(data);
        })
        .catch(err => console.error('Failed to fetch categories:', err));
    }, []);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_BASE_URL}/users/get-inventory/`, {  // Adjust this URL if needed
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'Selected-Store-Id': selectedStoreId
            }
        })
        .then(res => res.json())
        .then(data => setInventoryData(data))
        .catch(err => console.error('Failed to fetch inventory:', err));
    }, []);

    const isDealRelevantToSelectedCategory = (deal) => {
        // If there are no categories selected, always return true
        if (selectedCategories.length === 0) return true;
    
        // If the deal has a category and it matches the selected ones
        if (deal.productCategories && selectedCategories.some(catId => deal.productCategories.ids.includes(catId))) {
            return true;
        }
    
        // If there are no products specified for the deal, return false
        if (!deal.products || !deal.products.ids || deal.products.ids.length === 0) {
            return false;
        }
    
        // If any of the deal's products fall under the selected categories, return true
        for (let productId of deal.products.ids) {
            const productData = inventoryData.find(product => product.productId === productId);
            if (productData && selectedCategories.includes(productData.categoryId)) {
                return true;
            }
        }
    
        return false;  // Default return false
    };

    useEffect(() => {
        console.log("Received deals from parent:", selectedDealsFromParent);
        setSelectedDeals(selectedDealsFromParent);
    }, [selectedDealsFromParent]);
        

    const getCategoryNameFromId = (id) => {
        const category = categories.find(cat => cat.productCategoryId === id);
        return category ? category.productCategoryName : 'Unknown'; // handle cases where the category might not be found
    };

    const handleDealSelection = (deal) => {
        const dealExists = selectedDeals.some(d => d.discountId === deal.discountId);
        if (dealExists) {
            setSelectedDeals(prevSelected => prevSelected.filter(d => d.discountId !== deal.discountId));
        } else {
            setSelectedDeals(prevSelected => [...prevSelected, { 
                description: deal.discountName, 
                isDutchieDeal: true, 
                discountId: deal.discountId 
            }]);
        }
    };

    const handleCategorySelection = (categoryId) => {
        if (selectedCategories.includes(categoryId)) {
            setSelectedCategories(prev => prev.filter(id => id !== categoryId));
        } else {
            setSelectedCategories(prev => [...prev, categoryId]);
        }
    };

    const handleSave = () => {
        console.log("Saving selected deals:", selectedDeals);
        onClose(selectedDeals);
    };

    useEffect(() => {
        if (!searchTerm) {
            setFilteredCategories(categories);
            return;
        }
    
        setFilteredCategories(
            categories.filter(category => 
                category.productCategoryName.toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm, categories]);

    return (
        <div className="dutchie-modal-content">
            <div className="selected-counter">
                {selectedDeals.length} deals selected
    
                <div className="search-row">
                    <div className="deal-search">
                        <input 
                            type="text" 
                            className='dutchiedealinput'
                            placeholder="Search deals..." 
                            value={dealSearchTerm}
                            onChange={handleDealSearchChange}
                        />
                    </div>
    
                    <div className="category-search" ref={dropdownRef}>
                        <input 
                            type="text" 
                            className='dutchiedealinput'
                            placeholder="Search categories..." 
                            value={searchTerm}
                            onChange={e => setSearchTerm(e.target.value)}
                            onFocus={() => setShowDropdown(true)}
                        />
                        {showDropdown && (
                            <div className="dropdown-container">
                                {filteredCategories.map(category => (
                                    <div className="category-item" key={category.productCategoryId}>
                                        <label className="category-label">
                                            <input 
                                                className='dutchiedealsboxes'
                                                type="checkbox"
                                                checked={selectedCategories.includes(category.productCategoryId)}
                                                onChange={() => handleCategorySelection(category.productCategoryId)}
                                            />
                                            <span>{category.productCategoryName}</span>
                                        </label>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        
            <div className="scrollable-deals-list">
                {filteredDeals.map((deal, index) => (
                    <div key={index} className="deal-item">
                        <input 
                            type="checkbox" 
                            className='dutchiedealsboxes'
                            checked={selectedDeals.some(d => d.discountId === deal.discountId)}
                            onChange={() => handleDealSelection(deal)}
                        />
                        <span>{deal.discountName}</span>
                    </div>
                ))}
            </div>
    
            <div className="modal-buttons">
                <button className="btn-save" onClick={handleSave}>Save</button>
            </div>
        </div>
    );
    
    
}

export default DutchieDealsModal;