import React, { useState, useEffect, useRef } from 'react';
import './ExportDealSheetModal2.css';
import SyncModal from './SyncModal'; // Import SyncModal
import ConfirmationModal from './ConfirmationModal';
import { v4 as uuidv4 } from 'uuid';
import ConfirmationModal2 from './ConfirmationModal2';
import AssetCreationWizard from './AssetCreationWizard';
import BackgroundModal from './BackgroundModal';
import HeaderUploadModal from './HeaderUploadModal';

function ExportDealSheetModal2({ dealSheetId, onClose }) {
    const [assets, setAssets] = useState([]);
    const [backgroundImages, setBackgroundImages] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const selectedStore = JSON.parse(localStorage.getItem('selectedStore'));
    const selectedStoreId = selectedStore ? selectedStore.id : null;
    const [categories, setCategories] = useState([]);
    const [selectedBackground, setSelectedBackground] = useState({});
    const [slideshows, setSlideshows] = useState([]);

    const [selectedCategory, setSelectedCategory] = useState(null);
    const [showSyncModal, setShowSyncModal] = useState(false);

    const [selectedSlideshow, setSelectedSlideshow] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [isSaving, setIsSaving] = useState(false);
    const [zoomInputs, setZoomInputs] = useState({});
    const [deletedAssetIds, setDeletedAssetIds] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState(new Set());
    const [showImageDropdown, setShowImageDropdown] = useState({});
    const [showCategoriesDropdown, setShowCategoriesDropdown] = useState({});
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [exportTriggered, setExportTriggered] = useState(false);
    const [showHeaderDropdown, setShowHeaderDropdown] = useState({});
    const [headerImages, setHeaderImages] = useState([]);

    const [newAssetIdent, setNewAssetIdent] = useState(null);
    const assetRefs = useRef({});


    const [showBackgroundModal, setShowBackgroundModal] = useState(false);
    const [showHeaderModal, setShowHeaderModal] = useState(false);






    const [showWizard, setShowWizard] = useState(false);


    const [skipSync, setSkipSync] = useState(null);

    const handleExportClick = () => {
        const hasSyncedAssets = assets.some(asset => asset.syncedSlideshowId);
        if (hasSyncedAssets) {
            setShowConfirmationModal(true);
            setExportTriggered(false); // Reset the export trigger
        } else {
            setSkipSync(false); // Ensure skipSync is false
            setExportTriggered(true); // Trigger the export
        }
    };

    const handleExportAndSync = () => {
        setSkipSync(false);
        setShowConfirmationModal(false);
        setExportTriggered(true); // Trigger the export
    };
    
    const handleSkipSync = () => {
        setSkipSync(true);
        setShowConfirmationModal(false);
        setExportTriggered(true); // Trigger the export
    };

    useEffect(() => {
        if (exportTriggered) {
            handleExport();
            setExportTriggered(false); // Reset the trigger
        }
    }, [exportTriggered]);

    useEffect(() => {
        if (newAssetIdent && assetRefs.current[newAssetIdent]) {
            assetRefs.current[newAssetIdent].scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        }
    }, [newAssetIdent]);


    const toggleImageDropdown = (index) => {
        setShowImageDropdown(prevState => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    };
    
    const toggleCategoriesDropdown = (index) => {
        setShowCategoriesDropdown(prevState => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    };




    const [currentSyncIndex, setCurrentSyncIndex] = useState(null);


    const [showDropdown, setShowDropdown] = useState({});

    const [customWidth, setCustomWidth] = useState('1920');
    const [customHeight, setCustomHeight] = useState('1080');

    // Add these states in ExportDealSheetModal2 component
    const [startTime, setStartTime] = useState('00:00');
    const [endTime, setEndTime] = useState('00:00');
    const [durationDays, setDurationDays] = useState(7);
    const [daysOffset, setDaysOffset] = useState(2);
    const [smartScheduleEnabled, setSmartScheduleEnabled] = useState(true);

    const predefinedSizes = {
        'VerticalDealSheet': { width: 1000, height: 1294 },
        'HorizontalDealSheet': { width: 1294, height: 1000 },
        'TVAsset': { width: 1920, height: 1080 },
        'VerticalTVAsset': { width: 1080, height: 1920 }
    };

    const gatherDataForExport = async () => {
        // 1. Fetch the main dealsheet data
        const dealSheetResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/deal-sheets/${dealSheetId}/?store_id=${selectedStoreId}`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        });
        const dealSheet = await dealSheetResponse.json();

        // 2. Extract dutchieDealIds
        const dutchieDealIds = [];
        dealSheet.categories.forEach(category => {
            category.linkeddeals.forEach(deal => {
                if (deal.isDutchieDeal) {
                    dutchieDealIds.push(deal.discountId);
                }
            });
        });

        let fetchedDeals = [];
        if (dutchieDealIds.length > 0) {
            // 3. Fetch the Dutchie deals
            const dutchieDealsResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/get-deals-by-ids/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Selected-Store-Id': selectedStoreId
                },
                body: JSON.stringify({ ids: dutchieDealIds })
            });
            fetchedDeals = await dutchieDealsResponse.json();
        }

        // Package the data together
        return {
            dealSheet,
            fetchedDeals
        };
    };

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_BASE_URL}/users/slideshows/?store_id=${selectedStoreId}`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'Selected-Store-Id': selectedStoreId
            }
        })
        .then(res => res.json())
        .then(data => setSlideshows(data))
        .catch(error => console.error("Error fetching slideshows:", error));
    }, [selectedStoreId]);

    useEffect(() => {
        async function fetchAssets() {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/deal-sheets/${dealSheetId}/assets/`, {
                headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
            });
            if (response.ok) {
                const fetchedAssets = await response.json();
                const now = new Date();
                // Current UTC time as start date
                const startDateUTC = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds())).toISOString();
                // One year from now as end date
                const oneYearLater = new Date(Date.UTC(now.getUTCFullYear() + 1, now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds())).toISOString();


                const startDateLocal = `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}-${String(now.getDate()).padStart(2, '0')}T${String(now.getHours()).padStart(2, '0')}:${String(now.getMinutes()).padStart(2, '0')}:${String(now.getSeconds()).padStart(2, '0')}`;

                // One year from now in local time without converting to UTC
                const oneYearLaterLocalDate = new Date(
                    now.getFullYear() + 1,
                    now.getMonth(),
                    now.getDate(),
                    now.getHours(),
                    now.getMinutes(),
                    now.getSeconds()
                );

                const oneYearLaterLocal = `${oneYearLaterLocalDate.getFullYear()}-${String(oneYearLaterLocalDate.getMonth() + 1).padStart(2, '0')}-${String(oneYearLaterLocalDate.getDate()).padStart(2, '0')}T${String(oneYearLaterLocalDate.getHours()).padStart(2, '0')}:${String(oneYearLaterLocalDate.getMinutes()).padStart(2, '0')}:${String(oneYearLaterLocalDate.getSeconds()).padStart(2, '0')}`;

                            
                const transformedAssets = fetchedAssets.map(asset => {



                    // Conditional determination of start and end dates
                    let useStartDate = asset.sync_start_date;
                    let useEndDate = asset.sync_end_date;
                    if (!asset.smartScheduleEnabled) {
                        useStartDate = startDateLocal; // Reset start date for assets without smart schedule
                        useEndDate = oneYearLaterLocal; // Reset end date for assets without smart schedule
                    }

                   // console.error("startDatelocal:", startDateLocal);
                    //console.error("endDatelocal:", oneYearLaterLocal);

                    //console.error("useStartDate:", asset.sync_start_date);
                    //console.error("useEndDate:", asset.sync_end_date);

                    
                    // Determine if the asset size matches any predefined size
                    let sizeName = "Custom";
                    Object.entries(predefinedSizes).forEach(([key, value]) => {
                        if (asset.width === value.width && asset.height === value.height) {
                            sizeName = key;
                        }
                    });
    
                    return {
                        name: asset.name,
                        background: {
                            url: asset.background_image_url,
                            primary_color: asset.primary_color,
                            secondary_color: asset.secondary_color
                        },
                        type: asset.dealsheet_type,
                        size: {
                            name: sizeName, // Use either a predefined size name or 'Custom'
                            width: asset.width,
                            height: asset.height
                        },
                        sync: asset.sync,
                        export: asset.download,
                        zoom: asset.zoom,
                        selectedCategories: asset.categories ? asset.categories : [],
                        syncedSlideshowId: asset.synced_slideshow_id,
                        syncedSlideshowName: asset.synced_slideshow_name,
                        syncStartDate: useStartDate, // Use determined start date
                        syncEndDate: useEndDate, // Use determined end date
                        syncDetails: asset.sync_details || {startTime: '', endTime: '', durationDays: 0, daysOffset: 0},
                        category: asset.category,
                        id: asset.id,
                        ident: asset.ident,
                        shouldSync: asset.shouldSync,
                        smartScheduleEnabled: asset.smartScheduleEnabled,
                        header: asset.header_image_url,
                        font: asset.font,

                    };
                });
                    // Sort the transformed assets alphabetically by name
                const sortedAssets = transformedAssets.sort((a, b) => a.name.localeCompare(b.name));

                setAssets(sortedAssets);
            } else {
                console.error("Error fetching assets");
            }
        }
        fetchAssets();
    }, [dealSheetId]);
    
    
    
    

    const handleSyncClick = (index) => {
        const asset = assets[index];
        
        setSelectedSlideshow(asset.syncedSlideshowId ? slideshows.find(s => s.id === asset.syncedSlideshowId) : '');
        setStartDate(asset.syncStartDate || '');
        setEndDate(asset.syncEndDate || '');
        setStartTime(asset.syncDetails?.startTime || '00:00');
        setEndTime(asset.syncDetails?.endTime || '00:00');
        setDurationDays(asset.syncDetails?.durationDays || 7);
        setDaysOffset(asset.syncDetails?.daysOffset || 2);
        setSmartScheduleEnabled(asset.smartScheduleEnabled);
        
        setCurrentSyncIndex(index);
        setShowSyncModal(true);
    };
    
    

    const toggleDropdown = (index) => {
        console.log("Toggling dropdown for index:", index); // Debug log
        setShowDropdown(prevShowDropdown => ({
            ...prevShowDropdown,
            [index]: !prevShowDropdown[index]
        }));
    };
    
    const handleBackgroundSelect = (index, bgImage, event) => {
        event.stopPropagation();
        console.log("Selecting background:", bgImage); // Debug log
        handlePropertyChange(index, 'background', bgImage);
        toggleImageDropdown(index);
    };

        
    const handleSyncConfirm = (selectedSlideshow, syncStartDate, syncEndDate, syncDetails, smartScheduleEnabled) => {
        if (currentSyncIndex !== null && selectedSlideshow) {
            setAssets(assets.map((asset, i) => {
                if (i === currentSyncIndex) {
                    return { 
                        ...asset, 
                        syncedSlideshowId: selectedSlideshow.id,
                        syncedSlideshowName: selectedSlideshow.name,
                        syncStartDate, // Update start date
                        syncEndDate,   // Update end date
                        syncDetails,    // Include additional sync details
                        smartScheduleEnabled,

                    };
                }
                return asset;
            }));
        }
        setCurrentSyncIndex(null); // Reset the index after syncing
    };

    useEffect(() => {
        async function fetchData() {
            setIsLoading(true);
            setError(null);
            try {
                const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/deal-sheets/${dealSheetId}/?store_id=${selectedStoreId}`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                });
    
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
    
                const dealSheetData = await response.json();
                setBackgroundImages(dealSheetData.background_images);
                setHeaderImages(dealSheetData.header_images)
                setCategories(dealSheetData.categories); // Assuming categories are part of the response

            } catch (error) {
                setError('Failed to load data: ' + error.message);
            } finally {
                setIsLoading(false);
            }
        }
    
        fetchData();
    }, [dealSheetId, selectedStoreId]);
    

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    const handleAddAssetWizard = () => {

        // Initialize asset with default values
        console.log('dealSheetId:', dealSheetId);
        setShowWizard(true); // Show the wizard
        // Define default size
        { /*
        const defaultSize = 'HorizontalDealSheet';
        const defaultWidth = parseInt(predefinedSizes[defaultSize].width, 10);
        const defaultHeight = parseInt(predefinedSizes[defaultSize].height, 10);
    
        // Current UTC time as start date
        const now = new Date();
        const startDateUTC = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds())).toISOString();
    
        // One year from now as end date
        const oneYearLater = new Date(Date.UTC(now.getUTCFullYear() + 1, now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds())).toISOString();
    
        // Adding a new asset
        setAssets(assets => [
            ...assets,
            {
                ident: uuidv4(),
                name: 'My Dealsheet',
                background: backgroundImages.length > 0 ? backgroundImages[0] : null,
                type: 'fullSheet',
                size: {
                    name: defaultSize,
                    width: defaultWidth,
                    height: defaultHeight
                },
                sync: false,
                shouldSync: false,
                download: true,
                export: true,
                zoom: 1,
                selectedCategories: [],
                smartScheduleEnabled: false, // Initialize with SmartSchedule disabled
                syncStartDate: startDateUTC, // Start now in UTC
                syncEndDate: oneYearLater, // End one year from now in UTC
            }
        ]);
    */}
    };

    const handleAddAsset = () => {

        // Define default size
        const defaultSize = 'HorizontalDealSheet';
        const defaultWidth = parseInt(predefinedSizes[defaultSize].width, 10);
        const defaultHeight = parseInt(predefinedSizes[defaultSize].height, 10);

        const newAssetIdent = uuidv4(); // Generate a unique identifier for the new asset
        setNewAssetIdent(newAssetIdent);
    
        // Current UTC time as start date
        const now = new Date();
        const startDateUTC = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds())).toISOString();
    
        // One year from now as end date
        const oneYearLater = new Date(Date.UTC(now.getUTCFullYear() + 1, now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds())).toISOString();

        // Select the first header image from the headerImages array, if available
        const firstHeaderImage = headerImages.length > 0 ? headerImages[0] : null;
    
        // Adding a new asset
        setAssets(assets => [
            ...assets,
            {
                ident: uuidv4(),
                name: 'My Dealsheet',
                background: backgroundImages.length > 0 ? backgroundImages[0] : null,
                header: firstHeaderImage,
                type: 'fullSheet',
                size: {
                    name: defaultSize,
                    width: defaultWidth,
                    height: defaultHeight
                },
                sync: false,
                shouldSync: false,
                download: true,
                export: true,
                font: 'Arial',
                zoom: 1,
                ident: newAssetIdent,
                selectedCategories: [],
                smartScheduleEnabled: false, // Initialize with SmartSchedule disabled
                syncStartDate: startDateUTC, // Start now in UTC
                syncEndDate: oneYearLater, // End one year from now in UTC
            }
        ]);
    };
    
    

    const handleDeleteAsset = (index) => {
        const assetToDelete = assets[index];
        console.log("Deleting asset:", assetToDelete);
        if (assetToDelete && assetToDelete.id) {
            setDeletedAssetIds(prev => [...prev, assetToDelete.id]);
        } else {
            console.error("Deleted asset does not have an ID", assetToDelete);
        }
        setAssets(assets => assets.filter((_, i) => i !== index));
    };

    const handleCategorySelectionChange = (index, categoryId, isChecked) => {
        setAssets(assets.map((asset, i) => {
            if (i === index) {
                const updatedSelectedCategories = isChecked 
                    ? [...asset.selectedCategories, categoryId] // Add categoryId
                    : asset.selectedCategories.filter(id => id !== categoryId); // Remove categoryId
                return { ...asset, selectedCategories: updatedSelectedCategories };
            }
            return asset;
        }));
    };

    // Render Select Categories Dropdown
    const renderSelectCategoriesDropdown = (index) => {
        const asset = assets[index];
        if (!asset) {
            console.error("Asset not found at index", index);
            return null;
        }
        
        
        return (
            <div className="custom-dropdown">
                <button className="custom-dropdown-button" onClick={() => toggleCategoriesDropdown(index)}>
                    Select Categories
                    <span className="fa fa-caret-down"></span>
                </button>
                {showCategoriesDropdown[index] && (
                    <div className={`custom-dropdown-content show-dropdown-content`}>
                        {categories.map((category, catIndex) => (
                            <div key={catIndex}>
                                <input 
                                    type="checkbox"
                                    id={`category-${index}-${category.id}`}
                                    checked={asset.selectedCategories.includes(category.id)}
                                    onChange={(e) => handleCategorySelectionChange(index, category.id, e.target.checked)}
                                />
                                <label htmlFor={`category-${index}-${category.id}`}>{category.name}</label>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        );
    };

    
    

    const handleSizeChange = (index, value) => {
        let newSize;
        if (value === 'Custom') {
            const asset = assets[index];
            newSize = {
                name: 'Custom',
                width: asset.size.width, 
                height: asset.size.height
            };
            setCustomWidth(asset.size.width);
            setCustomHeight(asset.size.height);
        } else {
            const sizeData = predefinedSizes[value];
            newSize = {
                name: value,
                width: sizeData.width,
                height: sizeData.height
            };
        }
        handlePropertyChange(index, 'size', newSize);
    };
    
    
    

    const handleCustomSizeChange = (index, width, height) => {
        // Parse width and height to integers
        const newWidth = width !== undefined ? parseInt(width, 10) : assets[index].size.width;
        const newHeight = height !== undefined ? parseInt(height, 10) : assets[index].size.height;
    
        // Update global custom size state
        setCustomWidth(newWidth);
        setCustomHeight(newHeight);
    
        // Update custom size of the specific asset
        const updatedSize = {
            name: 'Custom',
            width: newWidth,
            height: newHeight
        };
        handlePropertyChange(index, 'size', updatedSize);
    };
    
    
    
    const handlePropertyChange = (index, property, value) => {
        const updatedAssets = assets.map((asset, i) => {
            if (i === index) {
                return { ...asset, [property]: value };
            }
            return asset;
        });
        setAssets(updatedAssets);
    };

    const handleZoomChange = (index, value) => {
        const zoomValue = value / 100; // Convert percentage to a decimal
        const updatedAssets = assets.map((asset, i) => {
            if (i === index) {
                return { ...asset, zoom: zoomValue };
            }
            return asset;
        });
        setAssets(updatedAssets);
    };

    const handleZoomInputChange = (index, value) => {
        setZoomInputs({ ...zoomInputs, [index]: value });
    };
    
    const handleZoomInputBlur = (index) => {
        const zoomValue = zoomInputs[index] ? parseFloat(zoomInputs[index]) / 100 : 1;
        const updatedAssets = assets.map((asset, i) => {
            if (i === index) {
                return { ...asset, zoom: zoomValue };
            }
            return asset;
        });
        setAssets(updatedAssets);
    };

    const renderBackgroundOption = (bgImage) => (
        <div className="background-option" style={{ backgroundImage: `url(${bgImage.url})` }}>
            {bgImage.color}
        </div>
    );

    const handleExportCheckboxChange = (index, checked) => {
        const updatedAssets = assets.map((asset, i) => {
            if (i === index) {
                return { ...asset, export: checked };
            }
            return asset;
        });
        setAssets(updatedAssets);
    };
    
    const handleCategoryChange = (index, categoryId) => {
        const updatedAssets = assets.map((asset, i) => {
            if (i === index) {
                return { ...asset, category: categoryId }; // Set the category ID
            }
            return asset;
        });
        setAssets(updatedAssets);
    };

    const handleSyncCheckboxChange = (index) => {
        setAssets(assets.map((asset, i) => {
            if (i === index) {
                return { ...asset, shouldSync: !asset.shouldSync };
            }
            return asset;
        }));
    };

    const calculateSyncDates = (syncDetails, smartScheduleEnabled) => {
        // When smartSchedule is not enabled, default start and end dates to current date and one year later
        if (!smartScheduleEnabled) {
            const now = new Date();
            const startDate = formatLocalDateTime(now);
            const oneYearLater = new Date(now.setFullYear(now.getFullYear() + 1));
            const endDate = formatLocalDateTime(oneYearLater);
            return { startDate, endDate };
        }
    
        // If smartSchedule is enabled, calculate based on the provided syncDetails
        if (!syncDetails || !syncDetails.startTime || !syncDetails.endTime) return { startDate: '', endDate: '' };
    
        const { startTime, endTime, durationDays, daysOffset } = syncDetails;
        const startDateTime = calculateStartDateTime(daysOffset, startTime);
        const endDateTime = calculateEndDateTime(startDateTime, durationDays, endTime);
    
        return { startDate: startDateTime, endDate: endDateTime };
    };
    
    const formatLocalDateTime = (date) => {
        return date.getFullYear() +
          '-' +
          String(date.getMonth() + 1).padStart(2, '0') +
          '-' +
          String(date.getDate()).padStart(2, '0') +
          'T' +
          String(date.getHours()).padStart(2, '0') +
          ':' +
          String(date.getMinutes()).padStart(2, '0');
    };

    const calculateStartDateTime = (offsetDays, time) => {
        // Check if the time string is valid (HH:MM format)
        if (!time.match(/^([01]\d|2[0-3]):([0-5]\d)$/)) {
            return '';
        }
    
        const start = new Date();
        start.setDate(start.getDate() + parseInt(offsetDays, 10));
        const [hours, minutes] = time.split(':');
        start.setHours(hours, minutes, 0, 0); // Reset seconds and milliseconds
    
        // Convert to local ISO string
        const timezoneOffset = start.getTimezoneOffset() * 60000; // offset in milliseconds
        return (new Date(start - timezoneOffset)).toISOString().slice(0, 16);
    };
    
    const calculateEndDateTime = (startDateTime, duration, time) => {
        // Check if the time string is valid (HH:MM format)
        if (!time.match(/^([01]\d|2[0-3]):([0-5]\d)$/)) {
            return '';
        }
    
        // Check if duration is a valid number
        const durationDays = parseInt(duration, 10);
        if (isNaN(durationDays) || durationDays < 1) {
            return ''; // Return empty or a default value
        }
    
        const end = new Date(startDateTime);
        end.setDate(end.getDate() + durationDays);
        const [hours, minutes] = time.split(':');
        end.setHours(hours, minutes, 0, 0); // Reset seconds and milliseconds
    
        // Convert to local ISO string
        const timezoneOffset = end.getTimezoneOffset() * 60000; // offset in milliseconds
        return (new Date(end - timezoneOffset)).toISOString().slice(0, 16);
    };

    const transformAssetsToExportAssets = (assets) => {
        return assets.map(asset => {
            const { startDate, endDate } = calculateSyncDates(asset.syncDetails, asset.smartScheduleEnabled);


            return {
                ident: asset.ident,
                name: asset.name,
                backgroundImageURL: asset.background ? asset.background.url : '',
                dealsheetType: asset.type,
                width: asset.size.width,
                height: asset.size.height,
                download: asset.export,
                zoom: asset.zoom || 1,
                category: asset.category,
                categories: asset.selectedCategories,
                selectedCategories: asset.selectedCategories,
                // Add sync related fields
                syncDetails: {
                    startTime: asset.syncDetails ? asset.syncDetails.startTime : '',
                    endTime: asset.syncDetails ? asset.syncDetails.endTime : '',
                    durationDays: asset.syncDetails ? asset.syncDetails.durationDays : 0,
                    daysOffset: asset.syncDetails ? asset.syncDetails.daysOffset : 0
                },
                syncStartDate: startDate,
                syncEndDate: endDate,
                syncedSlideshowId: asset.syncedSlideshowId || null,
                syncedSlideshowName: asset.syncedSlideshowName || '',
                smartScheduleEnabled: asset.smartScheduleEnabled,
                shouldSync: asset.shouldSync,
                headerImageURL: asset.header,
                font: asset.font,
            };
        });
    };
    


    async function handleExport() {
        const exportAssets = transformAssetsToExportAssets(assets, predefinedSizes);

        const shouldDownload = exportAssets.some(asset => asset.download);

        // Perform export logic with exportAssets
        console.log('Exporting assets:', exportAssets);
        setIsSaving(true);
        try {
            const completeData = await gatherDataForExport();

            console.log("Complete Data before export:", completeData);
    
            // Request the image from the backend.
            const exportResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/export_deal_sheet/`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ...completeData,
                    assets,
                    skipSync,
                
                })
                
            });
    
            // Error handling for the fetch request.
            if (!exportResponse.ok) {
                throw new Error('Failed to generate the image');
            }
    
            if (shouldDownload) {
                const blob = await exportResponse.blob();
                // Assuming the response will always be a blob that we want to inspect and potentially download
                const text = await blob.text();
                console.log(text.slice(0, 100));
    
                // Convert the blob to an array buffer
                const arrayBuffer = await blob.arrayBuffer();
    
                // Create a new Uint8Array from the buffer
                const uintArray = new Uint8Array(arrayBuffer);
    
                // Log the first 4 bytes of the image data
                console.log(uintArray.slice(0, 4));
    
                // Trigger a download of the blob as a zip file
                if (blob.size > 0) {
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = url;
                    a.download = `${selectedStore.name.replace(/ /g, "_")}_${String(new Date().getMonth() + 1).padStart(2, '0')}_${String(new Date().getDate()).padStart(2, '0')}_Sheets.zip`;
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                } else {
                    console.log("The blob does not contain any data.");
                }
            }
            onClose();  // Close the modal after exporting.
            // Save the assets to the server
            const requestBody = {
                updatedAssets: exportAssets,
                deletedAssetIds: deletedAssetIds,
            };
            const saveResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/deal-sheets/${dealSheetId}/assets/set/`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestBody)
            });

            if (!saveResponse.ok) {
                throw new Error('Failed to save assets');
            }

            // Proceed with other export logic
        } catch (error) {
            console.error('There was an error:', error);
        }
        setIsSaving(false);
    }

    const handleAddNewAsset = async (newAsset) => {
        // Add the asset to the local state
        setAssets(currentAssets => [...currentAssets, newAsset]);
    
        // Also trigger a save to the backend
        await saveAssetsToBackend([...assets, newAsset]);
    };

    const saveAssetsToBackend = async (updatedAssets) => {
        // Prepare the request body
        const requestBody = {
            updatedAssets: transformAssetsToExportAssets(updatedAssets),  // Transform the assets for the backend
            deletedAssetIds: deletedAssetIds,
        };
    
        try {
            const saveResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/deal-sheets/${dealSheetId}/assets/set/`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestBody)
            });
    
            if (!saveResponse.ok) {
                throw new Error('Failed to save assets');
            }
            console.log('Assets saved successfully to the backend.');
        } catch (error) {
            console.error('Error saving assets:', error);
        }
    };
    

    const handleCopyToClipboard = async () => {
        try {
            const completeData = await gatherDataForExport();
    
            let dataString = '';
            completeData.dealSheet.categories.forEach(category => {
                dataString += category.name + '\n';
    
                let maxDealLength = 0;
                category.linkeddeals.forEach(deal => {
                    const dealName = deal.description.split(/[-|]/)[0].trim();
                    if (dealName.length > maxDealLength) {
                        maxDealLength = dealName.length;
                    }
                });
    
                category.linkeddeals.forEach(deal => {
                    if (deal.description.includes('-') || deal.description.includes('|')) {
                        const parts = deal.description.split(/[-|]/);
                        const dealName = parts[0].trim();
                        const dealInfo = parts.slice(1).join(' ').trim();
    
                        const paddedDealName = dealName + ' ' + '-'.repeat(maxDealLength - dealName.length);
                        const adjustedDescription = paddedDealName + '| ' + dealInfo;
                        dataString += adjustedDescription + '\n';
                    } else {
                        dataString += deal.description + '\n';
                    }
                });
    
                dataString += '\n';
            });
    
            await navigator.clipboard.writeText(dataString);
            alert("Dealsheet Data copied to clipboard! \nPaste on Weedmaps, Email, or other Marketing Copy");
        } catch (error) {
            console.error("Failed to copy data to clipboard:", error);
        }
    };

    // Function to add a new background image
    const addBackgroundImage = (newImage) => {
        setBackgroundImages(currentImages => [...currentImages, newImage]);
    };

    // Function to remove a background image
    const refreshBackgroundImage = async (imageUrl) => {
        setBackgroundImages(currentImages => currentImages.filter(image => image.url !== imageUrl));
    };

    const refreshHeaderImage = async (headerUrl) => {
        setHeaderImages(currentImages => currentImages.filter(image => image !== headerUrl));
    };

    const addHeaderImage = (newHeaderUrl) => {
        setHeaderImages(currentHeaderImages => [...currentHeaderImages, newHeaderUrl]);
    };

    const handleHeaderSelect = (index, headerUrl, event) => {
        event.preventDefault(); // Prevent default action
        const updatedHeader = headerUrl === 'no-header' ? null : headerUrl; // Check if 'No Header' was selected
        setAssets(assets.map((asset, i) => {
            if (i === index) {
                return { ...asset, header: updatedHeader }; // Update the asset with the selected or no header URL
            }
            return asset;
        }));
        toggleHeaderDropdown(index); // Close the dropdown
    };
    
    

    const toggleHeaderDropdown = (index) => {
        setShowHeaderDropdown(prevState => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    };

    const handleDuplicateAsset = (index) => {
        const assetToDuplicate = assets[index];
        const duplicatedAsset = {
            ...assetToDuplicate,
            ident: uuidv4(), // Ensure the duplicated asset has a unique identifier
        };
    
        setAssets([...assets, duplicatedAsset]);
    };

    

    return (
        <div className="export-deal-sheet-modal2">
            <h2>Export Dealsheet Assets</h2>
            <div className="scrollable-asset-rows">
            {assets.map((asset, index) => (
                <div key={index} className="asset-row" ref={el => assetRefs.current[asset.ident] = el}>
                    <input
                        type="text"
                        placeholder="Name"
                        value={asset.name}
                        onChange={(e) => handlePropertyChange(index, 'name', e.target.value)}
                    />
                    <div className="custom-dropdown">
                        <button className="custom-dropdown-button" onClick={() => toggleImageDropdown(index)}>
                            {asset.background ? (
                                <div className="background-option" style={{ backgroundImage: `url(${asset.background.url})` }}></div>
                            ) : (
                                'Select Background'
                            )}
                            <span className="fa fa-caret-down"></span>
                        </button>
                        {showImageDropdown[index] && (
                            <div className={`custom-dropdown-content show-dropdown-content`}>
                                {backgroundImages.map((bgImage, bgIndex) => (
                                    <div key={bgIndex} onClick={(e) => handleBackgroundSelect(index, bgImage, e)}>
                                        {renderBackgroundOption(bgImage)}
                                    </div>
                                ))}
                                <div onClick={() => setShowBackgroundModal(true)}>Add New Background</div>
                            </div>
                        )}
                    </div>

                    <div className="custom-dropdown">
                        <button className="custom-dropdown-button" onClick={() => toggleHeaderDropdown(index)}>
                            {asset.header ? (
                                <img src={asset.header} alt="Header Preview" style={{ width: '100px', height: 'auto' }} />
                            ) : 'Select Header'}
                            <span className="fa fa-caret-down"></span>
                        </button>
                        {showHeaderDropdown[index] && (
                            <div className={`custom-dropdown-content show-dropdown-content`}>
                                <div onClick={(e) => handleHeaderSelect(index, 'no-header', e)}>
                                    No Header
                                </div>
                                {headerImages.map((headerUrl, headerIndex) => (
                                    <div key={headerIndex} onClick={(e) => handleHeaderSelect(index, headerUrl, e)}>
                                        <img src={headerUrl} alt={`Header ${headerIndex}`} style={{ width: '100px', height: '50px' }} />
                                    </div>
                                ))}
                                <div onClick={() => setShowHeaderModal(true)}>Add New Header</div>
                            </div>
                        )}
                    </div>

                    {/* Type Dropdown - dealsheetType */}
                    <select
                        value={asset.type}
                        onChange={(e) => handlePropertyChange(index, 'type', e.target.value)}
                    >
                        <option value="fullSheet">All Categories</option>
                        <option value="selectCategories">Select Categories</option>
                        <option value="singleCategory">Single Category</option>
                    </select>
                    {asset.type === 'selectCategories' && renderSelectCategoriesDropdown(index)}

                    {/* Category Dropdown - only shown if dealsheetType is singleCategory */}
                    {asset.type === 'singleCategory' && (
                        <select
                            value={asset.category || ''}
                            onChange={(e) => handleCategoryChange(index, e.target.value)}
                        >
                            <option value="">Select Category</option>
                            {categories.map((category, catIndex) => (
                                <option key={catIndex} value={category.id}>{category.name}</option>
                            ))}
                        </select>
                    )}
                    <select
                        value={asset.size.name}
                        onChange={(e) => handleSizeChange(index, e.target.value)}
                    >
                        <option value="VerticalDealSheet">Vertical Deal Sheet</option>
                        <option value="HorizontalDealSheet">Horizontal Deal Sheet</option>
                        <option value="TVAsset">TV Asset</option>
                        <option value="VerticalTVAsset">Vertical TV Asset</option>
                        <option value="Custom">Custom</option>
                    </select>
                    {asset.size.name === 'Custom' && (
                        <div className="custom-size-inputs">
                            <label>
                                W(px):
                                <input 
                                    className='zoom-input'
                                    type="number" 
                                    placeholder="Width" 
                                    max="3000" 
                                    value={asset.size.width} // Use asset's custom width
                                    onChange={(e) => handleCustomSizeChange(index, e.target.value, undefined)}
                                />
                            </label>
                            <label>
                                H(px):
                                <input 
                                    className='zoom-input'
                                    type="number" 
                                    placeholder="Height" 
                                    max="3000" 
                                    value={asset.size.height} // Use asset's custom height
                                    onChange={(e) => handleCustomSizeChange(index, undefined, e.target.value)}
                                />
                            </label>
                        </div>
                    )}
                    <label>
                        Zoom:
                        <input 
                            className='zoom-input'
                            type="number"
                            value={zoomInputs[index] !== undefined ? zoomInputs[index] : ((asset.zoom || 1) * 100).toString()}
                            onChange={(e) => handleZoomInputChange(index, e.target.value)}
                            onBlur={() => handleZoomInputBlur(index)}
                            min="0"
                            max="300"
                        />
                        %
                    </label>
                    <button className="sync-button" onClick={() => handleSyncClick(index)}>
                        {asset.syncedSlideshowName ? `Synced: ${asset.syncedSlideshowName}` : 'Sync'}
                    </button>
                    <label className="export-checkbox-label">
                        <input
                            type="checkbox"
                            checked={asset.shouldSync}
                            onChange={() => handleSyncCheckboxChange(index)}
                            className="sync-checkbox"
                        />
                        Sync Asset
                    </label>
                    <label className="export-checkbox-label">
                        <input
                            type="checkbox"
                            checked={asset.export} // Directly use asset.export without || false
                            onChange={(e) => handleExportCheckboxChange(index, e.target.checked)}
                            className="export-checkbox"
                        />
                        Download Image
                    </label>
                    <button className="copy-button" onClick={() => handleDuplicateAsset(index)}>Copy</button>
                    <button className="delete-button" onClick={() => handleDeleteAsset(index)}>Delete</button>
                </div>
            ))}
            </div>
            <div className="export-modal2-controls">
                <button className="add-asset-button" onClick={handleAddAsset}>+ Add Asset</button>
                <button className="add-asset-wizard-button" onClick={handleAddAssetWizard}>Asset Wizard</button>
                <button className="export-button" onClick={handleExportClick} disabled={isSaving}>
                    {isSaving ? (
                        <div className="dot-spinner">
                            <div class="dot-spinner__dot"></div>
                            <div class="dot-spinner__dot"></div>
                            <div class="dot-spinner__dot"></div>
                            <div class="dot-spinner__dot"></div>
                            <div class="dot-spinner__dot"></div>
                            <div class="dot-spinner__dot"></div>
                            <div class="dot-spinner__dot"></div>
                            <div class="dot-spinner__dot"></div>
                        </div>
                    ) : (
                        'Export Assets'
                    )}
                </button>
                <button className="copy-button" onClick={handleCopyToClipboard}>Copy to Clipboard</button>
                <button className="close-button" onClick={onClose}>Close</button>
            </div>
            {showWizard && (
            <AssetCreationWizard
                onSave={handleAddNewAsset}
                onClose={() => setShowWizard(false)}
                backgroundImages={backgroundImages}
                addBackgroundImage={addBackgroundImage}
                addHeaderImage={addHeaderImage}
                predefinedSizes={predefinedSizes}
                categories={categories}
                slideshows={slideshows} // Passing slideshows if necessary for SyncOptionsStep
                dealSheetId={dealSheetId}
                headerImages={headerImages}
                refreshBackgroundImage={refreshBackgroundImage}
                refreshHeaderImage={refreshHeaderImage}
            />
            )}
            {showSyncModal && (
                <SyncModal
                isOpen={showSyncModal}
                onClose={() => setShowSyncModal(false)}
                slideshows={slideshows}
                onSync={handleSyncConfirm}
                selectedSlideshow={selectedSlideshow}
                setSelectedSlideshow={setSelectedSlideshow}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                startTime={startTime}
                setStartTime={setStartTime}
                endTime={endTime}
                setEndTime={setEndTime}
                durationDays={durationDays}
                setDurationDays={setDurationDays}
                daysOffset={daysOffset}
                setDaysOffset={setDaysOffset}
                smartScheduleEnabled={smartScheduleEnabled}
                setSmartScheduleEnabled={setSmartScheduleEnabled}
            />
            
            )}

            <ConfirmationModal2
                isOpen={showConfirmationModal}
                onCancel={handleSkipSync} // Specific action when cancel is clicked
                onConfirm={handleExportAndSync}
                title="This Export Contains Synced Assets That Will Alter Your Slideshows."
                message="Are you sure you want to continue?"
                confirmButtonText="Export & Sync"
                cancelButtonText="Skip Sync"
            />

            {showBackgroundModal && (
                <BackgroundModal
                    onClose={() => setShowBackgroundModal(false)}
                    dealSheetId={dealSheetId}
                    addBackgroundImage={(newImage) => {
                        addBackgroundImage(newImage); // Add the new background to your state
                        setShowBackgroundModal(false); // Close the modal
                    }}
                />
            )}

            {showHeaderModal && (
                <HeaderUploadModal
                    onClose={() => setShowHeaderModal(false)}
                    dealSheetId={dealSheetId}
                    addHeaderImage={(newHeaderUrl) => {
                        addHeaderImage(newHeaderUrl); // Add the new header to your state
                        setShowHeaderModal(false); // Close the modal
                    }}
                />
            )}

            
            
        </div>
    );
}

export default ExportDealSheetModal2;
