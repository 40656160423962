import React, { useState, useEffect } from 'react';

const PreviewDealSheetStep = ({ asset, setAsset, dealSheetId, isActive }) => {
  const [previewUrl, setPreviewUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [lastFetchTime, setLastFetchTime] = useState(0);

  // Zoom input state
  const [zoomInputValue, setZoomInputValue] = useState(`${asset.zoom * 100}`);

  useEffect(() => {
    setZoomInputValue(`${asset.zoom * 100}`);
    if (isActive) {
        fetchPreview();
    }
  }, [isActive, asset.zoom, setAsset]);

  const handleSliderChange = (e) => {
    const zoomValue = e.target.value / 100;
    setZoomInputValue(`${zoomValue * 100}`);
    setAsset({ ...asset, zoom: zoomValue });
  };

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setZoomInputValue(newValue);
  };

  const handleInputBlur = () => {
    const zoomValue = Math.max(10, Math.min(parseFloat(zoomInputValue), 300)) / 100;
    setAsset({ ...asset, zoom: zoomValue });
  };

  // This function encapsulates the fetch logic
  const fetchPreview = async () => {
    const now = Date.now();
    // Check cooldown period
    if (now - lastFetchTime < 5000) {
      console.log("Waiting before fetching again.");
      return;
    }

    setIsLoading(true);
    setError('');
    setLastFetchTime(now); // Update fetch time to now

    // Fetch selectedStoreId directly from localStorage
    const selectedStore = JSON.parse(localStorage.getItem('selectedStore'));
    const selectedStoreId = selectedStore ? selectedStore.id : null;

    try {
      const token = localStorage.getItem('token');
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      };

      const dealSheetResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/deal-sheets/${dealSheetId}/?store_id=${selectedStoreId}`, { headers });
      if (!dealSheetResponse.ok) throw new Error('Failed to fetch dealSheet');
      const dealSheet = await dealSheetResponse.json();

      const dutchieDealIds = dealSheet.categories.flatMap(category => 
        category.linkeddeals.filter(deal => deal.isDutchieDeal).map(deal => deal.discountId)
      );

      let fetchedDeals = [];
      if (dutchieDealIds.length > 0) {
        const dutchieDealsResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/get-deals-by-ids/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Selected-Store-Id': selectedStoreId
          },
          body: JSON.stringify({ ids: dutchieDealIds })
        });
        if (!dutchieDealsResponse.ok) throw new Error('Failed to fetch dutchie deals');
        fetchedDeals = await dutchieDealsResponse.json();
      }

      const previewResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/export_single_deal_image/`, {
        method: 'POST',
        headers,
        body: JSON.stringify({ dealSheet, fetchedDeals, asset }),
      });

      if (!previewResponse.ok) throw new Error('Failed to generate preview');
      const blob = await previewResponse.blob();
      const objectURL = URL.createObjectURL(blob);
      setPreviewUrl(objectURL);
    } catch (error) {
      setError(`Failed to load preview: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownloadPreview = () => {
    if (previewUrl) {
      const link = document.createElement('a');
      link.href = previewUrl;
      link.setAttribute('download', 'preview.png'); // or any other extension
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <div>
      <h3>Preview</h3>
      {isLoading && <div>Loading preview...</div>}
      {error && <div>Error: {error}</div>}
      
      {/* Zoom controls shown regardless of loading or error state */}
      <div>
        <label>Zoom: </label>
        <input
          type="range"
          min="10"
          max="300"
          value={zoomInputValue}
          onChange={handleSliderChange}
          style={{ display: 'block', width: '100%' }}
        />
        <input
          type="number"
          value={zoomInputValue}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          min="10"
          max="300"
          style={{ width: '60px' }}
        /> %
      </div>
      
      {previewUrl && (
        <>
          <img 
            src={previewUrl} 
            alt="Deal Sheet Preview" 
            style={{ maxWidth: '500px', maxHeight: '500px', display: 'block', margin: '0 auto' }} 
          />
          <button onClick={handleDownloadPreview}>
            Download Preview
          </button>
        </>
      )}
    </div>
  );
};

export default PreviewDealSheetStep;