import React from 'react';
import './TnCPage.css'; // Make sure to create this CSS file

const PrivacyPolicy = () => {
    return (
        <div className='pagewrapper'>
            <div className='background-wrapper tncwrapper'>
                <h1 className='tnc-header'>Privacy Policy for Greenmind Studio</h1>
                <h2>Last Updated: 2/4/2024</h2>
                <div className='terms-wrapper'>
                    <div className='terms-content'>
                        <p>Welcome to Greenmind Studio, the mobile application ("App") that manages display signage for retailers, developed and operated by GREENMIND STUDIO, LLC ("we," "us," or "our"). Your privacy is of paramount importance to us. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our mobile application and use our services. Please read this privacy policy carefully. If you do not agree with the terms of this privacy policy, please do not access the app.</p>
                        
                        <h3>Information We Collect</h3>
                        <p>We may collect the following types of information:</p>
                        <ul>
                            <li>Personal Identification Information: Name, email address, phone number, etc.</li>
                            <li>App Usage Data: Information on how you use our App, such as the features you interact with and the time you spend on the App.</li>
                            <li>Device Information: Information about the mobile device you use to access our App, including hardware model, operating system, unique device identifiers, and mobile network information.</li>
                        </ul>

                        <h3>How We Use Your Information</h3>
                        <p>We use the information we collect in various ways, including to:</p>
                        <ul>
                            <li>Provide, operate, and maintain our App</li>
                            <li>Improve, personalize, and expand our App</li>
                            <li>Understand and analyze how you use our App</li>
                            <li>Develop new products, services, features, and functionality</li>
                            <li>Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the App, and for marketing and promotional purposes</li>
                            <li>Send you emails</li>
                            <li>Find and prevent fraud</li>
                        </ul>

                        <h3>How We Share Your Information</h3>
                        <p>We may share your information with third parties in the following situations:</p>
                        <ul>
                            <li>With Your Consent: We may disclose your personal information for any purpose with your consent.</li>
                            <li>Service Providers: We may share your information with third-party vendors, service providers, contractors, or agents who perform services for us or on our behalf and require access to such information to do that work.</li>
                            <li>Business Transfers: We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</li>
                        </ul>

                        <h3>Security of Your Information</h3>
                        <p>We use administrative, technical, and physical security measures to help protect your personal information. While we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other type of misuse.</p>

                        <h3>Your Privacy Rights</h3>
                        <p>Depending on your location, you may have certain rights under applicable data protection laws. These may include the right (i) to request access and obtain a copy of your personal information, (ii) to request rectification or erasure; (iii) to restrict the processing of your personal information; and (iv) if applicable, to data portability.</p>

                        <h3>Policy for Children</h3>
                        <p>We do not knowingly solicit information from or market to children under the age of 13. If you become aware of any data we have collected from children under age 13, please contact us using the contact information provided below.</p>

                        <h3>Changes to This Privacy Policy</h3>
                        <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. We will let you know via email and/or a prominent notice on our App, prior to the change becoming effective and update the "Last updated" date at the top of this Privacy Policy.</p>

                        <h3>Contact Us</h3>
                        <p>If you have questions or comments about this Privacy Policy, please contact us at:</p>
                        <p>support@greenmind.studio</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
