import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './FunctionSelectPage.css'; // Make sure to create and import the CSS file

function FunctionSelectPage() {
    const navigate = useNavigate();

    useEffect(() => {
        // Automatically navigate based on selectedDevice type
        const selectedDevice = JSON.parse(localStorage.getItem('selectedDevice'));
        if (selectedDevice && selectedDevice.type) {
            switch (selectedDevice.type) {
                case 'slideshow':
                    // Ensure you have a currentSlideshowId to navigate to the slideshow
                    if (selectedDevice.target) {
                        localStorage.setItem('currentSlideshowId', selectedDevice.target);
                        navigate('/slideshowplayer');
                    }
                    break;
                case 'queue':
                    // Ensure you have a selectedStore to navigate to the queue
                    const store = JSON.parse(localStorage.getItem('selectedStore'));
                    if (store && store.id === selectedDevice.target) {
                        navigate('/queue');
                    }
                    break;
                default:
                    // No automatic navigation if device type is unknown
                    console.log('Unknown device type or no specific action required');
            }
        }
    }, [navigate]);

    const updateDeviceForQueue = async () => {
        const device = JSON.parse(localStorage.getItem('selectedDevice'));
        const store = JSON.parse(localStorage.getItem('selectedStore'));

        if (device && store) {
            device.type = 'queue';
            device.target = store.id;
            localStorage.setItem('selectedDevice', JSON.stringify(device));

            // Optionally, send this updated device info to your backend
            // This step would be similar to the updateDevice function in SlideshowSelectionPage
            // You should define this function based on your backend API requirements
            try {
                const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/devices/update`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    },
                    body: JSON.stringify(device)
                });
                if (!response.ok) {
                    throw new Error('Failed to update device');
                }
                const updatedDevice = await response.json();
                console.log('Device updated successfully:', updatedDevice);
            } catch (error) {
                console.error("Error updating device:", error);
            }
        }
    };

    const handleSelect = (path) => {
        if (path === '/queue') {
            updateDeviceForQueue().then(() => {
                navigate(path);
            });
        } else {
            navigate(path);
        }
    };

    return (
        <div className="function-select-page">
            <div 
                className="function-option slideshow-option" 
                onClick={() => handleSelect('/select-slideshow')}
            >
                <h2>Slideshow</h2>
            </div>
            <div 
                className="function-option queue-option" 
                onClick={() => handleSelect('/queue')}
            >
                <h2>Queue</h2>
            </div>
        </div>
    );
}

export default FunctionSelectPage;
