import React, { useState, useEffect } from 'react';
import './EditStoreModal.css';

function EditStoreModal({ store, onClose, onSave }) {
    const [editedStore, setEditedStore] = useState(store);
    const [file, setFile] = useState(null);
    const [queueBackgroundImage, setQueueBackgroundImage] = useState(null);
    const [primaryTextColor, setPrimaryTextColor] = useState(store.primary_text_color || "#FFFFFF");
    const [secondaryTextColor, setSecondaryTextColor] = useState(store.secondary_text_color || "#FFFFFF");


    const handleQueueBackgroundImageChange = (e) => {
        setQueueBackgroundImage(e.target.files[0]);
    };

    const handleImageChange = (e) => {
        setFile(e.target.files[0]);
    }

    const uploadFile = async (file) => {
        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/upload_file/`, {
                method: 'POST',
                body: formData,
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            const jsonResponse = await response.json();

            if (response.ok) {
                return jsonResponse.fileURL.split("?")[0].replace(/ /g, '+');
            } else {
                console.error("Error uploading file. Status:", response.status, "Text:", await response.text());
                return null;
            }
        } catch(err) {
            console.error("Error uploading file:", err);
            return null;
        }
    };

    const saveChanges = async () => {
        let logoUrl = editedStore.logo_url; // Default to current logo URL in case no new file is uploaded
        if(file) {
            logoUrl = await uploadFile(file);
        }

        let queueBgUrl = editedStore.queue_bg_url; // Default to current background URL in case no new file is uploaded
        if(queueBackgroundImage) {
            queueBgUrl = await uploadFile(queueBackgroundImage);
        }

        const updatedStore = { 
            ...editedStore,
            logo_url: logoUrl,
        };

        onSave(updatedStore);
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setEditedStore(prevState => ({ ...prevState, [name]: value.replace(/ /g, ' ') }));
    };

    return (
        <div className="edit-modal">
            <h2>Edit Store</h2>
            
            <div className="input-row">
                <label htmlFor="storeName">Store Name:</label>
                <input 
                    id="storeName"
                    type="text" 
                    name="name" 
                    value={editedStore.name} 
                    onChange={handleChange}
                    placeholder="Store Name"
                />
            </div>

            <div className="input-row">
                <label htmlFor="uploadLogo">Upload Logo:</label>
                <input 
                    id="uploadLogo"
                    type="file" 
                    onChange={handleImageChange}
                />
            </div>
        
            <div className="input-row">
                <label htmlFor="apiKey">API Key:</label>
                <input 
                    id="apiKey"
                    type="text" 
                    name="dutchie_api_key" 
                    value={editedStore.dutchie_api_key} 
                    onChange={handleChange}
                    placeholder="API Key"
                />
                
            </div>

            <button onClick={saveChanges}>Save</button>
            <button className='btn-close' onClick={onClose}>Close</button>
        </div>
    );
}

export default EditStoreModal;
