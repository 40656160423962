import React from 'react';
import './NameYourAssetStep.css'; // Ensure you create and style this CSS file

const NameYourAssetStep = ({ asset, setAsset }) => {
  const handleFontChange = (e) => {
    setAsset({ ...asset, font: e.target.value });
  };

  const fonts = [
    'Arial', 'Helvetica', 'Times New Roman', 'Courier New', 'Verdana',
    'Georgia', 'Palatino', 'Garamond', 'Bookman', 'Comic Sans MS',
    'Trebuchet MS', 'Arial Black', 'Impact', 'Brush Script MT', 'Lobster',
    'Montserrat', 'Open Sans', 'Roboto', 'Lato', 'Oswald',
    'Raleway', 'Slabo 27px', 'PT Sans', 'Merriweather', 'Noto Sans',
    'Ubuntu', 'Playfair Display', 'Fira Sans', 'Arvo', 'Dosis',
    'Crimson Text', 'Dancing Script', 'Rubik', 'Bitter', 'Inconsolata',
    'Muli', 'Titillium Web', 'Zilla Slab', 'Cabin', 'Work Sans',
    'Quicksand', 'Josefin Sans', 'Anton', 'Abril Fatface', 'Varela Round',
    'Exo 2', 'Nanum Gothic', 'Bebas Neue', 'Caveat', 'Pacifico'
    // Add more fonts as needed
  ];

  return (
    <div>
      <h2>Name Your Asset</h2>
      <input
        type="text"
        placeholder="Enter asset name"
        value={asset.name}
        onChange={e => setAsset({ ...asset, name: e.target.value })}
      />
      <div className="font-selector-container">
        <label htmlFor="font-selector">Choose Your Font:</label>
        <select id="font-selector" value={asset.font} onChange={handleFontChange}>
          {fonts.map(font => (
            <option key={font} value={font} className={font.replace(/ /g, '-')}>
              {font}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default NameYourAssetStep;
