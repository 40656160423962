import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './HealthReport.css';

function HealthReport() {
    const [reportData, setReportData] = useState(null);
    const [storeName, setStoreName] = useState(null);

    const customDisplayNames = {
        'newCustomerCount': 'New Customers',
        'customerCount' : 'Total Customers',
        'cost' : 'Cost (COGS)',
        'grossSales': 'Gross Sales',
        'netSales': 'Net Sales',
        'transactionCount' : 'Transactions',
        'itemCount' : 'Items Sold',
        'averageCartNetSales' : 'Average Cart',
        // ... Add other custom names as needed ...
    };

    const dollarMetrics = ['grossSales', 'netSales', 'cost', 'averageCartNetSales'];

    useEffect(() => {
        const fetchData = async () => {
            const rawStore = localStorage.getItem('selectedStore');
            const store = rawStore ? JSON.parse(rawStore) : null;
            setStoreName(store.name)
            const selectedStoreId = store ? store.id : null;
            try {
                const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/get-comparison-report/`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'Content-Type': 'application/json',
                        'Selected-Store-Id': selectedStoreId,
                    }
                });
                if (response.ok) {
                    const data = await response.json();
                    setReportData(data);
                } else {
                    console.error('Error fetching data:', response.statusText);
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };

        fetchData();
    }, []);

    const formatNumber = (value, metric) => {
        // Adding commas for readability for all metrics
        let formattedValue = formatNumberWithCommas(Math.round(value));
    
        // For dollar metrics, format as currency
        if (dollarMetrics.includes(metric)) {
            formattedValue = `$${formattedValue}`;
        }
    
        return formattedValue;
    };
    
    // Helper function to add commas
    const formatNumberWithCommas = (value) => {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    const formatPercentageChange = (value) => {
        return value > 0 ? `+${value.toFixed(2)}%` : `${value.toFixed(2)}%`;
    };

    const calculateGrossProfit = (netSales, cost) => {
        return netSales - cost;
    };

    const calculateGrossProfitMargin = (grossProfit, netSales) => {
        return netSales > 0 ? (grossProfit / netSales) * 100 : 0;
    };

    const calculateMarginChangeValue = () => {
        const grossProfit1 = calculateGrossProfit(reportData.netSales.period_1, reportData.cost.period_1);
        const margin1 = calculateGrossProfitMargin(grossProfit1, reportData.netSales.period_1);
    
        const grossProfit2 = calculateGrossProfit(reportData.netSales.period_2, reportData.cost.period_2);
        const margin2 = calculateGrossProfitMargin(grossProfit2, reportData.netSales.period_2);
    
        return margin2 - margin1; // Return the raw numerical value
    };
    
    const calculateMarginChange = () => {
        // Use the raw numerical value for formatting
        return formatPercentageChange(calculateMarginChangeValue());
    };

    const formatAsPercentage = (value) => {
        return `${(value).toFixed(2)}%`;
    };

    const getDisplayName = (metric) => {
        return customDisplayNames[metric] || metric;
    };

    const renderMetric = (metricKey) => {
        if (!reportData || !reportData[metricKey]) return null; // Safety check

        const data = reportData[metricKey];
        return (
            <div key={metricKey} className="metric">
                <h2 className='cardTitle'>{getDisplayName(metricKey)}</h2>
                <div className="data">
                    <div className="last-week">
                        <p>Last Week</p>
                        <p className="number"><strong>{formatNumber(data.period_1, metricKey)}</strong></p>
                    </div>
                    <div className="this-week">
                        <p>This Week</p>
                        <p className="number"><strong>{formatNumber(data.period_2, metricKey)}</strong></p>
                    </div>
                </div>
                <p className="change" style={{ color: metricKey === 'cost' ? 'white' : (data.percentage_change > 0 ? 'green' : 'red') }}>
                    Change: {formatPercentageChange(data.percentage_change)}
                </p>
            </div>
        );
    };

    const renderGrossProfit = () => {
        if (!reportData) return null;

        return (
            <div className="metric">
                <h2 className='cardTitle'>Gross Margin</h2>
                <div className="data">
                    <div className="last-week">
                        <p>Last Week</p>
                        <p className="number"><strong>{formatAsPercentage(calculateGrossProfitMargin(
                            calculateGrossProfit(reportData.netSales.period_1, reportData.cost.period_1),
                            reportData.netSales.period_1
                        ))}</strong></p>
                    </div>
                    <div className="this-week">
                        <p>This Week</p>
                        <p className="number"><strong>{formatAsPercentage(calculateGrossProfitMargin(
                            calculateGrossProfit(reportData.netSales.period_2, reportData.cost.period_2),
                            reportData.netSales.period_2
                        ))}</strong></p>
                    </div>
                </div>
                <p className="change" style={{ color: calculateMarginChangeValue() > 0 ? 'green' : 'red' }}>
                    Margin Change: {calculateMarginChange()}
                </p>
            </div>
        );
    };

    // Updated rendering logic
    const renderMetricsRow = (metrics) => {
        return (
            <div className="row">
                {metrics.map(metricKey => {
                    if (metricKey === 'grossProfit') {
                        return renderGrossProfit();
                    } else {
                        return renderMetric(metricKey);
                    }
                })}
            </div>
        );
    };

    const navigate = useNavigate();

    const handleExit = () => {
        navigate('/dashboard');
      };

    return (
        <div className='health-report-background'>
            <button className="btn-exit" onClick={handleExit}>
                <i class="fa fa-arrow-left" aria-hidden="true"></i>
            </button>
            <h1 className='reportHeader white'>❤️Health Check - {storeName}</h1>
            <div className="metrics-container">
                {reportData ? (
                    <>
                        {renderMetricsRow(['newCustomerCount', 'customerCount', 'grossSales', 'netSales', 'transactionCount', 'itemCount'])}
                        {renderMetricsRow(['cost', 'grossProfit'])}
                        {reportData.averageCartNetSales && renderMetricsRow(['averageCartNetSales'])}
                    </>
                ) : (
                    <p className='loading-data'>Loading data...</p>
                )}
            </div>
        </div>
    );
}

export default HealthReport;