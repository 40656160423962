import React, { useState } from 'react';
import './AssetCreationWizard.css';

const BackgroundModal = ({ onClose, dealSheetId, addBackgroundImage }) => {
  const [file, setFile] = useState(null);
  const [primaryColor, setPrimaryColor] = useState('#ffffff');
  const [secondaryColor, setSecondaryColor] = useState('#000000');
  const [isUploading, setIsUploading] = useState(false);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  console.log('backgroundmodal dealSheetId:', dealSheetId);

  const handleUpload = async () => {
    if (!file) return;

    setIsUploading(true);
    const formData = new FormData();
    formData.append('file', file);
    formData.append('primary_color', primaryColor); // Include primary color
    formData.append('secondary_color', secondaryColor); // Include secondary color


    try {
      const uploadResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/deal-sheets/${dealSheetId}/add-background/`, {
        method: 'POST',
        body: formData,
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (uploadResponse.ok) {
        const responseData = await uploadResponse.json();
        const newImage = {
            url: responseData.fileURL, // Assume response contains the URL
            primary_color: primaryColor,
            secondary_color: secondaryColor,
          };
        addBackgroundImage(newImage);
        console.log('Upload Successful:', responseData);
        alert('Background image successfully uploaded!');
        onClose();
      } else {
        console.error('Upload failed:', await uploadResponse.text());
        alert('Failed to upload image. Please try again.');
      }
    } catch (error) {
      console.error('Error uploading image:', error);
      alert('Error uploading image. Please check your connection and try again.');
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <div className="wizard-modal-background">
      <div className="wizard-modal-content">
        <h2>Upload Background Image</h2>
        <input type="file" onChange={handleFileChange} accept="image/*" />
        <div>
          <label>Category Text Color:</label>
          <input type="color" value={primaryColor} onChange={(e) => setPrimaryColor(e.target.value)} />
        </div>
        <div>
          <label>Deal Text Color:</label>
          <input type="color" value={secondaryColor} onChange={(e) => setSecondaryColor(e.target.value)} />
        </div>
        <div className="wizard-controls">
          <button onClick={handleUpload} disabled={isUploading}>{isUploading ? 'Uploading...' : 'Upload'}</button>
          <button onClick={onClose}>Close</button>
        </div>
      </div>
    </div>
  );
};

export default BackgroundModal;
