import React, { useState, useMemo, useEffect, useRef } from 'react';
import './VendorList.css';
import ExportModal from './ExportModal';

import AIInsightsModal from './AIInsightsModal';

import ActualMarginModal from './ActualMarginModal'; // Import the new modal component

import MinMaxPriceModal from './MinMaxPriceModal';


let lastColorIsBlack = true;

const VendorList = ({ inventoryData, onTotalsUpdate }) => {
  const [selectedVendorId, setSelectedVendorId] = useState(null);
  const [sortMethod, setSortMethod] = useState({ field: 'date', direction: 'asc' }); // Inverted to 'asc'

  const [filterVendor, setFilterVendor] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [productSearchTerm, setProductSearchTerm] = useState('');

  const [favoriteVendors, setFavoriteVendors] = useState(new Set());
  const [showFavorites, setShowFavorites] = useState(false);

  const [selectedItems, setSelectedItems] = useState(new Set());
  const selectedVendorRef = useRef(null);

  const selectedStore = JSON.parse(localStorage.getItem('selectedStore'));
  const selectedStoreId = selectedStore ? selectedStore.id : null;

  const [hiddenOrders, setHiddenOrders] = useState([]);
  const [showHiddenOrders, setShowHiddenOrders] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('');

  const [isActualMarginModalOpen, setIsActualMarginModalOpen] = useState(false); // State for actual margin modal
  const [selectedProductForMargin, setSelectedProductForMargin] = useState(null); // State for selected product

  const [isMinMaxPriceModalOpen, setIsMinMaxPriceModalOpen] = useState(false); // State for the min/max price modal
  const [priceRange, setPriceRange] = useState({ min: 0, max: Infinity }); // State for the price range

  const handleReceivedMarginClick = (product, receivedDate) => {
    setSelectedProductForMargin({ 
      ...product, 
      receivedDate, 
      productId: product.productId, 
      packageId: product.packageId, 
      sku: product.sku 
    });
    setIsActualMarginModalOpen(true);
  };
  
  

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };
  

  const toggleItemSelection = (itemId) => {
    setSelectedItems((prevSelectedItems) => {
      const newSelectedItems = new Set(prevSelectedItems);
      if (newSelectedItems.has(itemId)) {
        newSelectedItems.delete(itemId);
      } else {
        newSelectedItems.add(itemId);
      }
      return newSelectedItems;
    });
  };


  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  // Helper function to calculate order percentage
  const calculateOrderPercentage = (items) => {
    const total = items.reduce((acc, item) => acc + item.quantity, 0);
    const weightedSum = items.reduce((acc, item) => acc + item.quantity * (item.percentageRemaining / 100), 0);
    return (weightedSum / total) * 100;
  };

  // Function to handle search term change
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // Function to handle product search term change
  const handleProductSearchChange = (event) => {
    setProductSearchTerm(event.target.value);
  };

  const toggleSortDirection = () => {
    setSortMethod((currentSortMethod) => ({
      ...currentSortMethod,
      direction: currentSortMethod.direction === 'asc' ? 'desc' : 'asc',
    }));
  };

  const handleSortChange = (selectedField) => {
    if (selectedField === 'unitCost') {
      setSortMethod((currentSortMethod) => ({
        ...currentSortMethod,
        field: selectedField,
        direction: 'asc', // or 'desc', depending on your default
      }));
      setIsMinMaxPriceModalOpen(true); // Open the MinMaxPriceModal when "Sort by Unit Cost" is selected
    } else {
      setSortMethod((currentSortMethod) => {
        let newDirection = 'desc';
        if (selectedField === 'date') {
          newDirection = 'asc';
        }
        return {
          field: selectedField,
          direction: newDirection,
        };
      });
    }
  };

  const handleMinMaxPriceApply = (minPrice, maxPrice) => {
    setPriceRange({ min: parseFloat(minPrice) || 0, max: parseFloat(maxPrice) || Infinity });
    setIsMinMaxPriceModalOpen(false); // Close the modal after applying the price range
  };
  

  // Sort function
  const sortInventoryData = (data, { field, direction }) => {
    const multiplier = direction === 'asc' ? 1 : -1;
    return [...data].sort((a, b) => {
      switch (field) {
        case 'date':
          return multiplier * (new Date(b.deliveredOn) - new Date(a.deliveredOn));
        case 'percentage':
          return multiplier * (a.totalPercentageRemaining - b.totalPercentageRemaining);
        case 'remainingCost':
          return multiplier * (a.totalRemainingCost - b.totalRemainingCost);
        default:
          return 0;
      }
    });
  };

  const handleVendorClick = (vendorId) => {
    if (selectedVendorId === vendorId) {
      setSelectedVendorId(null);
    } else {
      setSelectedVendorId(vendorId);
      // Wait for the next render to scroll into view
      setTimeout(() => {
        selectedVendorRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
      }, 0);
    }
  };
  


  // Function to determine product background color based on remaining percentage
  const getProductBackgroundColor = (percentageRemaining) => {
    if (percentageRemaining >= 90) {
      return 'green'; // Green for 90% or more remaining
    } else {
      // Calculate the color in the gradient from green to red
      const greenToRedGradient = 255 * (1 - (percentageRemaining / 90));
      return `rgb(${greenToRedGradient}, ${255 - greenToRedGradient}, 0)`;
    }
  };

  const filteredAndSortedInventoryData = useMemo(() => {
    let data = inventoryData.map(order => {
      const filteredItems = order.items.filter(item => 
        (!productSearchTerm || item.product.toLowerCase().includes(productSearchTerm.toLowerCase())) &&
        (sortMethod.field !== 'unitCost' || (item.unitCost >= priceRange.min && item.unitCost <= priceRange.max)) // Only filter by price range if sorting by unit cost
      );
  
      const totalCost = filteredItems.reduce((acc, item) => acc + item.unitCost * item.quantity, 0);
      const totalRemainingCost = filteredItems.reduce((acc, item) => acc + item.unitCost * (item.percentageRemaining / 100) * item.quantity, 0);
  
      return {
        ...order,
        items: filteredItems,
        totalCost,
        totalRemainingCost
      };
    });
    
    if (filterVendor) {
      data = data.filter(order => order.vendor === filterVendor);
    }
  
    if (searchTerm) {
      data = data.filter(order => order.vendor.toLowerCase().includes(searchTerm.toLowerCase()));
    }
  
    if (showFavorites) {
      data = data.filter(order => favoriteVendors.has(order.vendor));
    }
  
    if (showHiddenOrders) {
      data = data.filter(order => hiddenOrders.includes(order.receiveInventoryHistoryId));
    } else {
      data = data.filter(order => !hiddenOrders.includes(order.receiveInventoryHistoryId));
    }
  
    if (selectedCategory) {
      data = data.map(order => ({
        ...order,
        items: order.items.filter(item => item.category === selectedCategory)
      }));
    }
  
    data = data.filter(order => order.items.length > 0);
  
    const dataWithPercentages = data.map(order => ({
      ...order,
      totalPercentageRemaining: calculateOrderPercentage(order.items),
    }));
  
    return sortInventoryData(dataWithPercentages, sortMethod);
  }, [inventoryData, sortMethod, filterVendor, searchTerm, productSearchTerm, showFavorites, favoriteVendors, showHiddenOrders, hiddenOrders, selectedCategory, priceRange]);
  
  
  useEffect(() => {
    console.log("Toggled showHiddenOrders state:", showHiddenOrders);
  }, [showHiddenOrders]);

  const ItemStatusBar = ({ percentage }) => {
    return (
      <div className="item-status-bar">
        <div className="item-status-bar-fill" style={{ width: `${percentage}%` }}></div>
      </div>
    );
  };

  const UnitStatusBar = ({ unitsRemaining, quantity }) => {
    const percentageRemaining = (unitsRemaining / quantity) * 100;
  
    return (
      <div className="unit-status-bar">
        <div className="unit-status-bar-fill" style={{ width: `${percentageRemaining}%` }}></div>
        <div className="unit-status-bar-depleted" style={{ width: `${100 - percentageRemaining}%` }}></div>
        <div className="unit-status-bar-label">
          {unitsRemaining}/{quantity} Units
        </div>
      </div>
    );
  };
  
  

  const daysSince = (dateString) => {
    const deliveredDate = new Date(dateString);
    const currentDate = new Date();
    const differenceInTime = currentDate.getTime() - deliveredDate.getTime();
    return differenceInTime / (1000 * 3600 * 24); // Convert time difference from milliseconds to days
  };

  const StatusBar = ({ percentage }) => {
    const fillColor = percentage > 50 ? 'green' : 'red';
  
    return (
      <div className="status-bar">
        <div className="status-bar-fill" style={{ width: `${Math.max(0, Math.min(100, percentage))}%`, backgroundColor: fillColor }}>
          {percentage.toFixed(2)}%
        </div>
      </div>
    );
  };









  const hashCode = (str) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
  };
  
  const intToRGB = (i) => {
    const c = (i & 0x00FFFFFF).toString(16).toUpperCase();
    return "00000".substring(0, 6 - c.length) + c;
  };
  
  const invertColor = (hex) => {
    if (hex.indexOf('#') === 0) {
        hex = hex.slice(1);
    }
    // Convert hex to rgb
    let r = parseInt(hex.slice(0, 2), 16),
        g = parseInt(hex.slice(2, 4), 16),
        b = parseInt(hex.slice(4, 6), 16);
    // Invert color components
    r = (255 - r).toString(16);
    g = (255 - g).toString(16);
    b = (255 - b).toString(16);
    // Pad each with zeros and return
    return "#" + padZero(r) + padZero(g) + padZero(b);
  };
  
  const padZero = (str, len = 2) => {
    const zeros = new Array(len).join('0');
    return (zeros + str).slice(-len);
  };

  const getBrightness = (hex) => {
    const rgb = parseInt(hex, 16); // convert hex to a number
    const r = (rgb >> 16) & 0xff;
    const g = (rgb >> 8) & 0xff;
    const b = (rgb >> 0) & 0xff;
  
    // Using the HSP (Highly Sensitive Poo) formula to determine the brightness of the color
    return Math.sqrt(
      0.299 * (r * r) +
      0.587 * (g * g) +
      0.114 * (b * b)
    );
  }

  // Add function to sort items by SKU
  const sortItemsBySKU = (items) => {
    return items.sort((a, b) => a.sku.localeCompare(b.sku));
  };

  const getItemStyle = (currentSKU, previousSKU) => {
    let shouldAlternate = currentSKU !== previousSKU;
    const isBlack = shouldAlternate ? !lastColorIsBlack : lastColorIsBlack;
    lastColorIsBlack = isBlack; // Update the global variable to remember the last color used
  
    const backgroundColor = isBlack ? '#55555555' : 'white';
    const textColor = isBlack ? 'black' : 'black';
    return { backgroundColor, color: textColor };
  };
  
  
  const chooseTextColor = (backgroundColorHex) => {
    const brightness = getBrightness(backgroundColorHex);
    return brightness > 127.5 ? 'black' : 'white';
  }
  



  const getMetrcBackgroundColor = (percentageRemaining) => {
    return percentageRemaining > 50 ? 'green' : 'red';
  };
  const getGpBackgroundColor = (gp) => {
    return gp > 40 ? 'green' : 'red';
  };

  const getTotalUnitsBackgroundColor = (unitsRemaining, totalUnitsInStock, quantity) => {
    if (totalUnitsInStock >= 2 * quantity) {
      return { backgroundColor: 'green', color: 'white' }; // Green background, white text
    } else if (totalUnitsInStock >= quantity) {
      return { backgroundColor: 'yellow', color: 'black' }; // Yellow background, black text
    } else {
      return { backgroundColor: 'red', color: 'white' }; // Red background, white text
    }
  };
  
  


  // Add a new state to store sorting information for each order
  const [orderSortStates, setOrderSortStates] = useState({});

  // Function to sort items within an order
  const sortItems = (items, field, order) => {
    return items.sort((a, b) => {
      if (a[field] < b[field]) {
        return order === 'asc' ? -1 : 1;
      }
      if (a[field] > b[field]) {
        return order === 'asc' ? 1 : -1;
      }
      return 0;
    });
  };

  // Function to handle sorting for a specific order
  const handleSort = (orderId, sortField) => {
    const currentSortState = orderSortStates[orderId] || { field: null, order: 'asc' };
    const newOrder = currentSortState.field === sortField && currentSortState.order === 'asc' ? 'desc' : 'asc';
    
    setOrderSortStates({
      ...orderSortStates,
      [orderId]: { field: sortField, order: newOrder }
    });
  };


  const toggleFavorite = async (e, vendor) => {
    e.stopPropagation(); // Stop event bubbling
    
    setFavoriteVendors((prevFavorites) => {
      const newFavorites = new Set(prevFavorites);
      if (newFavorites.has(vendor)) {
        newFavorites.delete(vendor);
      } else {
        newFavorites.add(vendor);
      }
      // Update backend/local storage after state change
      updateFavoritesOnBackend(Array.from(newFavorites)); // Convert Set to Array for API call or storage
      return newFavorites;
    });
  };
  
  // Function to update favorites on the backend or local storage
  const updateFavoritesOnBackend = async (favorites) => {
    const jwt = localStorage.getItem('token'); // Assuming JWT token is stored in local storage
    try {
      await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/save-favorite-vendors/`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${jwt}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ favorites })
      });
      // Handle successful update
    } catch (error) {
      console.error('Error updating favorited vendors:', error);
      // Handle errors appropriately
    }
  };

  useEffect(() => {
    const fetchFavoritedVendors = async () => {
      const jwt = localStorage.getItem('token'); // Get JWT token from local storage
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/load-favorite-vendors/`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${jwt}`
          }
        });
        if (!response.ok) {
          throw new Error('Failed to fetch favorited vendors');
        }
        const data = await response.json();
        setFavoriteVendors(new Set(data.favorites));
      } catch (error) {
        console.error('Error fetching favorited vendors:', error);
        // Handle errors appropriately
      }
    };

    fetchFavoritedVendors();
  }, []);

  // Calculate and pass total and remaining costs
  useEffect(() => {
    const totalCost = filteredAndSortedInventoryData.reduce((acc, order) => acc + order.totalCost, 0);
    const remainingCost = filteredAndSortedInventoryData.reduce((acc, order) => acc + order.totalRemainingCost, 0);
    
    onTotalsUpdate(totalCost, remainingCost);
  }, [filteredAndSortedInventoryData, onTotalsUpdate]);

  const exportSelectedItems = () => {
    console.log("Export Selected clicked"); // Confirm this is being called
    setIsModalOpen(true);
  };
  
  const handleExport = (exportType) => {
    console.log("Export function called with type:", exportType);
  
    const dataToExport = filteredAndSortedInventoryData
    .flatMap(order => 
      order.items
        .filter(item => selectedItems.has(item.packageId)) // Filter only selected items
        .map(item => {
          const filteredData = {};
          Object.keys(exportFields).forEach(field => {
            if (exportFields[field]) { // Check if the field is set to true
              if (field === 'vendor') {
                filteredData[field] = order.vendor;
              } else {
                filteredData[field] = item[field] || 'N/A';
              }
            }
          });
          return filteredData;
        })
    );
  
    // Convert data to a CSV string
    const csvData = convertDataToCSV(dataToExport);
  
    if (exportType === 'csv') {
      // Logic to save data as a CSV file
      const csvData = convertDataToCSV(dataToExport);
      saveCSV(csvData);
    } else if (exportType === 'clipboard') {
      // Logic to copy formatted data to clipboard
      const clipboardData = convertDataToFormattedText(dataToExport);
      navigator.clipboard.writeText(clipboardData).then(() => {
        console.log('Data copied to clipboard');
      }).catch(err => {
        console.error('Failed to copy data to clipboard:', err);
      });
    }
  
    setIsModalOpen(false);
  };

  function flattenDataForCSV(inventoryData) {
    let csvRows = [];
  
    // Define the headers
    const headers = [
      'OrderId', 'DeliveryDate', 'VendorName',
      'PackageId', 'ProductName', 'SKU', 'Quantity', 'UnitPrice', 'UnitCost', 
      'PercentageRemaining', 'DaysOfSupplyRemaining', 'TotalQuantityPerSKU', 'GrossProfit'
    ];
  
    // Add the header row
    csvRows.push(headers.join(','));
  
    inventoryData.forEach(order => {
      const orderInfo = {
        OrderId: order.receiveInventoryHistoryId,
        DeliveryDate: formatDate(order.deliveredOn),
        VendorName: escapeCSV(order.vendor),
        // ...other order-level fields...
      };
  
      order.items.forEach(item => {
        const productInfo = {
          PackageId: item.packageId,
          ProductName: escapeCSV(item.product),
          SKU: item.sku,
          Quantity: item.quantity,
          UnitPrice: item.unitPrice,
          UnitCost: item.unitCost,
          PercentageRemaining: item.percentageRemaining,
          DaysOfSupplyRemaining: item.daysOfSupplyRemaining,
          TotalQuantityPerSKU: item.totalQuantityPerSKU,
          GrossProfit: item.gp,
          imageUrl: item.imageUrl,
          // ...other product-level fields...
        };
  
        // Combine order info and product info
        const combinedInfo = {...orderInfo, ...productInfo};
        const row = Object.values(combinedInfo).map(value => escapeCSV(checkValue(value))).join(',');
        csvRows.push(row);
      });
    });
  
    return csvRows.join('\n');
  }
  
  function escapeCSV(field) {
    if (field == null || typeof field === 'function') return ''; 
    const stringField = field.toString();
    if (stringField.includes(',') || stringField.includes('"') || stringField.includes('\n')) {
      return `"${stringField.replace(/"/g, '""')}"`;
    }
    return stringField;
  }
  
  function checkValue(value) {
    if (typeof value === 'function' || value === undefined) {
      return '';
    }
    return value;
  }
  
  
  
  const convertDataToCSV = (data) => {
    if (data.length === 0) return '';
  
    // Extracting headers (column names)
    const headers = Object.keys(data[0]);
    const csvRows = [];
  
    // Adding headers row
    csvRows.push(headers.join(','));
  
    // Adding data rows
    for (const row of data) {
      const values = headers.map(header => {
        const escaped = ('' + row[header]).replace(/"/g, '\\"'); // Escape any quotes in data
        return `"${escaped}"`; // Wrap each value in quotes
      });
      csvRows.push(values.join(','));
    }
  
    return csvRows.join('\n');
  };
  
  
  const saveCSV = (csvData) => {
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
  
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', 'export.csv');
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  const convertDataToFormattedText = (data) => {
    if (data.length === 0) return '';
  
    const headers = Object.keys(data[0]);
    const columnWidths = headers.map(header => 
      Math.max(...data.map(row => ('' + row[header]).length), header.length)
    );
  
    const formatRow = (row, isHeader = false) => 
      headers.map((header, index) => {
        const cellContent = isHeader ? header : ('' + row[header]);
        return cellContent.padEnd(columnWidths[index], ' ');
      }).join(' | ');
  
    const formattedRows = [
      formatRow(headers, true), // Pass true to indicate this is a header row
      ...data.map(row => formatRow(row))
    ];
  
    return formattedRows.join('\n');
  };


  const toggleHiddenOrder = async (e, orderId, hideOrder) => {
    e.stopPropagation();
  
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/store/${selectedStoreId}/toggle-hidden-order/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({ order_id: orderId, hide_order: hideOrder }),
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const data = await response.json();
      // Assuming the response contains the updated list of hidden order IDs
      setHiddenOrders(data.hidden_orders);
  
      console.log('Hidden orders updated:', data.hidden_orders);
    } catch (error) {
      console.error('Error toggling hidden order:', error);
    }
  };
  

  
  
  useEffect(() => {
    // Simulate fetching hidden orders from the server
    const fetchHiddenOrders = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/store/${selectedStoreId}/toggle-hidden-order/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
          body: JSON.stringify({ order_id: "999999999" }), // Using "xxxx" to get hidden orders
        });
        if (!response.ok) {
          throw new Error('Failed to fetch hidden orders');
        }
        const data = await response.json();
        setHiddenOrders(data.hidden_orders); // Assuming the response contains an array of hidden order IDs
        console.log("Fetched and set hiddenOrders state:", data.hidden_orders);
      } catch (error) {
        console.error('Error fetching hidden orders:', error);
      }
    };

    fetchHiddenOrders();
  }, [selectedStoreId]); // Rerun this effect if selectedStoreId changes

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [exportFields, setExportFields] = useState({
    vendor: false,
    product: false,
    sku: false,
    packageId: false,
    totalQuantityPerSKU: false,
    gp: false,
    percentageRemaining: false,
    daysOfSupplyRemaining: false,
    // ... other fields
  });


  const exportFieldLabels = {
    vendor: 'Vendor',
    product: 'Product',
    sku: 'SKU #',
    packageId: 'METRC #',
    totalQuantityPerSKU: 'SKU Quantity',
    gp: 'Gross Margin %',
    percentageRemaining: 'Remaining %',
    daysOfSupplyRemaining: 'Days Supply',
    // Add other fields as needed
  };


  // Convert inventory data to CSV
  const csvInventoryData = flattenDataForCSV(inventoryData);
  const [isAIInsightsModalOpen, setIsAIInsightsModalOpen] = useState(false);

  // Function to open AI Insights modal
  const openAIInsightsModal = () => {
    setIsAIInsightsModalOpen(true);
  };

  const hiddenOrderStyle = { backgroundColor: 'rgb(200, 200, 200)' };


  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const extractedCategories = new Set();
    inventoryData.forEach(order => {
      order.items.forEach(item => {
        if (item.category) {
          extractedCategories.add(item.category);
        }
      });
    });
    // Convert to array and sort alphabetically
    const sortedCategories = [...extractedCategories].sort((a, b) => a.localeCompare(b));
    setCategories(sortedCategories);
  }, [inventoryData]);

  





  return (
    <div className="vendor-list">
      <div className="sorting-buttons">
        <div className="group">
        <button onClick={() => toggleSortDirection()}>
          {sortMethod.direction === 'asc' ? '⬇️' : '⬆️'}
        </button>
        <select value={sortMethod.field} onChange={(e) => handleSortChange(e.target.value)}>
          <option value="date">📆 Sort by Date</option>
          <option value="percentage">📈 Sort by Percentage</option>
          <option value="remainingCost">💵 Sort by Remaining Cost</option>
          <option value="unitCost">💲 Sort by Unit Cost</option>
        </select>
        <button onClick={() => setShowFavorites(!showFavorites)}>
          {showFavorites ? '☆ Show All Vendors' : '⭐️ Show Favorited Vendors'}
        </button>
        {filterVendor && (
          <button onClick={() => setFilterVendor(null)}>Show All Vendors</button>
        )}
        <button onClick={() => setShowHiddenOrders(prev => !prev)}>
          {showHiddenOrders ? "👁️ Hide Hidden Orders" : "👁️‍🗨️ Show Hidden Orders"}
        </button>
        </div>
        <div className="group">
          <input type="text" placeholder="Search Vendor" value={searchTerm} onChange={handleSearchChange} />
          <input type="text" placeholder="Search Product" value={productSearchTerm} onChange={handleProductSearchChange} />
          <select value={selectedCategory} onChange={handleCategoryChange}>
            <option value="">Select Category</option>
            {categories.map((category, index) => (
              <option key={index} value={category}>
                {category}
              </option>
            ))}
          </select>
          {/*<button onClick={openAIInsightsModal}>AI Insights</button>*/}
          <AIInsightsModal
            isOpen={isAIInsightsModalOpen}
            onClose={() => setIsAIInsightsModalOpen(false)}
            inventoryData={csvInventoryData} // Pass inventory data as a prop
          />
          <button onClick={exportSelectedItems}>Export Selected</button>

        </div>
      </div>
      {filteredAndSortedInventoryData.map((order) => (
        <div key={order.receiveInventoryHistoryId} className="vendor-entry">
          <div
            className="vendor-summary"
            style={showHiddenOrders && hiddenOrders.includes(order.receiveInventoryHistoryId) ? hiddenOrderStyle : {}}
            onClick={() => handleVendorClick(order.receiveInventoryHistoryId)}
          >
            <span className="favorite-icon" onClick={(e) => toggleFavorite(e, order.vendor)}>
              {favoriteVendors.has(order.vendor) ? '⭐️' : '☆'}
            </span>
            <span className="favorite-icon" onClick={(e) => toggleHiddenOrder(e, order.receiveInventoryHistoryId)}>
                {hiddenOrders.includes(order.receiveInventoryHistoryId) ? '👁️‍🗨️' : '👁️'}
            </span>

            <span className="vendor-name">
              
              {daysSince(order.deliveredOn) > 60 && ' 💀'}
              <button className='vendor-button' onClick={(event) => {
                  event.stopPropagation();
                  setFilterVendor(order.vendor);
              }}>
                  {order.vendor}
              </button>
              
            </span>
            
            <div className="status-bar-container">
              <StatusBar percentage={order.totalPercentageRemaining} />
            </div>
            <span className="left-align order-total-cost">Total: ${order.totalCost.toFixed(0)}</span>
            <span className="left-align order-remaining-cost">Remaining: ${order.totalRemainingCost.toFixed(0)}</span>
            <span className="left-align received-date">Date: {formatDate(order.deliveredOn)}</span>
          </div>
          {selectedVendorId === order.receiveInventoryHistoryId && (
            <div ref={selectedVendorRef} className="vendor-details">
              <div className='sorting-buttons'>
                
                <button onClick={() => handleSort(order.receiveInventoryHistoryId, 'product')}>Sort by Name</button>
                <button onClick={() => handleSort(order.receiveInventoryHistoryId, 'unitCost')}>Sort by Cost</button>
                <button onClick={() => handleSort(order.receiveInventoryHistoryId, 'packageId')}>Sort by METRC</button>
                <button onClick={() => handleSort(order.receiveInventoryHistoryId, 'unitsRemaining')}>Sort by Units Remaining</button>
                <button onClick={() => handleSort(order.receiveInventoryHistoryId, 'sku')}>Sort by SKU</button>
                <button onClick={() => handleSort(order.receiveInventoryHistoryId, 'totalQuantityPerSKU')}>Sort by Total Units</button>
                <button onClick={() => handleSort(order.receiveInventoryHistoryId, 'gp')}>Sort by Received Margin</button>
                
                
                <div className="group">
                </div>
              </div>

              {sortItems(
                order.items,
                orderSortStates[order.receiveInventoryHistoryId]?.field,
                orderSortStates[order.receiveInventoryHistoryId]?.order
              ).map((item, index, array) => {
                const previousSKU = index > 0 ? array[index - 1].sku : null;
                const { backgroundColor, color: textColor } = getItemStyle(item.sku, previousSKU);
                const metrcBackgroundColor = getMetrcBackgroundColor(item.percentageRemaining);
                const gpBackgroundColor = getGpBackgroundColor(item.gp);
                const totalUnitsBackgroundColor = getTotalUnitsBackgroundColor(item.unitsRemaining, item.totalQuantityPerSKU, item.quantity);
                const totalUnitsBackgroundStyle = getTotalUnitsBackgroundColor(item.unitsRemaining, item.totalQuantityPerSKU, item.quantity);



                return (
                  <div key={item.productId} className="item-detail">
                    <input
                      type="checkbox"
                      checked={selectedItems.has(item.packageId)}
                      onChange={() => toggleItemSelection(item.packageId)}
                    />
                    <span className='reorder-item-name'>{item.product}</span>
                    <span className='sku-detail'>Unit Cost: ${item.unitCost.toFixed(2)}</span>

                    <span className='sku-detail'>
                    METRC: {item.packageId.slice(-6)}
                    </span>
                    
                    <span className="sku-detail" style={{ color: 'white' }}>
                    <UnitStatusBar unitsRemaining={item.unitsRemaining} quantity={item.quantity} />
                    </span>
                    
                    

                    <span className="realsku-detail" style={{ backgroundColor, color: textColor }}>
                      SKU: {item.sku}
                    </span>


                    <span className='sku-detail' style={{ backgroundColor: totalUnitsBackgroundStyle.backgroundColor, color: totalUnitsBackgroundStyle.color }}>
                      {item.totalQuantityPerSKU.toFixed(1)} Ct Total
                      {item.daysOfSupplyRemaining != null && ` - ${item.daysOfSupplyRemaining.toFixed(0)} Days`}
                    </span>

                    <span 
                      className='sku-detail more-product-info' // Updated class name
                      onClick={() => handleReceivedMarginClick(item, order.deliveredOn)}
                    >
                      View More Info
                    </span>


                  </div>
                );
              })}



            </div>
          )}
        </div>
      ))}
      <ActualMarginModal
        isOpen={isActualMarginModalOpen}
        onClose={() => setIsActualMarginModalOpen(false)}
        product={selectedProductForMargin}
        authHeader={localStorage.getItem('token')} // Pass the auth header
      />

      <MinMaxPriceModal
        isOpen={isMinMaxPriceModalOpen}
        onClose={() => setIsMinMaxPriceModalOpen(false)}
        onApply={handleMinMaxPriceApply}
      />

      <ExportModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onExport={handleExport}
        fields={exportFields}
        setFields={setExportFields}
        fieldLabels={exportFieldLabels}
      />
    </div>
  );
};

export default VendorList;
