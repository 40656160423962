import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './Signup.css';

const Signup = () => {
  const [invitationCode, setInvitationCode] = useState(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [company, setCompany] = useState('');
  const [BetaCode, setBetaCode] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [verifyPassword, setVerifyPassword] = useState('');


  useEffect(() => {
    // Extract the 'code' parameter from the URL
    const params = new URLSearchParams(location.search);
    const code = params.get('code');
    if (code) {
      setInvitationCode(code);
    }
  }, [location.search]);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if passwords match
    if (password !== verifyPassword) {
      setError('Passwords do not match.');
      return;
    }

    // Convert email to lowercase
    const emailLowercase = email.toLowerCase();

    const betacodeToSend = invitationCode ? 'seed' : BetaCode;

    fetch(`${process.env.REACT_APP_API_BASE_URL}/users/register/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: emailLowercase,
        password,
        email: emailLowercase,
        first_name: firstName,
        last_name: lastName,
        company,
        code: invitationCode,
        betacode: betacodeToSend,
      }),
    })
    .then((response) => {
      if (response.status === 201) {
        return response.json();
      } else {
        return response.json().then(data => {
          throw new Error(data.detail || 'Signup failed');
        });
      }
    })
    .then((data) => {
      navigate('/login'); // Redirect to login
    })
    .catch((error) => {
      setError(error.message);
    });
};

  return (
  <div className="div-container">
  <div className="page-container">
  <img src="https://d27oebqltxtb0s.cloudfront.net/GreenMindLogo.png" alt="Logo" className="logo" />
  <div className="signup-container">
    <h1 className="signup-title">Create Account</h1>
    <form onSubmit={handleSubmit} className="signup-form">
      <div className="name-container">
        <input
          type="text"
          placeholder="First Name"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          className="name-input forminput"
        />
        <input
          type="text"
          placeholder="Last Name"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          className="name-input forminput"
        />
        
      </div>
      <div className="email-password-container">
        <input
          className="company-input forminput"
          type="text"
          placeholder="Company"
          value={company}
          onChange={(e) => setCompany(e.target.value)}
        />
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className='forminput'
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className='forminput'

        />
        <input
          type="password"
          placeholder="Verify Password"
          value={verifyPassword}
          onChange={(e) => setVerifyPassword(e.target.value)}
          className='forminput'
        />
        {!invitationCode && (
                <input
                  type="text"
                  placeholder="Beta Code (Provided From Greenmind)"
                  value={BetaCode}
                  onChange={(e) => setBetaCode(e.target.value)}
                  className='forminput'
                />
              )}
      </div>
      <button type="submit" className="submit-button">Sign Up</button>
    </form>
    {error && <div className="error-message">{error}</div>}
      <div className="login-link-container">
        <span>By clicking 'Sign Up' you agree to our </span>
        <a href="/TNC" className='createanaccount'>Terms & Conditions</a>
      </div>
      <div className="login-link-container">
        <span>Already have an account? </span>
        <a href="/login" className='createanaccount'>Login Here</a>
      </div>
  </div>
  </div>
  </div>
);
};

export default Signup;
