// HeaderUploadModal.js
import React, { useState } from 'react';

const HeaderUploadModal = ({ onClose, dealSheetId, addHeaderImage }) => {
  const [file, setFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file) return;

    setIsUploading(true);
    const formData = new FormData();
    formData.append('file', file);

    try {
      const uploadResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/deal-sheets/${dealSheetId}/add-header/`, {
        method: 'POST',
        body: formData,
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (uploadResponse.ok) {
        const responseData = await uploadResponse.json();
        const newImage = responseData.fileURL; // Assuming response directly provides the URL of the uploaded header
        addHeaderImage(newImage);
        alert('Header image successfully uploaded!');
        onClose();
      } else {
        alert('Failed to upload header image. Please try again.');
      }
    } catch (error) {
      alert('Error uploading header image. Please check your connection and try again.');
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <div className="wizard-modal-background">
      <div className="wizard-modal-content">
        <h2>Upload Header Image</h2>
        <input type="file" onChange={handleFileChange} accept="image/*" />
        <div className="wizard-controls">
          <button onClick={handleUpload} disabled={isUploading}>{isUploading ? 'Uploading...' : 'Upload'}</button>
          <button onClick={onClose}>Close</button>
        </div>
      </div>
    </div>
  );
};

export default HeaderUploadModal;
