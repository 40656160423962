import React, { useState } from 'react';
import styles from './ImageSlider.module.css';
import backgroundImage from './DemoMonitorCustomerMenu2.png';  // Adjust the path as necessary
import foregroundImage from './DemoMonitorCustomerMenu3.png';

function ImageSlider() {
  const [sliderPos, setSliderPos] = useState(50);
  const [isDragging, setIsDragging] = useState(false);

  const handleSliderChange = (e) => {
    setSliderPos(e.target.value);
  };

  const startDrag = (e) => {
    setIsDragging(true);
  };

  const endDrag = () => {
    setIsDragging(false);
  };

  const handleDrag = (e) => {
    if (!isDragging) return;

    const rect = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - rect.left; // Mouse position relative to the container
    const newSliderPos = Math.min(100, Math.max(0, (x / rect.width) * 100));
    setSliderPos(newSliderPos);
  };

  return (
    <div
      className={styles.container}
      onMouseMove={handleDrag}
      onMouseUp={endDrag}
      onMouseLeave={endDrag}
      onTouchMove={(e) => handleDrag(e.touches[0])}
      onTouchEnd={endDrag}
    >
      <div className={styles.slideimg} style={{ backgroundImage: `url(${backgroundImage})` }}></div>
      <div className={styles.slideimg} style={{ backgroundImage: `url(${foregroundImage})`, width: `${sliderPos}%` }}></div>
      <input
        type="range"
        min="1"
        max="100"
        value={sliderPos}
        className={styles.slider}
        onChange={handleSliderChange}
      />
      <div
        className={styles.sliderButton}
        style={{ left: `calc(${sliderPos}% - 18px)` }}
        onMouseDown={startDrag}
        onTouchStart={(e) => startDrag(e.touches[0])}
      ></div>
    </div>
  );
}

export default ImageSlider;
