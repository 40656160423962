import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { FaImage } from 'react-icons/fa';
import './FileSelectionModal.css';

function FileSelectionModal({ onClose, onFileSelect }) {
    const [files, setFiles] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [loading, setLoading] = useState(false);
    const storeId = JSON.parse(localStorage.getItem('selectedStore')).id;
    const [showNoFilesMessage, setShowNoFilesMessage] = useState(false);
    const [loadingText, setLoadingText] = useState('Uploading, please wait...');

    // Update the `fetchFiles` function to sort files in reverse order
    useEffect(() => {
        const fetchFiles = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/stores/${storeId}/files/`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                });
                const data = await response.json();
                if (response.ok) {
                    // Sort the files in reverse order
                    setFiles(data.files.reverse());
                } else {
                    console.error("Error fetching files:", data);
                }
            } catch (error) {
                console.error("Error fetching files:", error);
            }
        };

        fetchFiles();
    }, [storeId]);


    const handleFileSelectToggle = (file) => {
        setSelectedFiles((prevSelected) => {
            if (prevSelected.includes(file)) {
                return prevSelected.filter((selected) => selected !== file);
            } else {
                return [...prevSelected, file];
            }
        });
    };

    const handleAddSelected = () => {
        if (selectedFiles.length === 0) {
            setShowNoFilesMessage(true);
            setTimeout(() => setShowNoFilesMessage(false), 3000); // Hide after 3 seconds
            return;
        }
    
        onFileSelect(selectedFiles.map((file) => ({ url: file.url })));
        setSelectedFiles([]);
        onClose();
    };
    

    const handleUploadNewFile = async () => {
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = '.jpeg,.jpg,.png,.mp4,.avi,.mkv,.mov';
        fileInput.multiple = true;
    
        fileInput.onchange = async (event) => {
            const selectedFiles = event.target.files;
            setLoadingText('Uploading files, please wait...');
            setLoading(true); // Show loading overlay
    
            try {
                for (let i = 0; i < selectedFiles.length; i++) {
                    let file = selectedFiles[i];
                    let safeFileName = file.name.replace(/[^\w.-]/g, '_');
                    file = new File([file], safeFileName, { type: file.type });
    
                    const allowedTypes = [
                        'image/jpeg', 'image/jpg', 'image/png', 'video/mp4',
                        'video/x-msvideo', 'video/avi', 'video/x-matroska', 'video/quicktime'
                    ];
    
                    if (allowedTypes.includes(file.type)) {
                        const formData = new FormData();
                        formData.append('file', file);
    
                        const uploadResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/upload_file/`, {
                            method: 'POST',
                            body: formData,
                            headers: {
                                'Authorization': `Bearer ${localStorage.getItem('token')}`
                            }
                        });
    
                        const jsonResponse = await uploadResponse.json();
                        if (uploadResponse.ok) {
                            const imageUrl = jsonResponse.fileURL;
                            const now = new Date();
                            const oneMonthFromNow = new Date(new Date().setMonth(now.getMonth() + 12));
                            const timezoneOffset = now.getTimezoneOffset() * 60000;
                            const localNow = new Date(now - timezoneOffset);
                            const localOneMonthFromNow = new Date(oneMonthFromNow - timezoneOffset);
                            const start_date = localNow.toISOString().slice(0, -1);
                            const end_date = localOneMonthFromNow.toISOString().slice(0, -1);
    
                            await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/stores/${storeId}/upload-slide/`, {
                                method: 'POST',
                                headers: {
                                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify({
                                    image_url: imageUrl,
                                    start_date,
                                    end_date,
                                 })
                            });
    
                            setFiles((prevFiles) => [{ url: imageUrl, id: jsonResponse.file_id, type: file.type, name: safeFileName }, ...prevFiles]);
                        } else {
                            console.error("Error uploading file:", jsonResponse);
                        }
                    } else {
                        console.warn(`File type not allowed: ${file.type}`);
                    }
                }
            } catch (error) {
                console.error("Error uploading files:", error);
            } finally {
                setLoading(false); // Hide loading overlay after completion
            }
        };
    
        fileInput.click();
    };

    const handleRemoveFile = async (fileId) => {
        setLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/stores/${storeId}/files/${fileId}/remove/`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (response.ok) {
                setFiles((prevFiles) => prevFiles.filter((file) => file.id !== fileId));
                setSelectedFiles((prevSelected) => prevSelected.filter((file) => file.id !== fileId));
            } else {
                console.error("Error removing file:", await response.json());
            }
        } catch (error) {
            console.error("Error removing file:", error);
        } finally {
            setLoading(false);
        }
    };

    const renderFilePreview = (file) => {
        // Extract file extension from the URL
        const extension = file.url.split('.').pop().toLowerCase();
        const fileName = file.url.split('/').pop(); // Extract file name from URL
    
        if (['jpeg', 'jpg', 'png'].includes(extension)) {
            return <img src={file.url} alt="File preview" className="fileselection-image" />;
        } else if (['mp4', 'avi', 'mkv', 'mov'].includes(extension)) {
            return (
                <div className="fileselection-video-container">
                    <video className="fileselection-video" controls muted>
                        <source src={file.url} type={`video/${extension}`} />
                    </video>
                    <div className="fileselection-overlay">
                        <span className="fileselection-file-name-overlay">{fileName}</span>
                    </div>
                </div>
            );
        } else {
            return <span className="fileselection-file-name">{fileName}</span>;
        }
    };
    
    

    const totalCards = 24;
    const blankCardsNeeded = totalCards - files.length;

    return ReactDOM.createPortal(
        <div className="fileselection-modal">
            <div className="fileselection-overlay" onClick={onClose}></div>
            <div className="fileselection-modal-content">
            <h2>
                Select a File
                {showNoFilesMessage && (
                    <span className="no-files-message">No files are selected</span>
                )}
            </h2>
                {loading && <p>Uploading, please wait...</p>}
                <div className="fileselection-scroll-container">
                    <div className="fileselection-file-list">
                        {files.map((file) => (
                            <div 
                                key={file.id}
                                className={`fileselection-file-card ${selectedFiles.includes(file) ? 'fileselection-selected' : ''}`} 
                                onClick={() => handleFileSelectToggle(file)}
                            >
                                <div className="fileselection-image-container">
                                    <button className="fileselection-remove-button" onClick={(e) => { e.stopPropagation(); handleRemoveFile(file.id); }}>X</button>
                                    {renderFilePreview(file)}
                                </div>
                            </div>
                        ))}
                        {Array.from({ length: blankCardsNeeded }).map((_, index) => (
                            <div className="fileselection-file-card" key={`blank-${index}`}>
                                <FaImage className="fileselection-file-card-placeholder" />
                            </div>
                        ))}
                    </div>
                </div>
                <div className="fileselection-modal-buttons">
                <button
                    className="fileselection-modal-button"
                    onClick={handleAddSelected}
                >
                    Add Selected to Slideshow
                </button>
                    <button className="fileselection-modal-button" onClick={handleUploadNewFile}>Upload New File</button>
                    <button className="fileselection-modal-button" onClick={onClose}>Close</button>
                </div>
            </div>
            {loading && (
                <div className="loading-overlay">
                    <div className="loading-spinner"></div>
                    <p className="loading-text">{loadingText}</p>
                </div>
            )}
        </div>,
        document.body
    );
}

export default FileSelectionModal;
