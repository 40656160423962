import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import './SlideshowPlayer.css';
import { w3cwebsocket as W3CWebSocket } from 'websocket';

function SlideshowPlayer() {
    const navigate = useNavigate();
    const slideshowId = localStorage.getItem('currentSlideshowId');
    const [slides, setSlides] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [fadeDuration, setFadeDuration] = useState(0);
    const videoRefs = useRef([]);
    const wsClientRef = useRef(null);

    const shouldReconnect = useRef(true);
    const lastMessageTimeRef = useRef(Date.now());

    const [loading, setLoading] = useState(true); // New state for loading
    const [allAssetsLoaded, setAllAssetsLoaded] = useState(false); // New state to track if all assets are loaded
    const loadedAssetsCount = useRef(0); // Ref to keep track of loaded assets

    const fetchAttemptInterval = useRef(null);




    // Function to fetch all devices for the current store using fetchWithRefresh
    const fetchDevicesForCurrentStore = async () => {
        const selectedStore = JSON.parse(localStorage.getItem('selectedStore'));
        if (!selectedStore) return [];

        const url = `${process.env.REACT_APP_API_BASE_URL}/users/get-devices/?store_id=${selectedStore.id}`;

        try {
            const response = await fetchWithRefresh(url, {
                method: 'GET', // Assuming it's a GET request; adjust if necessary
                headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` },
            });
            
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            
            const devicesData = await response.json();
            return devicesData;
        } catch (error) {
            console.error("Error fetching devices:", error);
            return [];
        }
    };



    useEffect(() => {
        const initAndCheckDeviceUpdates = async () => {
            console.log("Checking for device updates...");
            const selectedDevice = JSON.parse(localStorage.getItem('selectedDevice'));
            if (!selectedDevice) {
                console.log("No selected device found in localStorage.");
                return; // Exit if no device is selected
            }
    
            const devices = await fetchDevicesForCurrentStore();
            if (devices.length === 0) {
                console.log("No devices found for the current store.");
                return;
            }
            console.log(`Fetched ${devices.length} devices for the current store.`);
    
            const matchedDevice = devices.find(device => device.id === selectedDevice.id);
            if (!matchedDevice) {
                console.log("No matching device found in the fetched devices list.");
                return;
            }
    
            console.log("Matched device found:", matchedDevice);
            if (matchedDevice.type !== selectedDevice.type || matchedDevice.target !== selectedDevice.target) {
                console.log("Device type or target has changed. Updating localStorage and navigating...");
                localStorage.setItem('selectedDevice', JSON.stringify(matchedDevice));
    
                // Navigate based on the updated device type
                if (matchedDevice.type === 'slideshow') {
                    localStorage.setItem('currentSlideshowId', matchedDevice.target);
                    navigate('/slideshowplayer');
                } else if (matchedDevice.type === 'queue') {
                    // Assuming that the 'queue' page might have a different handling or navigation
                    localStorage.setItem('selectedStore', JSON.stringify({ id: matchedDevice.target }));
                    navigate('/queue');
                }
            } else {
                console.log("No changes in device type or target. No action needed.");
            }
        };
    
        // Call immediately on mount to fetch devices and check for any necessary updates
        initAndCheckDeviceUpdates();
    
        // Then set up the interval to periodically check for updates
        const intervalId = setInterval(initAndCheckDeviceUpdates, 60 * 60 * 1000); // 5 minutes
    
        return () => clearInterval(intervalId); // Cleanup on component unmount
    }, [navigate]);
    

    
    // Function to refresh the token with retry logic
    const refreshToken = async () => {
        const refreshTokenUrl = `${process.env.REACT_APP_API_BASE_URL}/users/jwt-refresh/`;
        let attempt = 0; // Attempt counter
        let delay = 5000; // Starting delay of 5 seconds
        const maxAttempts = 9; // Define maximum attempts before redirection
        const refreshTokenValue = localStorage.getItem('refreshToken');
    
        const attemptRefresh = async () => {
            const refreshTokenValue = localStorage.getItem('refreshToken');
            return await fetch(refreshTokenUrl, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ refresh: refreshTokenValue })
            });
        };
    
        while (true) {
            const response = await attemptRefresh();
            if (response.ok) {
                const data = await response.json();
                localStorage.setItem('token', data.access);
                localStorage.setItem('refreshToken', data.refresh);
                return true; // Successful token refresh
            } else {
                attempt++;
                if (attempt > maxAttempts) {
                    // Redirect to login page using window.location after exceeding max attempts
                    window.location.href = '/login?tv=true';
                    return false; // Optionally, return false to indicate the redirection
                }
                // Exponential backoff logic
                const backoffDelay = Math.pow(2, attempt - 1) * delay;
                console.error(`Token refresh attempt ${attempt} failed, retrying in ${backoffDelay / 1000} seconds...`);
                await new Promise(resolve => setTimeout(resolve, backoffDelay));
            }
        }
    };

    // Function to fetch slides with token refresh logic
    const fetchSlides = async () => {
        const url = `${process.env.REACT_APP_API_BASE_URL}/users/slideshows/${slideshowId}/`; // Update the URL based on your actual endpoint

        let response = await fetchWithRefresh(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
        });

        if (response.ok) {
            const data = await response.json();
            // Filter slides to only include those within the valid date range
            const validSlides = data.slides
                .filter(slide => isCurrentDateInRange(slide.start_date, slide.end_date))
                .sort((a, b) => a.order - b.order);
            setSlides(validSlides);
            setFadeDuration(data.fade_duration);
        } else {
            console.error('Failed to fetch slides:', response.statusText);
        }
    };

    // Helper function to handle fetch with automatic token refresh
    const fetchWithRefresh = async (url, options) => {
        let response = await fetch(url, options);

        // If unauthorized (token expired)
        if (response.status === 401) {
            // Try to refresh the token
            const tokenRefreshed = await refreshToken();
            
            if (tokenRefreshed) {
                // Retry the original request with the new token
                options.headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
                return fetch(url, options);
            } else {
                // Handle token refresh failure (maybe redirect to login?)
                console.error('Token refresh failed, redirecting to login...');
                // Redirect to login or handle failure
            }
        }

        return response;
    };

    useEffect(() => {
        fetchSlides(); // Fetch slides on component mount

        const intervalId = setInterval(() => {
            fetchSlides(); // Re-fetch slides every 15 minutes
        }, 15 * 60 * 1000);

        return () => clearInterval(intervalId); // Cleanup interval on component unmount
    }, [slideshowId]);

    useEffect(() => {
        const threshold = Math.min(3, slides.length); // Load at least 3 assets or all if fewer
        loadedAssetsCount.current = 0;
    
        slides.forEach((slide, index) => {
            const isVideo = slide.image_url.endsWith('.mp4');
            if (isVideo) {
                // Check if video metadata is loaded
                if (videoRefs.current[index] && videoRefs.current[index].readyState >= 1) {
                    loadedAssetsCount.current++;
                }
            } else {
                // For images, check if already complete (cached)
                const img = new Image();
                img.src = slide.image_url;
                img.onload = () => handleAssetLoaded(); // Increment count on image load
                if (img.complete) {
                    loadedAssetsCount.current++;
                }
            }
        });
    
        if (loadedAssetsCount.current >= threshold) {
            setLoading(false);
        }
    }, [slides]);
    
    const handleAssetLoaded = () => {
        loadedAssetsCount.current++;
        const threshold = Math.min(3, slides.length);
        if (loadedAssetsCount.current >= threshold && loading) {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (currentIndex >= slides.length) {
            setCurrentIndex(0); // Reset to first slide if current index is out of bounds
        }
    }, [slides, currentIndex]);


    useEffect(() => {
        const handleTransition = () => {
            const nextIndex = (currentIndex + 1) % slides.length;
            console.log(`Transitioning from slide ${currentIndex} to slide ${nextIndex}`);
            setCurrentIndex(nextIndex);
        };
    
        if (slides[currentIndex]) {
            const slide = slides[currentIndex];
            const isVideo = slide.image_url.endsWith('.mp4');
    
            if (isVideo) {
                // Assign onEnded handler for videos to handle transition
                const videoElement = videoRefs.current[currentIndex];
                if (videoElement && videoElement.readyState >= 1) {
                    videoElement.onended = handleTransition;
                    videoElement.muted = true;
                    videoElement.autoplay = true;
                    videoElement.playsInline = true;
                    videoElement.play().catch(err => console.error("Error playing video:", err));
                    console.log(`Playing video at index ${currentIndex}`);
                }
            } else {
                // Set timeout for images based on their duration
                const imageDuration = slide.duration * 1000; // Convert seconds to milliseconds
                setTimeout(handleTransition, imageDuration);
                console.log(`Displaying image at index ${currentIndex} for ${imageDuration} milliseconds.`);
            }
        }
    }, [currentIndex, slides, videoRefs]);
    
    

    const handleVideoEnded = (index) => {
        console.log(`Video at index ${index} ended. Transitioning to next.`);
        setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
    };

    const handleVideoError = (event) => {
        console.error("Video error:", event.target.error);
    };

    

    const isCurrentDateInRange = (startDate, endDate) => {
        // Remove the "Z" to force interpretation as local time
        const startLocal = new Date(startDate.replace("Z", ""));
        const endLocal = new Date(endDate.replace("Z", ""));
    
        // Get the current date/time in local time
        const currentDate = new Date();
    
        return currentDate >= startLocal && currentDate <= endLocal;
    };
    

    return (
        <div className="fullscreen-slideshow">
            {loading ? (
                <div>Loading...</div> // Display loading page or spinner
            ) : (
                slides.map((slide, index) => {
                    if (!slide || !slide.image_url) {
                        console.error(`Slide or slide.image_url undefined at index ${index}`);
                        return null; // or return a placeholder component
                    }
    
                    return slide.image_url.endsWith('.mp4') ? (
                        <video 
                            ref={(el) => videoRefs.current[index] = el}
                            onLoadedMetadata={(event) => {
                                handleAssetLoaded();
                                if (index === currentIndex) {
                                    // Directly use the event target which is the video element
                                    event.target.play().catch(err => {
                                        console.error("Error auto-playing video:", err);
                                        // Handle potential play() promise rejection.
                                    });
                                }
                            }}
                            preload="auto"
                            key={index}
                            src={slide.image_url}
                            muted
                            playsInline
                            className={index === currentIndex ? 'active-slide' : ''}
                            onEnded={() => handleVideoEnded(index)}
                            onError={handleVideoError} 
                            style={{ transitionDuration: `${fadeDuration}s` }}
                        />
                    ) : (
                        <img
                            key={index}
                            src={slide.image_url}
                            alt={`Slide ${index + 1}`}
                            onLoad={() => handleAssetLoaded()}
                            className={index === currentIndex ? 'active-slide' : ''}
                            style={{ transitionDuration: `${fadeDuration}s` }}
                        />
                    );
                })
            )}
        </div>
    );
    
    
}

export default SlideshowPlayer;