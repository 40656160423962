// BackgroundSelectionStep.js
import React, { useState } from 'react';
import BackgroundModal from '../BackgroundModal';
import ConfirmationModal from '../ConfirmationModal'; // Make sure the path is correct

const BackgroundSelectionStep = ({ asset, setAsset, backgroundImages, dealSheetId, addBackgroundImage, refreshBackgroundImage }) => {
    const [showBackgroundModal, setShowBackgroundModal] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [selectedImageForDeletion, setSelectedImageForDeletion] = useState(null);

    const removeBackgroundImage = async (image) => {
        const imageUrl = image.url; // Extract the URL or unique identifier of the image to be removed
        const requestUrl = `${process.env.REACT_APP_API_BASE_URL}/users/deal-sheets/${dealSheetId}/remove-background/`; // Adjust the URL as needed
    
        try {
            const response = await fetch(requestUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`, // Assuming you are using token-based authentication
                },
                body: JSON.stringify({ image_url: imageUrl }), // Make sure the body matches the expected format of your backend
            });
    
            if (!response.ok) {
                throw new Error('Failed to remove background image');
            }
    
            // Optionally, parse the response if needed
            // const data = await response.json();

            refreshBackgroundImage(imageUrl);
            

    
            alert('Background image successfully removed');
        } catch (error) {
            console.error('Error removing background image:', error);
            alert('An error occurred while removing the background image.');
        }
    };

    const isSelected = (image) => {
        return asset.background && asset.background.url === image.url;
    };

    const extractFilename = (url) => {
        return url.split('/').pop();
    };

    const handleOpenModal = () => {
        setShowBackgroundModal(true);
    };

    const handleCloseModal = () => {
        setShowBackgroundModal(false);
    };

    const handleOpenConfirmationModal = (image) => {
        setSelectedImageForDeletion(image);
        setShowConfirmationModal(true);
    };

    const handleConfirmDeletion = () => {
        removeBackgroundImage(selectedImageForDeletion);
        setShowConfirmationModal(false);
    };

    return (
        <div>
            <h2>Select Background</h2>
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', position: 'relative' }}>
                <button onClick={handleOpenModal} style={{ border: '2px dashed gray', borderRadius: '5px', padding: '2px', display: 'flex', justifyContent: 'center', alignItems: 'center', width: 100, height: 100 }}>
                    <span>Upload New</span>
                </button>
                {backgroundImages.map((image, index) => (
                    <div key={index} style={{ position: 'relative' }}>
                        <button
                            onClick={() => setAsset({ ...asset, background: image })}
                            style={{
                                border: isSelected(image) ? '2px solid black' : 'none',
                                borderRadius: '5px',
                                padding: '2px',
                                display: 'block',
                            }}
                        >
                            <img src={image.url} alt={`Background ${index}`} style={{ width: 100, height: 100 }} />
                        </button>
                        <div
                            onClick={(e) => {
                                e.stopPropagation(); // Prevent button click from triggering
                                handleOpenConfirmationModal(image);
                            }}
                            style={{
                                position: 'absolute',
                                top: 5,
                                right: 5,
                                cursor: 'pointer',
                                background: 'red',
                                borderRadius: '50%',
                                width: '20px',
                                height: '20px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                color: 'white',
                            }}
                        >
                            ×
                        </div>
                    </div>
                ))}
            </div>
            {asset.background && (
                <>
                    <p>The selected background is:</p>
                    <p>{extractFilename(asset.background.url)}</p>
                </>
            )}
            {showBackgroundModal && (
                <BackgroundModal onClose={handleCloseModal} dealSheetId={dealSheetId} addBackgroundImage={addBackgroundImage} />
            )}
            <ConfirmationModal
                isOpen={showConfirmationModal}
                onClose={() => setShowConfirmationModal(false)}
                onConfirm={handleConfirmDeletion}
                title="Confirm Deletion"
                message="Are you sure you want to delete this background image?"
            />
        </div>
    );
};

export default BackgroundSelectionStep;
