import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './Signup.css'; // Import the same CSS file
import CalendlyModal from './CalendlyModal'; // Import the new modal component

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [isTvLogin, setIsTvLogin] = useState(false); // State to track if it's TV login mode
  const navigate = useNavigate();
  const location = useLocation();

  const [isModalOpen, setIsModalOpen] = useState(false); // State to manage modal visibility

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tvLoginMode = queryParams.get('tv') === 'true';
    console.log('TV Login Mode from Query Params:', tvLoginMode);
    setIsTvLogin(tvLoginMode); // Set the isTvLogin state based on the URL query

    if (tvLoginMode) {
      const savedUsername = localStorage.getItem('tvUsername');
      const savedPassword = localStorage.getItem('tvPassword');
      if (savedUsername && savedPassword) {
        login(savedUsername, savedPassword, true);
      }
    }
  }, [location.search]);

  const handleSubmit = (e) => {
    e.preventDefault();
    login(username, password);
  };

  const login = (username, password, isTvLoginOverride = null) => {
    const isTvLoginCurrent = isTvLoginOverride !== null ? isTvLoginOverride : isTvLogin; // Use override if provided
    console.log('Logging in. TV Login Mode:', isTvLoginCurrent); // Log whether it's a TV login
  
    const lowercaseUsername = username?.toLowerCase() || '';
    fetch(`${process.env.REACT_APP_API_BASE_URL}/users/jwt-login/`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ username: lowercaseUsername, password }),
    })
    .then((response) => response.json())
    .then((data) => {
      if (data.access) {
        console.log('Login successful. Redirecting based on TV mode:', isTvLoginCurrent); // Log successful login and next redirect
        localStorage.setItem('token', data.access);
        localStorage.setItem('refreshToken', data.refresh);
        if (isTvLoginCurrent) {
          localStorage.setItem('tvUsername', lowercaseUsername);
          localStorage.setItem('tvPassword', password);
          navigate('/select-store');
        } else {
          navigate('/dashboard');
        }
      } else {
        setError('Login failed');
        console.error('Login failed with error:', data); // Log any error message from login attempt
      }
    });
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const viewHardware = () => navigate('/hardware');

  return (
    <div className="div-container">
      <div className="page-container">
        <img src="https://d27oebqltxtb0s.cloudfront.net/GreenMindLogo.png" alt="Logo" className="logo" />
        <div className="signup-container">
          <h1 className="signup-title">Welcome to Greenmind</h1>
          <h1 className="signup-subtitle">NEXT GEN SOLUTIONS FOR DISPENSARIES</h1>
          <form onSubmit={handleSubmit} className="signup-form">
            <div className="email-password-container">
              <input
                type="text"
                placeholder="Email"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                className="name-input"
              />
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="name-input"
              />
            </div>
            <button type="submit" className="submit-button">Log In</button>
          </form>
          {error && <div className="error-message">{error}</div>}
          {!isTvLogin && ( // Conditionally render these elements
            <div className="login-link-container">
              <span>New User? </span>
              <a className='createanaccount' href="/signup">Create an Account</a>
              <br />
              <a className='createanaccount' href="/reset-password">Reset My Password</a>
            </div>
          )}
        </div>
        <div className="login-buttons-container">
          <button onClick={openModal} className="submit-button" style={{width:"200px"}}>Book A Demo</button>
          <button onClick={viewHardware} className="submit-button" style={{width:"200px"}}>View Hardware</button>
        </div>        <CalendlyModal isOpen={isModalOpen} onClose={closeModal} />
      </div>
    </div>
  );
};

export default Login;
