function ExportModal({ isOpen, onClose, onExport, fields, setFields, fieldLabels }) {
    const handleFieldChange = (field, checked) => {
        setFields({ ...fields, [field]: checked });
    };

    const handleExportToCSV = () => {
        // Call the onExport function with an argument to specify CSV export
        onExport('csv');
    };

    const handleCopyToClipboard = () => {
        // Call the onExport function with an argument to specify clipboard copy
        onExport('clipboard');
    };

    if (!isOpen) {
        return null;
    }

    return (
        <div className="export-modal-container">
            <div className="export-modal-content">
                <h2>Select Fields to Export</h2>
                {Object.keys(fields).map((field) => (
                    <div key={field}>
                        <input
                            type="checkbox"
                            checked={fields[field]}
                            onChange={(e) => handleFieldChange(field, e.target.checked)}
                        />
                        {fieldLabels[field] || field} {/* Display the user-friendly label or fallback to the field name */}
                    </div>
                ))}
                <div className="button-container">
                    <button onClick={handleExportToCSV}>Save to CSV</button>
                    <button onClick={handleCopyToClipboard}>Copy to Clipboard</button>
                    <button onClick={onClose}>Close</button>
                </div>
            </div>
        </div>
    );
}

export default ExportModal;
