import React, { useState, useEffect } from 'react';
import EditSlideshowModal from './EditSlideshowModal';  // Make sure to implement this modal for editing
import { useNavigate } from 'react-router-dom';
import ConfirmationModal from './ConfirmationModal';
import './SlideshowsPage.css';  // Remember to style appropriately
import 'font-awesome/css/font-awesome.min.css';
import AppHeader from './AppHeader';


function SlideshowsPage() {
    const navigate = useNavigate();
    const [slideshows, setSlideshows] = useState([]);
    const [isEditModalVisible, setEditModalVisible] = useState(false);
    const [currentSlideshow, setCurrentSlideshow] = useState(null);
    const store = JSON.parse(localStorage.getItem('selectedStore'));
    const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
    const [slideshowToDelete, setSlideshowToDelete] = useState(null);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_BASE_URL}/users/slideshows/`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'Selected-Store-Id': store.id
            }
        })
        .then(res => res.json())
        .then(data => setSlideshows(data))
        .catch(error => console.error("Error fetching slideshows:", error));
    }, []);

    const handleBack = () => {
        navigate('/dashboard');
    }

    const handleAddSlideshow = () => {
        const newSlideshow = {
            store: store.id,
        };
    
        fetch(`${process.env.REACT_APP_API_BASE_URL}/users/slideshows/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify(newSlideshow)
        })
        .then(res => res.json())
        .then(data => {
            // Update the local state with the new slideshow
            setSlideshows(prevSlideshows => [...prevSlideshows, data]);
            
            // Set the currentSlideshow state to the newly created slideshow
            setCurrentSlideshow(data);
    
            // Open the edit modal for the newly created slideshow
            setEditModalVisible(true);
        })
        .catch(error => console.error("Error adding slideshow:", error));
    };

    const handleDeleteSlideshow = (storeId, slideshowId) => {
        // Step 3: Update to set the state instead of deleting immediately
        const slideshow = slideshows.find(s => s.id === slideshowId);
        setSlideshowToDelete(slideshow);
        setConfirmationModalOpen(true);
    };

    const confirmDeleteSlideshow = () => {
        if (slideshowToDelete) {
            fetch(`${process.env.REACT_APP_API_BASE_URL}/users/slideshows/${slideshowToDelete.id}/`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Selected-Store-Id': store.id
                }
            })
            .then(() => {
                setSlideshows(prevSlideshows => prevSlideshows.filter(slideshow => slideshow.id !== slideshowToDelete.id));
                setConfirmationModalOpen(false);
                setSlideshowToDelete(null);
            })
            .catch(error => console.error("Error deleting slideshow:", error));
        }
    };

    

    const handleEditSlideshow = (slideshowId) => {
        const slideshowToEdit = slideshows.find(slideshow => slideshow.id === slideshowId);
        setCurrentSlideshow(slideshowToEdit);
        setEditModalVisible(true);
    };

    // This is the new function to handle updating the slideshow
    const handleUpdateSlideshow = (updatedSlideshow) => {
        fetch(`${process.env.REACT_APP_API_BASE_URL}/users/slideshows/${updatedSlideshow.id}/`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify(updatedSlideshow)
        })
        .then(res => res.json())
        .then(data => {
            setSlideshows(prevSlideshows => prevSlideshows.map(slideshow => {
                if (slideshow.id === updatedSlideshow.id) {
                    return updatedSlideshow;
                }
                return slideshow;
            }));
            setEditModalVisible(false);
        })
        .catch(error => console.error("Error updating slideshow:", error));
    };

    const handlePlaySlideshow = (slideshowId) => {
        localStorage.setItem('currentSlideshowId', slideshowId);
        navigate('/slideshowplayer');
    };

    const handleEditDevices = () => {
        navigate('/edit-devices')
    }

    return (
        <div className="slideshows-page">
            <AppHeader
                showBackButton={true}
                showSettings={true}
                showLogout={true}
                logoUrl="https://d27oebqltxtb0s.cloudfront.net/GreenMindSimpleLogo.png"
            />
            <div className="center-header">
                <button className="btn-add" onClick={handleAddSlideshow}>
                    <i class="fa fa-plus" aria-hidden="true"></i> New Slideshow
                </button>
                <button className="btn-add" onClick={handleEditDevices}>
                    Edit Devices
                </button>
                <button className="btn-add" onClick={() => navigate('/edit-devices2')}>
                    Edit Devices 2 (BETA)
                </button>
            </div>
            <div className="slideshows-list-container">
                <div className="slideshows-list">
                    {slideshows.map(slideshow => (
                        <div key={slideshow.id} className="slideshow-row">
                            {/* Representation of the slideshow (maybe a thumbnail?) */}
                            <button className="btn-play" onClick={() => handlePlaySlideshow(slideshow.id)}>
                                <i class="fa fa-play" aria-hidden="true"></i>
                            </button>
                            <div className="slideshow-preview">
                                {slideshow.slides.slice(0, 8).map((slide, index) => (
                                    slide.image_url.endsWith('.mp4') ? (
                                        <div key={index} className="video-preview">
                                            <i className="fa fa-video-camera" aria-hidden="true"></i>
                                        </div>
                                    ) : (
                                        <img key={index} src={slide.image_url} alt={`Slide ${index + 1}`} />
                                    )
                                ))}
                            </div>
                            <div className="slideshow-name"><div className='title-name-spacing'>{slideshow.name}</div></div>
                            
                            <button className="btn-edit" onClick={() => handleEditSlideshow(slideshow.id)}>
                                <i class="fa fa-pencil-square-o" aria-hidden="true"></i> Edit
                            </button>
                            <button className="btn-delete" onClick={() => handleDeleteSlideshow(store.id, slideshow.id)}>
                                <i class="fa fa-trash-o" aria-hidden="true"></i> Delete
                            </button>
                        </div>
                    ))}
                </div>
            </div>
            {isEditModalVisible && 
                <div className="modal-background">
                    <div className="modal-content">
                        <EditSlideshowModal 
                            slideshow={currentSlideshow} 
                            onClose={() => setEditModalVisible(false)} 
                            onSave={(updatedSlideshow) => {
                                setSlideshows(prevSlideshows => prevSlideshows.map(slideshow => {
                                    if (slideshow.id === updatedSlideshow.id) {
                                        return updatedSlideshow;
                                    }
                                    return slideshow;
                                }));
                            }}
                        />
                    </div>
                </div>
            }
            <ConfirmationModal
                isOpen={isConfirmationModalOpen}
                onClose={() => setConfirmationModalOpen(false)}
                onConfirm={confirmDeleteSlideshow}
                title="Confirm Deletion"
                message="Are you sure you want to delete this slideshow?"
            />
        </div>
    );
}

export default SlideshowsPage;
