import React, { useState, useEffect } from 'react';

const StoreReportPage = () => {
  const [stores, setStores] = useState([]);
  
  useEffect(() => {
    const fetchStores = async () => {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/store-report`, {
        method: 'GET',
        headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` },
      });
      if (response.ok) {
        const data = await response.json();
        setStores(data.stores);
      } else {
        console.error('Failed to fetch stores');
      }
    };
    
    fetchStores();
  }, []);

  return (
    <div>
      <h1>Store Report</h1>
      {stores.map((store, index) => (
        <div 
          key={store.id} 
          style={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            marginBottom: '10px', 
            paddingBottom: '10px',
            borderBottom: '1px solid #ccc', // Add a border to the bottom of each row for separation
            // Add space between rows, but not after the last item
            marginBottom: index === stores.length - 1 ? '0' : '10px', 
          }}
        >
          <span><strong>Name:</strong> {store.name}</span>
          <span><strong>Company:</strong> {store.company}</span>
          <span><strong>Created At:</strong> {new Date(store.created_at).toLocaleString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true })}</span>
        </div>
      ))}
    </div>
  );
};

export default StoreReportPage;
