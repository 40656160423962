import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './Dashboard.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { FaStar } from 'react-icons/fa';


const Dashboard = () => {

    const navigate = useNavigate();
    const [userName, setUserName] = useState('');

    const isFirstLoad = useRef(true);


    const [userPermissions, setUserPermissions] = useState({
      isAdmin: false,
      canAccessHealthCheck: false,
      canAccessSlideshows: false,
      canAccessDealsheets: false,
      canAccessCustomerMenu: false,
      canAccessQueue: false,
      canAccessQrTracker: false,
      canAccessReorderReport: false,
   });

    const [stores, setStores] = useState([]);  // Store list
    const [isDropdownVisible, setDropdownVisible] = useState(false);  // Dropdown visibility state
    const dropdownRef = useRef(null);  // Reference to the dropdown for detecting outside clicks
    const [selectedStore, setSelectedStore] = useState(null);
    const [favoriteStoreId, setFavoriteStoreId] = useState(null);

    const [isStoreListFetched, setIsStoreListFetched] = useState(false);

    const [zoomStates, setZoomStates] = useState({
      'inset-div1': { scale: 1, x: 50, y: 50 },
      'inset-div2': { scale: 1, x: 50, y: 50 },
      'inset-div3': { scale: 1, x: 50, y: 50 },
      'inset-div4': { scale: 1, x: 50, y: 50 },
      'inset-div5': { scale: 1, x: 50, y: 50 },
      'inset-div6': { scale: 1, x: 50, y: 50 },
    });

    const handleMouseEnter = (divId) => {
      setZoomStates(prevZoomStates => ({
        ...prevZoomStates,
        [divId]: { ...prevZoomStates[divId], scale: 1.15 },
      }));
    };
    
    const handleMouseMove = (divId, e) => {
      const div = e.currentTarget;
      const { left, top, width, height } = div.getBoundingClientRect();
      const x = ((e.pageX - left) / width) * 100;
      const y = ((e.pageY - top) / height) * 100;
      setZoomStates(prevZoomStates => ({
        ...prevZoomStates,
        [divId]: { ...prevZoomStates[divId], x, y },
      }));
    };
    
    const handleMouseLeave = (divId) => {
      setZoomStates(prevZoomStates => ({
        ...prevZoomStates,
        [divId]: { scale: 1, x: 50, y: 50 },
      }));
    };

    const handleSetFavoriteStore = (storeId) => {
      fetch(`${process.env.REACT_APP_API_BASE_URL}/users/set-favorite-store/${storeId}/`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
      })
      .then(response => response.json())
      .then(() => setFavoriteStoreId(storeId))  // Update favorite store in state
      .catch(error => console.error('Error setting favorite store:', error));
  };


      const handleLogout = () => {
        // Remove the token from localStorage
        localStorage.removeItem('token');

        // Redirect to login page or homepage
        navigate('/login');
    }

    const handleDownload = () => {
      const link = document.createElement('a');
      link.href = '/GreenmindCFD.bat';  // Adjusted path
      link.download = 'GreenmindCFD.bat';
      link.click();
    };

    const handleSettings = (e) => {
      
      if (!isStoreListFetched) return;
          if (!userPermissions.isAdmin) {
            alert('You do not have access to Settings.');
            return;
        }
        // Redirect to login page or homepage
        if (!promptSelectStore('handleSettings')) {
        navigate('/settings');
        } else if (selectedStore) {
          navigate('/settings');
        }
    }

    const handleHealthCheck = (e) => {
      if (!isStoreListFetched) return;
      // Redirect to login page or homepage
      if (!userPermissions.canAccessHealthCheck) {
        alert('You do not have access to Health Check.');
        return;
    }
    if (!promptSelectStore('handleHealthCheck')) {
      checkSubscriptionAndNavigate('/HealthReport', 'handleHealthCheck', e);
    } else if (selectedStore) {
      checkSubscriptionAndNavigate('/HealthReport', 'handleHealthCheck', e);
    }
  }

    const handleTools = (e) => {
      if (!isStoreListFetched) return;
        // Redirect to login page or homepage
        if (!userPermissions.canAccessDealsheets) {
          alert('You do not have access to Dealsheets.');
          return;
      }
      if (!promptSelectStore('handleTools')) {
        checkSubscriptionAndNavigate('/tools', 'handleTools', e);
      } else if (selectedStore) {
        checkSubscriptionAndNavigate('/tools', 'handleTools', e);
      }
    }

    const handleCFD = (e) => {
      if (!isStoreListFetched) return;
        // Redirect to login page or homepage
        if (!userPermissions.canAccessCustomerMenu) {
          alert('You do not have access to the Customer Menu.');
          return;
      }
      if (!promptSelectStore('handleCFD')) {
        checkSubscriptionAndNavigate('/cfd', 'handleCFD', e);
      } else if (selectedStore) {
        checkSubscriptionAndNavigate('/cfd', 'handleCFD', e);
      }
    }

    const handleQueue = (e) => {
      if (!isStoreListFetched) return;
        // Redirect to login page or homepage
        if (!userPermissions.canAccessQueue) {
          alert('You do not have access to the Queue.');
          return;
      }
      if (!promptSelectStore('handleQueue')) {
        checkSubscriptionAndNavigate('/queue', 'handleQueue', e);
      } else if (selectedStore) {
        checkSubscriptionAndNavigate('/queue', 'handleQueue', e);
      }
    }

    const handleQR = (e) => {
      if (!isStoreListFetched) return;
      // Redirect to login page or homepage
      if (!userPermissions.canAccessQrTracker) {
        alert('You do not have access to The QR Codes.');
        return;
    }
    if (!promptSelectStore('handleQR')) {
      checkSubscriptionAndNavigate('/QRCodeManager', 'handleQR', e);
    } else if (selectedStore) {
      checkSubscriptionAndNavigate('/QRCodeManager', 'handleQR', e);
    }
  }

    const handleSlideshow = (e) => {
      if (!isStoreListFetched) return;
        // Redirect to login page or homepage
        if (!userPermissions.canAccessSlideshows) {
          alert('You do not have access to the Slideshows.');
          return;
      }
      if (!promptSelectStore('handleSlideshow')) {
        checkSubscriptionAndNavigate('/slideshows', 'handleSlideshow', e);
      } else if (selectedStore) {
        checkSubscriptionAndNavigate('/slideshows', 'handleSlideshow', e);
      }
    }

    const handleReorder = (e) => {
      if (!isStoreListFetched) return;
      // Redirect to login page or homepage
      if (!userPermissions.canAccessReorderReport) {
        alert('You do not have access to the Reorder Report.');
        return;
    }
    if (!promptSelectStore('handleReorder')) {
      checkSubscriptionAndNavigate('/reorder-report', 'handleReorder', e);
    } else if (selectedStore) {
      checkSubscriptionAndNavigate('/reorder-report', 'handleReorder', e);
    }
  }


  const handleManageStores = () => {
    if (!isStoreListFetched) return;
    if (userPermissions.isAdmin) {
        navigate('/storespage');
    } else {
        alert('You do not have permission to manage stores.');
    }
};

// Function to check if the selected store has a valid subscription
const checkSubscriptionAndNavigate = (navigateTo, actionName, e) => {
  /*
  // Temporarily disable subscription check
  if (!selectedStore) {
      alert('Please select a store before proceeding.');
      return;
  }

  const fullStoreData = stores.find(s => s.id === selectedStore.id);
  const hasValidSubscription = fullStoreData && fullStoreData.subscription_tier && fullStoreData.subscription_tier !== 0;

  if (!hasValidSubscription) {
      if (userPermissions.isAdmin) {
          const manageSubscriptions = window.confirm(`This store does not have a valid subscription. Would you like to manage your subscriptions?`);
          if (manageSubscriptions) {
              navigate('/StoreSubscriptions');
          } else {
              const manageStores = window.confirm('Would you like to manage your stores?');
              if (manageStores) {
                  navigate('/storespage');
              }
          }
      } else {
          alert('Access denied. This store does not have a valid subscription.');
      }
  } else {
      navigate(navigateTo);
  }
  */
  // Check if a store is selected before navigating
  if (!selectedStore) {
    alert('Please select a store before proceeding.');
    return;
  }
  if (e.button === 1) {
      // Middle click detected; open in new tab
      window.open(navigateTo, '_blank');
      return;
  }
  navigate(navigateTo); // Navigate if a store is selected
};

// Function to prompt for store selection and check for valid subscription
const promptSelectStore = (action) => {
  /*
  // Temporarily disable subscription check
  if (!selectedStore) {
    const manageStores = window.confirm('No store selected. Would you like to manage your stores?');
    if (manageStores) {
      navigate('/storespage');
    } else {
      alert('Please select a store to continue.');
    }
    return true; // Exit the function early if no store is selected
  }

  // Commented out to temporarily disable subscription check
  const hasValidSubscription = selectedStore.subscription_tier && selectedStore.subscription_tier !== 0;

  if (!hasValidSubscription) {
      if (userPermissions.isAdmin) {
          const manageSubscriptions = window.confirm("This store does not have a valid subscription. Would you like to manage your subscriptions?");
          if (manageSubscriptions) {
              navigate('/StoreSubscriptions');
              return true;
          } else {
              const manageStores = window.confirm('Would you like to manage your stores?');
              if (manageStores) {
                  navigate('/storespage');
                  return true;
              }
          }
      }
      alert('Access denied. This store does not have a valid subscription.');
      return true;
  }
  */
  if (!selectedStore) {
    const navigateToManageStores = window.confirm('No store selected. Would you like to manage your stores?');
    if (navigateToManageStores) {
        navigate('/storespage');
    } else {
        alert('Please select a store to continue.');
    }
    return true; // Prevent further action if no store is selected
  }
  return false; // Allow proceeding if a store is selected
};


useEffect(() => {
  // Determine if this is the first load by checking session storage
  if (sessionStorage.getItem('isFirstLoad') === null) {
      isFirstLoad.current = true;
  } else {
      isFirstLoad.current = false;
  }

  // Fetch the current user data first to get the favorite store ID
  fetch(`${process.env.REACT_APP_API_BASE_URL}/users/current_user/`, {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
      },
  })
  .then(response => response.json())
  .then(data => {
      setUserName(data.first_name);
      setFavoriteStoreId(data.favorite_store);
      //console.error("favorite_store:", data.favorite_store);
      setUserPermissions({
          isAdmin: data.is_admin,
          canAccessHealthCheck: data.can_access_health_check,
          canAccessSlideshows: data.can_access_slideshows,
          canAccessDealsheets: data.can_access_dealsheets,
          canAccessCustomerMenu: data.can_access_customer_menu,
          canAccessQueue: data.can_access_queue,
          canAccessQrTracker: data.can_access_qr_tracker,
          canAccessReorderReport: data.can_access_reorder_report,
      });
  })
  .catch(error => console.error("Error fetching user data:", error));
}, []);

useEffect(() => {
  // Fetch stores only when favoriteStoreId is set
    fetch(`${process.env.REACT_APP_API_BASE_URL}/users/stores/`, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
    })
    .then(res => res.json())
    .then(data => {
        setStores(data);
        setIsStoreListFetched(true);

        //console.error("favorite_store ID:", favoriteStoreId);
        const savedStore = JSON.parse(localStorage.getItem('selectedStore'));
        // Only on the first load, set the selected store to either the favorite or saved store
        if (isFirstLoad.current) {
            
            if (favoriteStoreId) {
                const favoriteStore = data.find(store => store.id === favoriteStoreId);
                if (favoriteStore) {
                    setSelectedStore(favoriteStore);
                    localStorage.setItem('selectedStore', JSON.stringify({ id: favoriteStore.id, name: favoriteStore.name }));
                }
            } else if (savedStore) {
              setSelectedStore(savedStore);
          }
            isFirstLoad.current = false; // Mark the first load as complete
        } else if (savedStore && data.some(store => store.id === savedStore.id)) {
          // Only set the selectedStore to the savedStore if it is found in the fetched stores data
          setSelectedStore(savedStore);
      }
        setIsStoreListFetched(true);
        sessionStorage.setItem('isFirstLoad', 'false');
    })
    .catch(error => {
        console.error("Error fetching stores:", error);
        setIsStoreListFetched(true);
        sessionStorage.setItem('isFirstLoad', 'false');
    });

}, [favoriteStoreId]); // Dependency array ensures this runs only when favoriteStoreId changes

// Event listener to close the dropdown if clicked outside
useEffect(() => {
  document.addEventListener("mousedown", handleClickOutside);
  return () => {
      document.removeEventListener("mousedown", handleClickOutside);
  };
}, []);





    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setDropdownVisible(false);
        }
    };

    const toggleDropdown = () => {
        setDropdownVisible(!isDropdownVisible);
    };

    const handleSelectStore = (store) => {
      const fullStoreData = stores.find(s => s.id === store.id);
      if (fullStoreData) {
          setSelectedStore(fullStoreData);
          // Save only the necessary details to localStorage
          const minimalStoreData = { id: fullStoreData.id, name: fullStoreData.name };
          localStorage.setItem('selectedStore', JSON.stringify(minimalStoreData));
          localStorage.setItem('Selected-Store-Id', fullStoreData.id);
          console.log('Minimal store data saved to localStorage:', minimalStoreData);
      }
      setDropdownVisible(false);
  };
  
    
  return (
    <div className="dashboard-div-container">
      <div className="dash-settings-container">
        <div className='topleftbuttons buttons-div'>
        <button onClick={toggleDropdown}>
                {selectedStore ? selectedStore.name : "Select Store"}
            </button>
                    {isDropdownVisible && 
                    <div ref={dropdownRef} className="dropdown">
                        {stores.map(store => (
                            <div key={store.id} onClick={() => handleSelectStore(store)} className="store-item">
                              <FaStar
                                  className={`star-icon ${favoriteStoreId === store.id ? 'filled' : ''}`}
                                  onClick={(e) => {
                                      e.stopPropagation(); // Prevent dropdown from closing on star click
                                      handleSetFavoriteStore(store.id);  // Set favorite store
                                  }}
                              />
                              {store.name}
                          </div>
                        ))}
                    </div>}
          <button className='button-pulse' onClick={handleHealthCheck}>
            <p className='healthcheck'>Health Check</p>
          </button>
        </div>
        
        <div className="logo-div">
          <img src="https://d27oebqltxtb0s.cloudfront.net/GreenMindSimpleLogo.png" alt="Logo" className="dash-logo" />
        </div>
        <div className="buttons-div">
          <button onClick={handleSettings}>
            <i class="fa fa-cog" aria-hidden="true"></i> Settings
          </button>
          <button onClick={handleLogout}>
            <i class="fa fa-sign-out" aria-hidden="true"></i> Logout
          </button>
          
        </div>
      </div>
    <span className="user-name-dash">Welcome Back, {userName}</span>  {/* Display the user's name */}

      <div className="dashboard-container">
        <div className="quarter-div">
        <div
          className="inset-div inset-div1"
          onMouseEnter={() => handleMouseEnter('inset-div1')}
          onMouseMove={(e) => handleMouseMove('inset-div1', e)}
          onMouseLeave={() => handleMouseLeave('inset-div1')}
          onMouseDown={(e) => handleSlideshow(e)}
          
        >
          <img
      className="background-image"
      src="https://d27oebqltxtb0s.cloudfront.net/ScreenImg.png"
      alt="TV Slideshow"
      style={{
        transform: `scale(${zoomStates['inset-div1'].scale})`,
        transformOrigin: `${zoomStates['inset-div1'].x}% ${zoomStates['inset-div1'].y}%`
      }}
    />
            <span className="btnlabel">TV SLIDESHOW
            </span>
          </div>
        </div>
        <div className="quarter-div">
        <div
          className="inset-div inset-div2"
          onMouseEnter={() => handleMouseEnter('inset-div2')}
          onMouseMove={(e) => handleMouseMove('inset-div2', e)}
          onMouseLeave={() => handleMouseLeave('inset-div2')}
          onMouseDown={(e) => handleTools(e)}
        >
          <img
      className="background-image"
      src="https://d27oebqltxtb0s.cloudfront.net/ReportImg.png"
      alt="TV Slideshow"
      style={{
        transform: `scale(${zoomStates['inset-div2'].scale})`,
        transformOrigin: `${zoomStates['inset-div2'].x}% ${zoomStates['inset-div2'].y}%`
      }}
    />
            <span className="btnlabel">DEALSHEETS
            </span>
          </div>
        </div>
        <div className="quarter-div">
        <div
          className="inset-div inset-div3"
          onMouseEnter={() => handleMouseEnter('inset-div3')}
          onMouseMove={(e) => handleMouseMove('inset-div3', e)}
          onMouseLeave={() => handleMouseLeave('inset-div3')}
          onMouseDown={(e) => handleCFD(e)}
        >
          <img
      className="background-image"
      src="https://d27oebqltxtb0s.cloudfront.net/CFDImg.png"
      alt="TV Slideshow"
      style={{
        transform: `scale(${zoomStates['inset-div3'].scale})`,
        transformOrigin: `${zoomStates['inset-div3'].x}% ${zoomStates['inset-div3'].y}%`
      }}
    />
            <span className="btnlabel">CUSTOMER MENU
            </span>
            {/*<i className="fa fa-download download-icon" onClick={handleDownload} aria-hidden="true"></i>*/}
          </div>
        </div>
        <div className="quarter-div">
          <div
          className="inset-div inset-div4"
          onMouseEnter={() => handleMouseEnter('inset-div4')}
          onMouseMove={(e) => handleMouseMove('inset-div4', e)}
          onMouseLeave={() => handleMouseLeave('inset-div4')}
          onMouseDown={(e) => handleQueue(e)}
        >
          <img
      className="background-image"
      src="https://d27oebqltxtb0s.cloudfront.net/QueueImg.png"
      alt="TV Slideshow"
      style={{
        transform: `scale(${zoomStates['inset-div4'].scale})`,
        transformOrigin: `${zoomStates['inset-div4'].x}% ${zoomStates['inset-div4'].y}%`
      }}
    />
            <span className="btnlabel">QUEUE
            </span>
          </div>
        </div>
        <div className="quarter-div">
        <div
          className="inset-div inset-div5"
          onMouseEnter={() => handleMouseEnter('inset-div5')}
          onMouseMove={(e) => handleMouseMove('inset-div5', e)}
          onMouseLeave={() => handleMouseLeave('inset-div5')}
          onMouseDown={(e) => handleQR(e)}
        >
          <img
      className="background-image"
      src="https://d27oebqltxtb0s.cloudfront.net/QRImg.png"
      alt="TV Slideshow"
      style={{
        transform: `scale(${zoomStates['inset-div5'].scale})`,
        transformOrigin: `${zoomStates['inset-div5'].x}% ${zoomStates['inset-div5'].y}%`
      }}
    />
            <span className="btnlabel">QR TRACKER
            </span>
          </div>
        </div>
        <div className="quarter-div">
        <div
          className="inset-div inset-div6"
          onMouseEnter={() => handleMouseEnter('inset-div6')}
          onMouseMove={(e) => handleMouseMove('inset-div6', e)}
          onMouseLeave={() => handleMouseLeave('inset-div6')}
          onMouseDown={(e) => handleReorder(e)}
        >
          <img
      className="background-image"
      src="https://d27oebqltxtb0s.cloudfront.net/ReorderImg.png"
      alt="TV Slideshow"
      style={{
        transform: `scale(${zoomStates['inset-div6'].scale})`,
        transformOrigin: `${zoomStates['inset-div6'].x}% ${zoomStates['inset-div6'].y}%`
      }}
    />
            <span className="btnlabel">SUPPLY CHAIN</span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Dashboard;
