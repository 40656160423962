import React from 'react';
import './ConfirmationModal.css';

function ConfirmationModal({ isOpen, onClose, onConfirm, title, message, confirmButtonText = 'Delete', cancelButtonText = 'No' }) {
  if (!isOpen) return null;

  return (
    <div className="modal-background center-modal">
      <div className="conf-modal-content">
        <h2 className='conf-h2'>{title}</h2>
        <p className='conf-p'>{message}</p>
        <div className="modal-buttons">
          <button className="confirm-button conf-button" onClick={onConfirm}>{confirmButtonText}</button>
          <button className="cancel-button conf-button" onClick={onClose}>{cancelButtonText}</button>
        </div>
      </div>
    </div>
  );
}

export default ConfirmationModal;

