import React from 'react';
import './QueueHeader.css';
import { useNavigate } from 'react-router-dom';

class QueueHeader extends React.Component {

    handleBackClick = () => {
        this.props.navigate('/dashboard');
    };

    render() {
        console.log("Rendering QueueHeader component");
        return (
            <div className={`queue-header ${this.props.show ? 'show' : 'hide'}`}>
                <div className="button-container">
                    <button className="queue-btn-back" onClick={this.handleBackClick}>
                        <i class="fa fa-arrow-left" aria-hidden="true"></i> Back
                    </button>
                </div>
                <img className="header-image" src="https://d27oebqltxtb0s.cloudfront.net/HeaderImg.png" alt="Descriptive Alt Text" />
            </div>
        );
    }
}

function QueueHeaderWithNavigation(props) {
    const navigate = useNavigate();

    return <QueueHeader {...props} navigate={navigate} />;
}

export default QueueHeaderWithNavigation;
