import './Queue.css';
import React from 'react';
import QueueHeader from './QueueHeader'; // Make sure this path is correct
import { w3cwebsocket as W3CWebSocket } from 'websocket';

class Queue extends React.Component {
    state = {
        nextInLine: [],
        showHeader: false,
        logoURL: ''
    };

    componentDidMount() {
        this.shouldReconnect = true;
        // Set up an interval to reconnect WebSocket every hour
        this.reconnectInterval = setInterval(this.reconnectWebSocket, 3600000); // 3600000 ms = 1 hou
        //this.checkForDeviceUpdates();
        this.connectWebSocket();
        document.addEventListener('mousemove', this.handleMouseMove)
        const selectedStore = JSON.parse(localStorage.getItem('selectedStore'));
        const selectedStoreId = selectedStore ? selectedStore.id : null;

        if (selectedStoreId) {
            this.fetchWithRefresh(`${process.env.REACT_APP_API_BASE_URL}/users/stores/${selectedStoreId}/store-details/`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            })
            .then(response => response.json())
            .then(data => {
                const logoUrl = data.logo_url;
                const backgroundUrl = data.queue_bg_url;
                const primaryTextColor = data.primary_text_color;
                const secondaryTextColor = data.secondary_text_color;
                const logoSizeMultiplier = data.logo_size_multiplier;
                const nextInLineText = data.next_in_line_text || 'Next In Line:';

                this.setState({
                    logoURL: logoUrl,
                    backgroundURL: backgroundUrl,
                    primaryTextColor: primaryTextColor,
                    secondaryTextColor: secondaryTextColor,
                    logoSizeMultiplier: logoSizeMultiplier,
                    NextInLineText: nextInLineText,
                });
            })
            .catch(error => console.error("Error fetching store details:", error));
        }
    }

    checkForDeviceUpdates = () => {
        const updateCheck = async () => {
            const selectedDevice = JSON.parse(localStorage.getItem('selectedDevice'));
            if (!selectedDevice) return;
    
            const devices = await this.fetchDevicesForCurrentStore();
            const matchedDevice = devices.find(device => device.id === selectedDevice.id);
            if (matchedDevice && (matchedDevice.type !== selectedDevice.type || matchedDevice.target !== selectedDevice.target)) {
                console.log("Device update detected. Updating localStorage with new device details.");
                localStorage.setItem('selectedDevice', JSON.stringify(matchedDevice));
    
                // Perform navigation or refresh based on the type of device
                if (matchedDevice.type !== 'queue') {
                    // Assuming you have a way to handle navigation or refreshing the component based on the new device type
                    // This part needs to be adapted based on your routing or state management approach
                    console.log("Device type changed. Performing necessary actions...");
                }
            }
        };
    
        // Set up the interval for periodic device update checks
        updateCheck();
        this.deviceUpdateInterval = setInterval(updateCheck, 60 * 60 * 1000); // Every 60 minutes
    }
    
    fetchDevicesForCurrentStore = async () => {
        const selectedStore = JSON.parse(localStorage.getItem('selectedStore'));
        if (!selectedStore) return [];
    
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/get-devices/?store_id=${selectedStore.id}`, {
                headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` },
            });
            if (!response.ok) throw new Error('Network response was not ok');
            return await response.json();
        } catch (error) {
            console.error("Error fetching devices:", error);
            return [];
        }
    };

    reconnectWebSocket = () => {
        // Close the existing connection if it exists
        if (this.wsClient) {
            this.wsClient.close();
            this.wsClient = null;
        }
        // Establish a new connection
        this.connectWebSocket();
    };

    resetInactivityTimeout = () => {
        clearTimeout(this.inactivityTimeout);
        this.inactivityTimeout = setTimeout(() => {
            console.log('No messages received for 10 seconds, reconnecting WebSocket.');
            this.reconnectWebSocket();
        }, 10000); // 10000 ms = 10 seconds
    };

    connectWebSocket = () => {
        console.log("Trying to connect to WebSocket");
        const selectedStore = JSON.parse(localStorage.getItem('selectedStore'));
        const selectedStoreId = selectedStore ? selectedStore.id : null;
        
        if(!selectedStoreId) {
            console.error("Store ID is missing.");
            return;
        }
    
        const wsUrlBase = process.env.REACT_APP_API_BASE_URL.replace("http", "ws");
        const wsUrl = `${wsUrlBase}/ws/queue/${selectedStoreId}/`;
        console.log("WebSocket URL:", wsUrl);
        if (this.connecting) {
            return;
        }
        
        this.connecting = true;
        
        const wsClient = new W3CWebSocket(wsUrl);
        
        
        wsClient.onopen = () => {
            console.log('WebSocket Client Connected');
            this.connecting = false;
            this.resetInactivityTimeout();
        };
        
        

        wsClient.onmessage = (message) => {
            const dataFromServer = JSON.parse(message.data);
            console.log("Received:", dataFromServer);
            this.resetInactivityTimeout();
            if (dataFromServer.type === 'ping') {
                console.log('Received ping from server');
                wsClient.send(JSON.stringify({ type: 'pong' }));
            } else {
                if (dataFromServer.names && dataFromServer.names.length > 0) {
                    this.setState({nextInLine: dataFromServer.names.slice(0, this.state.maxNamesToDisplay) });
                } else {
                    this.setState({ nextInLine: ['No Wait!'] });
                }
            }
        };

        wsClient.onerror = (error) => {
            console.error("WebSocket Error:", error);
            this.connecting = false;
            setTimeout(this.connectWebSocket, 5000); // Attempt to reconnect after 5 seconds
        };

        wsClient.onclose = (event) => {
            console.log(`WebSocket Client Disconnected: ${event.code} - ${event.reason}`);
            if (this.shouldReconnect && !this.reconnectInterval) {
                console.log('Attempting to reconnect in 5 seconds...');
                setTimeout(this.connectWebSocket, 5000);
            }
        };

        this.wsClient = wsClient;
    }

    componentWillUnmount() {
        this.shouldReconnect = false;
        if (this.deviceUpdateInterval) clearInterval(this.deviceUpdateInterval);
        clearTimeout(this.inactivityTimeout);
        if (this.wsClient) {
            this.wsClient.close();
        }
        document.removeEventListener('mousemove', this.handleMouseMove);
        if (this.reconnectInterval) {
            clearInterval(this.reconnectInterval);
        }
    }

    handleMouseMove = () => {
        // Show the header when the mouse moves
        this.setState({ showHeader: true });
        
        // Hide the header after 3 seconds of no mouse movement
        clearTimeout(this.mouseMoveTimeout);
        this.mouseMoveTimeout = setTimeout(() => {
            this.setState({ showHeader: false });
        }, 3000);
    };

    fetchWithRefresh = async (url, options) => {
        const response = await fetch(url, options);
        
        // If unauthorized (token expired)
        if (response.status === 401) {
            // Try to refresh the token
            const tokenRefreshed = await this.refreshToken();
            
            if (tokenRefreshed) {
                // Retry the original request with the new token
                options.headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
                return fetch(url, options);
            } else {
                window.location.href = '/login?tv=true';
            }
        }
    
        return response;
    };
    
    refreshToken = async () => {
        // Assuming you have an endpoint like /jwt-refresh/ to refresh tokens
        const url = `${process.env.REACT_APP_API_BASE_URL}/users/jwt-refresh/`;
        const refreshToken = localStorage.getItem('refreshToken');  // Make sure you're saving the refresh token in local storage upon login
    
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ refresh: refreshToken })
        });
    
        if (response.ok) {
            const data = await response.json();
            console.log('Refresh Token Response:', data);
            localStorage.setItem('token', data.access);
            localStorage.setItem('refreshToken', data.refresh);
            return true;
        }
    
        return false;
    };
    

    fetchNextInLine = () => {
        const token = localStorage.getItem('token');
        const selectedStore = JSON.parse(localStorage.getItem('selectedStore'));
        const selectedStoreId = selectedStore ? selectedStore.id : null; 

        if (!selectedStoreId) {
            console.error('Selected store ID missing.');
            return;
        }

        fetch(`${process.env.REACT_APP_API_BASE_URL}/users/queue/`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Selected-Store-Id': selectedStoreId
            }
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            if (data.names && data.names.length > 0) {
                this.setState({ nextInLine: data.names }, () => {
                    console.log("Updated State:", this.state.nextInLine);
                });
            } else {
                this.setState({ nextInLine: ['No Wait!'] });
            }
        })
        .catch(error => {
            console.error('Error fetching next person.', error);
        });
    };

    render() {
        const logoStyle = {
            transform: `scale(${this.state.logoSizeMultiplier || 1})`
        };
        const [firstPerson, ...remaining] = this.state.nextInLine;
        return (
            <div className="queue-background" style={{ backgroundImage: `url(${this.state.backgroundURL})` }}>
                
                {this.state.logoURL && (
                <div className="queue-logo-container" style={logoStyle}>
                    <img src={this.state.logoURL} alt="Logo" className="queue-store-logo" />
                </div>
            )}

                <div className="heading-spacer">
                <h1 className="next-in-line-header" style={{ color: this.state.primaryTextColor }}>{this.state.NextInLineText}</h1>
                    <div className="first-person">
                        <span style={{ color: this.state.primaryTextColor }}>{firstPerson}</span>
                    </div>
                    <div className="remaining-names" style={{ color: this.state.secondaryTextColor }}>
                        {remaining.join(', ')}
                    </div>
                </div>
            </div>
        );
    }
}

export default Queue;
