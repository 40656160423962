import React, { useState, useEffect } from 'react';
import DutchieDealsModal from './DutchieDealsModal';
import './EditDealSheetModal.css';
import DraggableDeal from './DraggableDeal';
import { useDrag, useDrop } from 'react-dnd';
import ConfirmationModal from './ConfirmationModal';

function EditDealSheetModal({ onClose, dealSheet, categories: initialCategories, selectedStore }) {

    //console.log('Initial Categories:', initialCategories);
    const [loading, setLoading] = useState(false);

    const [isDutchieModalVisible, setDutchieModalVisible] = useState(false);
    const [activeCategory, setActiveCategory] = useState(null);
    const [linkeddeals, setlinkeddeals] = useState(() => {
        const linked = {};
    
        if (initialCategories && initialCategories.length) {
            initialCategories.forEach(category => {
                linked[category.name] = category.linkeddeals || [];
            });
        }
    
        return linked;
    });

    const [dealSheetName, setDealSheetName] = useState(dealSheet.name || "");

    const [selectedFiles, setSelectedFiles] = useState([]);

    const [mobileBanner, setMobileBanner] = useState(null);
    const [desktopBanner, setDesktopBanner] = useState(null);
    const [backgroundImages, setBackgroundImages] = useState(dealSheet.background_images || []);
    const [selectedBgImageIndex, setSelectedBgImageIndex] = useState(0);
    const [primaryColor, setPrimaryColor] = useState(
        backgroundImages[0]?.primary_color || '#ffffff'
    );
    const [secondaryColor, setSecondaryColor] = useState(
        backgroundImages[0]?.secondary_color || '#000000'
    );
    const [dealSheetFooter, setDealSheetFooter] = useState(dealSheet.footer || "");

    const [discountSupplyInfo, setDiscountSupplyInfo] = useState(null);

    const [showAssetSettings, setShowAssetSettings] = useState(false);

    const [showSyncMessage, setShowSyncMessage] = useState(false);


    const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);
        // This function will be called when the user confirms the action
    const handleConfirmClose = () => {
        setConfirmModalOpen(false); // Close the confirmation modal
        onClose(); // Call the original onClose handler
    };

    // Modify this function to open the confirmation modal
    const handleAttemptClose = () => {
        setConfirmModalOpen(true); // Show the confirmation modal instead of directly closing
    };
    

    // Initialize categories
    const [categories, setCategories] = useState(() => {
        const cats = [];

        if (initialCategories && initialCategories.length) {
            initialCategories.forEach(category => {
                const allDeals = category.linkeddeals || [];
                cats.push({
                    ...category,
                    deals: [...allDeals, { description: '' }]
                });
            });
        } else {
            cats.push({ deals: [{ description: '' }] });
        }

        return cats;
    });

    const handleAddCategory = () => {
        setCategories([...categories, { deals: [''] }]);
    };

    const handleDeleteCategory = (categoryIndex) => {
        const newCategories = [...categories];
        newCategories.splice(categoryIndex, 1);
        setCategories(newCategories);
    };

    const handleDealChange = (e, categoryIndex, index) => {
        const newCategories = [...categories];
    
        if (typeof newCategories[categoryIndex].deals[index] === 'string' || !newCategories[categoryIndex].deals[index].description) {
            newCategories[categoryIndex].deals[index] = { description: e.target.value };
        } else {
            newCategories[categoryIndex].deals[index].description = e.target.value;
        }
    
        // Remove any empty deals except for the last one
        newCategories[categoryIndex].deals = newCategories[categoryIndex].deals.filter((deal, idx) => 
            (deal.description && deal.description.trim() !== '') || idx === newCategories[categoryIndex].deals.length - 1
        );
    
        // Auto-append a new deal input if it's the last deal being typed into
        if (index === newCategories[categoryIndex].deals.length - 1 && e.target.value.trim() !== '') {
            newCategories[categoryIndex].deals.push({ description: '' });
        }
    
        setCategories(newCategories);
    };

    const handleCategoryNameChange = (e, categoryIndex) => {
        const newCategories = [...categories];
        newCategories[categoryIndex].name = e.target.value;
        setCategories(newCategories);
    };

    const handleBackgroundImageChange = (e) => {
        const files = e.target.files;
        setSelectedFiles(files);
    };
    

    const handleBgSelectionChange = (e) => {
        const selectedIndex = Number(e.target.value);
        setSelectedBgImageIndex(selectedIndex);
        
        const selectedBg = backgroundImages[selectedIndex];
        if (selectedBg) {
            setPrimaryColor(selectedBg.primary_color || '#ffffff');  // default to white if undefined
            setSecondaryColor(selectedBg.secondary_color || '#000000');  // default to black if undefined
        }
    };

    const handlePrimaryColorChange = (e) => {
        const newColor = e.target.value;
        setPrimaryColor(newColor);
    
        const newBackgroundImages = [...backgroundImages];
        if (newBackgroundImages[selectedBgImageIndex]) {
            newBackgroundImages[selectedBgImageIndex].primary_color = newColor;
            setBackgroundImages(newBackgroundImages);
        }
    };
    
    const handleSecondaryColorChange = (e) => {
        const newColor = e.target.value;
        setSecondaryColor(newColor);
    
        const newBackgroundImages = [...backgroundImages];
        if (newBackgroundImages[selectedBgImageIndex]) {
            newBackgroundImages[selectedBgImageIndex].secondary_color = newColor;
            setBackgroundImages(newBackgroundImages);
        }
    };

    const uploadFile = async (file) => {
        const formData = new FormData();
        formData.append('file', file);
    
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/upload_file/`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: formData
            });
    
            const data = await response.json();
    
            return data.fileURL;  // Return the URL of the uploaded file
        } catch (error) {
            console.error("Error uploading file:", error);
            return null;
        }
    };

    const handleSave = async () => {

        setLoading(true);

        const allCategoriesNamed = categories.every(category => category.name && category.name.trim() !== '');

        if (!allCategoriesNamed) {
            alert("Please enter a name for each category before saving the sheet.");
            setLoading(false);
            return; // Stop the save function if any category is missing a name
        }

        let mobileBannerUrl = dealSheet.mobile_banner;
        let desktopBannerUrl = dealSheet.desktop_banner;
        let backgroundImagesUrls = dealSheet.background_images || [];
        let backgroundImagesData = [...backgroundImages];

        // First, upload existing backgroundImages that don't have a URL
        for (let i = 0; i < backgroundImages.length; i++) {
            if (typeof backgroundImages[i] === 'object' && !backgroundImages[i].url.startsWith('http')) {
                const imageUrl = await uploadFile(backgroundImages[i].url);
                backgroundImagesData[i].url = imageUrl;
            }
        }
    
        if (mobileBanner) {
            mobileBannerUrl = await uploadFile(mobileBanner);
        }
    
        if (desktopBanner) {
            desktopBannerUrl = await uploadFile(desktopBanner);
        }
    
        if (backgroundImages && backgroundImages.length > 0) {
            for (let i = 0; i < backgroundImages.length; i++) {
                if (typeof backgroundImages[i] === 'object' && !backgroundImages[i].url.startsWith('http')) {
                    // Assuming the image needs to be uploaded if it doesn't start with http
                    const imageUrl = await uploadFile(backgroundImages[i].url);
                    backgroundImagesData[i].url = imageUrl;
                }
            }
        }
    
        const dealSheetData = {
            ...dealSheet,
            ...(mobileBanner && { mobile_banner: mobileBannerUrl }),
            ...(desktopBanner && { desktop_banner: desktopBannerUrl }),
            background_images: backgroundImagesData
        };
    

        
        const categoriesToSave = categories.map(category => {
            const allDeals = category.deals.filter(deal => deal.description && deal.description.trim() !== '');
        
            return {
                ...(category.id ? { id: category.id } : {}),  // Include category ID if it exists
                ...category,
                linkeddeals: allDeals
            };
        });
    
        

            // Then, upload and append new background images from selectedFiles
        const newBackgroundImages = await Promise.all([...selectedFiles].map(async file => {
            const uploadedImageUrl = await uploadFile(file);
            if (uploadedImageUrl) {
                return {
                    url: uploadedImageUrl,
                    primary_color: '#ffffff',  // default white
                    secondary_color: '#000000'  // default black
                };
            }
            return null;
        }));
        backgroundImagesData = [...backgroundImagesData, ...newBackgroundImages.filter(Boolean)];
        setBackgroundImages(prevBackgroundImages => [...prevBackgroundImages, ...newBackgroundImages.filter(Boolean)]);
        //console.log(backgroundImagesData);

        const updatedDealSheetData = {
            ...dealSheet,
            ...(mobileBanner && { mobile_banner: mobileBannerUrl }),
            ...(desktopBanner && { desktop_banner: desktopBannerUrl }),

            footer: dealSheetFooter,
            background_images: backgroundImagesData,
            categories: categoriesToSave,
            name: dealSheetName,
        };
        
        const apiEndpoint = `${process.env.REACT_APP_API_BASE_URL}/users/deal-sheets/${dealSheet.id}/?store_id=${selectedStore.id}`;
        //console.log('Updated Deal Sheet Data:', updatedDealSheetData);

        fetch(apiEndpoint, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify(updatedDealSheetData)  // Send the updated deal sheet data
        })
        .then(response => response.json())
        .then(data => {
            //console.log('Deal sheet updated:', data);
            onClose(data);  // Pass the updated deal sheet back
            setLoading(false); 
        })
        .catch(error => {
            console.error("Error updating deal sheet:", error);
            setLoading(false); // Hide the spinner on error
        });
        
    };

    const handleMoveCategoryUp = (index) => {
        if (index <= 0) return; // Can't move the first item up
        const newCategories = [...categories];
        const temp = newCategories[index];
        newCategories[index] = newCategories[index - 1];
        newCategories[index - 1] = temp;
        setCategories(newCategories);
    };
    
    const handleMoveCategoryDown = (index) => {
        if (index >= categories.length - 1) return; // Can't move the last item down
        const newCategories = [...categories];
        const temp = newCategories[index];
        newCategories[index] = newCategories[index + 1];
        newCategories[index + 1] = temp;
        setCategories(newCategories);
    };
    

    const handleDutchieModalOpen = (category) => {
        //console.log("Opening Dutchie modal for category:", category.name, "with deals:", linkeddeals[category.name]);
        setActiveCategory(category);
        setDutchieModalVisible(true);
    };
    
    const handleDutchieModalClose = (selectedDutchieDeals) => {
        if (selectedDutchieDeals && activeCategory) {
            const updatedCategories = [...categories];
            const categoryIndex = updatedCategories.findIndex(cat => cat.name === activeCategory.name);
            
            if (categoryIndex !== -1) {
                // Extract the user-entered deals
                const userEnteredDeals = updatedCategories[categoryIndex].deals.filter(deal => !deal.isDutchieDeal);
        
                // Merge the selected Dutchie deals with user-entered deals
                updatedCategories[categoryIndex].deals = [
                    ...selectedDutchieDeals,
                    ...userEnteredDeals
                ];
                
                setCategories(prevCategories => updatedCategories);
        
                // Update the linkeddeals state
                setlinkeddeals(prevlinkeddeals => ({
                    ...prevlinkeddeals,
                    [activeCategory.name]: [...selectedDutchieDeals, ...userEnteredDeals]
                }));
            }
            setShowSyncMessage(true);
            setTimeout(() => setShowSyncMessage(false), 8000);
        }
        setActiveCategory(null);
        setDutchieModalVisible(false);
    };

    useEffect(() => {
        // Ensure all items in backgroundImages are in the correct structure
        const migratedBackgroundImages = (dealSheet.background_images || []).map(bg => {
            if (typeof bg === 'string') {  // If it's a URL string
                return {
                    url: bg,
                    primary_color: '#ffffff',  // default white
                    secondary_color: '#000000'  // default black
                };
            }
            return bg;  // If it's already in the correct structure
        });
        setBackgroundImages(migratedBackgroundImages);
    }, []); // Empty dependency array ensures this runs only once

    useEffect(() => {
        // Extract discount IDs from the dealSheet prop
        const discountIDs = [];
        dealSheet.categories.forEach(category => {
            category.linkeddeals.forEach(deal => {
                if (deal.isDutchieDeal && deal.discountId) {
                    discountIDs.push(deal.discountId);
                }
            });
        });

        if (discountIDs.length > 0) {
            // Make the API call
            fetch(`${process.env.REACT_APP_API_BASE_URL}/users/get_discount_supply_info/?discountIDs=${discountIDs.join(",")}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Selected-Store-Id': selectedStore.id
                }
            })
            .then(response => response.json())
            .then(data => {
                setDiscountSupplyInfo(data.discountInfo);
            })
            .catch(error => console.error("Error fetching discount supply info:", error));
        }
    }, [dealSheet, selectedStore.id]);

    

    const handleMoveDeal = (categoryIndex, fromIndex, toIndex) => {
        const newCategories = [...categories];
        const [movedDeal] = newCategories[categoryIndex].deals.splice(fromIndex, 1);
        newCategories[categoryIndex].deals.splice(toIndex, 0, movedDeal);
        setCategories(newCategories);
    };

    const handleDeleteDeal = (categoryIndex, dealIndex) => {
        const updatedCategories = [...categories];
        const deletedDeal = updatedCategories[categoryIndex].deals[dealIndex];
        updatedCategories[categoryIndex].deals.splice(dealIndex, 1);
    
        // If the deal is a Dutchie deal, remove it from the linkeddeals state
        if (deletedDeal.isDutchieDeal) {
            const categoryName = updatedCategories[categoryIndex].name;
            const updatedlinkeddealsForCategory = linkeddeals[categoryName].filter(deal => deal.discountId !== deletedDeal.discountId);
            setlinkeddeals(prevlinkeddeals => ({
                ...prevlinkeddeals,
                [categoryName]: updatedlinkeddealsForCategory
            }));
        }
    
        setCategories(updatedCategories);
    };

    const handleRemoveBackgroundImage = () => {
        const newBackgroundImages = [...backgroundImages];
        newBackgroundImages.splice(selectedBgImageIndex, 1);
        setBackgroundImages(newBackgroundImages);
    
        // Reset selected index and colors if there are remaining images
        if (newBackgroundImages.length > 0) {
            setSelectedBgImageIndex(0);
            setPrimaryColor(newBackgroundImages[0].primary_color || '#ffffff');
            setSecondaryColor(newBackgroundImages[0].secondary_color || '#000000');
        } else {
            setSelectedBgImageIndex(-1); // No images left
            setPrimaryColor('#ffffff');
            setSecondaryColor('#000000');
        }
    };
    
    

    return (
        <>
        <div className='modal-wrapper'>
            <div className="sheet-modal-content store-modal-content">
                <div className="modal-header">
                    <input
                        className='sheet-name-input'
                        type="text"
                        title='Name this dealsheet'
                        placeholder="Deal Sheet Name"
                        value={dealSheetName}
                        onChange={(e) => setDealSheetName(e.target.value)} 
                    />
                    {showSyncMessage && (
                        <span className="sync-message">The dealsheet may need to be saved and re-opened to sync data from new deals</span>
                    )}
                    {/*
                    <button className="modal-button image-settings" onClick={() => setShowAssetSettings(!showAssetSettings)}>Image Settings 
                    <i class="fa fa-picture-o" aria-hidden="true"></i>
                    

                    </button>
                    */}
                    <button className="modal-button add-category" onClick={handleAddCategory}>
                        Add Category
                    </button>
                </div>
                <div className={showAssetSettings ? 'asset-settings' : 'asset-settings hidden'}>
                    <div className="image-upload-section-container">
                        <div className='image-upload-section'>
                        <label className='bold'>Dealsheet Header:</label>
                        <label>1920w x 200h</label>
                        <input type="file" title='Dimensions: 1920w x 200h (Upload A New Image To Change)' onChange={e => setMobileBanner(e.target.files[0])} />
                        </div>
                        <div className='image-upload-section'>
                        <label className='bold'>Custom Header:</label>
                        <label>1920w x ANY h</label>
                        <input type="file" title='Dimensions: 1920w x 100h (Upload A New Image To Change)' onChange={e => setDesktopBanner(e.target.files[0])} />
                        </div>
                        <div className='image-upload-section'>
                        <label className='bold'>Background Images:</label>
                        <label>1920w x 1080h</label>
                        <input type="file" title='Dimensions: 1920w x 1080h (Multiple Images May Be Uploaded)' multiple onChange={handleBackgroundImageChange} />
                        </div>
                    </div>
                    {backgroundImages.length > 0 ? (
                    <div className="background-color-selector image-upload-section">
                        
                        <label className='bold'>Select a Background Image to Change Its Text Colors</label>
                        <label>(Each Image Can Have Unique Colors)</label>
                        <select value={selectedBgImageIndex} onChange={handleBgSelectionChange} title='Select a Background Image to Change Its Text Colors(Each Image Can Have Unique Colors)'>
                            {backgroundImages.map((bg, idx) => (
                                <option key={idx} value={idx}>
                                    {bg && bg.url ? bg.url.split('/').pop() : 'Unnamed'}
                                </option>
                            ))}
                        </select>
                        <button onClick={handleRemoveBackgroundImage} className="remove-bg-button">
                            Remove Selected Background
                        </button>
                        <label>Category Text Color:</label>
                        <input type="color" value={primaryColor} onChange={handlePrimaryColorChange} />
                        <label>Deals Text Color:</label>
                        <input type="color" value={secondaryColor} onChange={handleSecondaryColorChange} />
                        
                    </div>
                    ) : (
                        <p>No background images available. Upload to add.</p>
                    )}
                    

                </div>
                
        
                <div className={`categories-scrollable ${showAssetSettings ? 'hidden' : ''}`}>
                    {categories.map((category, categoryIndex) => (
                        <div key={categoryIndex} className="category-container">
                            <div className="category-header">
                                <input 
                                    className="category-name" 
                                    placeholder="Category Name" 
                                    value={category.name}
                                    onChange={(e) => handleCategoryNameChange(e, categoryIndex)} 
                                />
                                <span className="deals-counter">Counter: {category.deals.length-1} Lines</span>
                                <div>
                                <button className='updownarrows' onClick={() => handleMoveCategoryUp(categoryIndex)}>
                                <i class="fa fa-chevron-up scroll-btns" aria-hidden="true"></i>


                                </button>
                                <button className='updownarrows' onClick={() => handleMoveCategoryDown(categoryIndex)}>
                                <i class="fa fa-chevron-down scroll-btns" aria-hidden="true"></i>

                                </button>
                                <button className="btn-sync" onClick={() => handleDutchieModalOpen(category)}>
                                <i class="fa fa-refresh" aria-hidden="true"></i> Sync

                                </button>
                                <button className="btn-delete-category" onClick={() => handleDeleteCategory(categoryIndex)}>
                                <i class="fa fa-trash-o" aria-hidden="true"></i> Delete
                                </button>
                                </div>
                            </div>
                            

                            <div className="deals-container">
                                {category.deals.map((deal, index) => {
                                    const dealInfo = discountSupplyInfo && discountSupplyInfo[deal.discountId];
                                    const maxHitsInCategory = Math.max(...category.deals.map(d => discountSupplyInfo?.[d.discountId]?.UsedWeek || 0));
                                    return (
                                        <DraggableDeal
                                            key={deal.discountId || `${categoryIndex}-${index}`} 
                                            index={index}
                                            deal={deal}
                                            dealInfo={dealInfo}
                                            moveDeal={(fromIndex, toIndex) => handleMoveDeal(categoryIndex, fromIndex, toIndex)}
                                            categoryIndex={categoryIndex}
                                            handleDealChange={handleDealChange}
                                            maxHitsInCategory={maxHitsInCategory}
                                            onDelete={() => handleDeleteDeal(categoryIndex, index)}
                                            
                                        />
                                    );
                                })}
                            </div>
                        </div>
                    ))}
                    
                </div>
                
                <textarea 
                    placeholder="Enter footer..." 
                    value={dealSheetFooter}
                    onChange={(e) => setDealSheetFooter(e.target.value)}
                    className='modal-footer-textarea'>
                </textarea>
                <div className="modal-footer modal-footer-buttons">
                    <button className="modal-button save" onClick={handleSave}>
                    <i class="fa fa-floppy-o" aria-hidden="true"></i> Save
                    </button>
                    <button className="modal-button close" onClick={handleAttemptClose}>Close</button>
                </div>
            </div>
            <ConfirmationModal
                isOpen={isConfirmModalOpen}
                onClose={() => setConfirmModalOpen(false)}
                onConfirm={handleConfirmClose}
                title="Discard changes?"
                message="Are you sure you want to discard your changes?"
                confirmButtonText="Discard"
                cancelButtonText="Cancel"
                />

            {isDutchieModalVisible && (
                <>
                    <div className="dutchie-modal-background" onClick={() => handleDutchieModalClose()}></div>
                    {
                        //console.log("Linked Deals:", linkeddeals, "Active Category:", activeCategory.name)
                    }
                    <DutchieDealsModal 
                        category={activeCategory} 
                        onClose={handleDutchieModalClose} 
                        selectedDealsFromParent={linkeddeals[activeCategory.name]?.filter(deal => deal.isDutchieDeal) || []} 
                    />
                </>
            )}

            {loading && (
                <div className="dealsheet-saving-overlay">
                    <div className="dealsheet-saving-spinner"></div>
                    <p className="dealsheet-saving-text">Saving, please wait...</p>
                </div>
            )}

            
        </div>
    </>
    );
}

export default EditDealSheetModal;
