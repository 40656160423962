import React, { useState, useEffect } from 'react';
import QRCode from 'qrcode.react';
import { Link } from 'react-router-dom';
import './ProfileManager.css';

function ProfileManager() {
    // User profile state
    const [profile, setProfile] = useState({
        firstName: 'John',
        lastName: 'Doe',
        email: 'johndoe@example.com',
        store: 'Awesome Store',
    });


    // State for managing fetched users
    const [users, setUsers] = useState([]);

    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        // Fetch associated users for all stores based on the selected store ID
        const selectedStore = JSON.parse(localStorage.getItem('selectedStore'));
        const selectedStoreId = selectedStore && selectedStore.id;
        console.log(selectedStoreId)
        if (selectedStoreId) {
            fetch(`${process.env.REACT_APP_API_BASE_URL}/users/stores/${selectedStoreId}/associated-users`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            })
            .then(res => res.json())
            .then(data => {
                setUsers(data.users);  // Assuming the backend sends the users under 'users' key
                console.log("User fetch response: ", data);
            })
            .catch(error => {
                console.error("Error fetching users:", error);
            });
        }
    }, []);

    const filteredUsers = users.filter(user =>
        user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.email.toLowerCase().includes(searchTerm.toLowerCase())
    );

    function handleSearchChange(event) {
        setSearchTerm(event.target.value);
    }

    function handleEdit(userId) {
        // Redirect to an edit page, or open a modal, or any other logic you'd like
        window.location.href = `/edit-user?userId=${userId}`;
    }

    // QR code generation state
    const [code, setCode] = useState(null);
    const [loading, setLoading] = useState(false);
    const registrationURL = code ? `https://www.greenmind.studio/signup?code=${code}` : '';

    async function fetchQRCode() {
        setLoading(true);
        const token = localStorage.getItem('token');

        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/generate-qr-invite/`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
            });

            const data = await response.json();
            setCode(data.code);
        } catch (error) {
            console.error("Error fetching QR code:", error);
        } finally {
            setLoading(false);
        }
    }

    return (
        <div className='pagewrapper'>
        <div className='background-wrapper'>
            <div>
                <Link to="/dashboard">
                    <img src="https://d27oebqltxtb0s.cloudfront.net/GreenMindSimpleLogo.png" alt="Logo" className="dash-logo pushnext" />
                </Link>
            </div>
        <div className="profile-manager">

            <h2>Invite New Employee</h2>
            {loading ? (
                <p>Loading...</p>
            ) : (
                <>
                    <button className="profile-button" onClick={fetchQRCode}>Generate QR Code</button>
                    {code && (
                        <div className="qr-display">
                            <QRCode value={registrationURL} />
                            <p>Link: {registrationURL}</p>
                        </div>
                    )}
                </>
            )}
            <h2>User Profiles</h2>
            <div className="search-bar">
                <input
                    type="text"
                    placeholder="Search by name or email"
                    value={searchTerm}
                    onChange={handleSearchChange}
                />
            </div>
            <div className="user-manager">
            {filteredUsers.map(user => (
                <div key={user.id} className="user-details">
                    <h3>{user.name}</h3>
                    <p><strong>Email:</strong> {user.email}</p>
                    <button onClick={() => handleEdit(user.id)}>Edit</button>
                    {/* Add more user fields if needed */}
                </div>
            ))}
            </div>
        </div>
        </div>
        </div>
    );
}

export default ProfileManager;
