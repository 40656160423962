// wizardSteps/SyncOptionsStep.js
import React, { useState, useEffect } from 'react';

const SyncOptionsStep = ({ asset, setAsset, slideshows }) => {
    const [startDate, setStartDate] = useState(asset.syncStartDate || '');
    const [endDate, setEndDate] = useState(asset.syncEndDate || '');
    const [startTime, setStartTime] = useState(asset.syncDetails?.startTime || '00:00');
    const [endTime, setEndTime] = useState(asset.syncDetails?.endTime || '00:00');
    const [durationDays, setDurationDays] = useState(asset.syncDetails?.durationDays || 7);
    const [daysOffset, setDaysOffset] = useState(asset.syncDetails?.daysOffset || 0);
    const [smartScheduleEnabled, setSmartScheduleEnabled] = useState(asset.smartScheduleEnabled || false);


    const initialSlideshow = slideshows.find(s => s.id === asset.syncedSlideshowId) || null;
    const [selectedSlideshow, setSelectedSlideshow] = useState(initialSlideshow);
    // Other state initializations remain the same

    // Adjust the `onChange` handler for the dropdown
    const handleSlideshowChange = (e) => {
        const slideshowId = e.target.value;
        const slideshow = slideshows.find(s => s.id.toString() === slideshowId);
        setSelectedSlideshow(slideshow); // Store the entire slideshow object
    };

    // Initialize with a one-year period or adjust based on SmartSchedule
    useEffect(() => {
        if (!smartScheduleEnabled) {
            const now = new Date();
            // Use now to set the start date
            setStartDate(formatLocalDateTime(now));
    
            // Create a new Date object for oneYearLater to avoid mutating now
            const oneYearLater = new Date();
            oneYearLater.setFullYear(oneYearLater.getFullYear() + 1);
            setEndDate(formatLocalDateTime(oneYearLater)); // One year later as end
        } else {
            // Adjust start and end dates based on SmartSchedule settings
            const startDateTime = calculateStartDateTime(daysOffset, startTime);
            const endDateTime = calculateEndDateTime(startDateTime, durationDays, endTime);
            setStartDate(startDateTime);
            setEndDate(endDateTime);
        }
    }, [smartScheduleEnabled, daysOffset, startTime, durationDays, endTime]);
    

    // Utility functions to format and calculate date-times
    const formatLocalDateTime = (date) => {
        return date.toISOString().split('T')[0] + 'T' + 
               ('0' + date.getHours()).slice(-2) + ':' + 
               ('0' + date.getMinutes()).slice(-2);
    };

    const calculateStartDateTime = (offsetDays, time) => {
        const [hours, minutes] = time.split(':').map(Number);
        const startDate = new Date();
        startDate.setDate(startDate.getDate() + parseInt(offsetDays, 10));
        startDate.setHours(hours, minutes);
        return formatLocalDateTime(startDate);
    };

    const calculateEndDateTime = (startDateTime, durationDays, time) => {
        const [hours, minutes] = time.split(':').map(Number);
        const endDate = new Date(startDateTime);
        endDate.setDate(endDate.getDate() + parseInt(durationDays, 10));
        endDate.setHours(hours, minutes);
        return formatLocalDateTime(endDate);
    };

    const toggleSync = () => {
        setAsset({
            ...asset,
            shouldSync: !asset.shouldSync,
            syncedSlideshowId: selectedSlideshow,
            syncStartDate: startDate,
            syncEndDate: endDate,
            syncDetails: { startTime, endTime, durationDays, daysOffset },
            smartScheduleEnabled
        });
    };

    useEffect(() => {
        setAsset({
            ...asset,
            syncedSlideshowId: selectedSlideshow,
            syncStartDate: startDate,
            syncEndDate: endDate,
            syncDetails: { startTime, endTime, durationDays, daysOffset },
            smartScheduleEnabled
        });
    }, [selectedSlideshow, startDate, endDate, startTime, endTime, durationDays, daysOffset, smartScheduleEnabled]);

    // When the selected slideshow changes, update the asset state
    useEffect(() => {
        if (asset.shouldSync) {
            setAsset({
                ...asset,
                syncedSlideshowId: selectedSlideshow ? selectedSlideshow.id : '',
                syncedSlideshow: selectedSlideshow,
                syncStartDate: startDate,
                syncEndDate: endDate,
                syncDetails: { startTime, endTime, durationDays, daysOffset },
                smartScheduleEnabled
            });
        }
    }, [selectedSlideshow, startDate, endDate, startTime, endTime, durationDays, daysOffset, smartScheduleEnabled]);

    return (
        <div>
            <h2>Sync Options</h2>
            <label>
                <input type="checkbox" checked={asset.shouldSync} onChange={toggleSync} />
                Enable Sync To TV Slideshow
            </label>
            {asset.shouldSync && (
                <>
                    <div className='sync-dropdown'>
                    <select value={selectedSlideshow ? selectedSlideshow.id : ''} onChange={handleSlideshowChange}>
                            <option value="">Select Slideshow</option>
                            {slideshows.map((slideshow) => (
                                <option key={slideshow.id} value={slideshow.id}>{slideshow.name}</option>
                            ))}
                        </select>
                    </div>
                    <div className='sync-dates'>
                        <input type="datetime-local" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                        <input type="datetime-local" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                    </div>
                    <label>
                        <input
                            type="checkbox"
                            checked={smartScheduleEnabled}
                            onChange={(e) => setSmartScheduleEnabled(e.target.checked)}
                        />
                        Enable SmartSchedule
                    </label>
                    {smartScheduleEnabled && (
                        <div>
                            <div>
                                Start in <input type="number" value={daysOffset} onChange={(e) => setDaysOffset(e.target.value)} min="0" /> days at <input type="time" value={startTime} onChange={(e) => setStartTime(e.target.value)} />
                            </div>
                            <div>
                                Play for <input type="number" value={durationDays} onChange={(e) => setDurationDays(e.target.value)} min="1" /> days until <input type="time" value={endTime} onChange={(e) => setEndTime(e.target.value)} />
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default SyncOptionsStep;
