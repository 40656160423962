import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import './CustomerFacingMenu.css';

function mapDiscountsToProducts(products, discounts, selectedStoreId) {
    return products.map(product => {
      const discount = discounts.find(discount => {
        const now = new Date();
        const validFrom = new Date(discount.validFrom);
        const validUntil = new Date(discount.validUntil);
        const isDateValid = validFrom <= now && validUntil >= now;
  
        const isProductSelected = discount.products && discount.products.ids && discount.products.ids.includes(product.productId);
        const isBrandSelected = discount.brands && discount.brands.ids && discount.brands.ids.includes(product.brandId);
        const isCategorySelected = discount.productCategories && discount.productCategories.ids && discount.productCategories.ids.includes(product.categoryId);
  
        if (!discount.products && !discount.brands && !discount.productCategories) {
          return false;
        }
  
        if (discount.products && discount.products.ids && discount.products.ids.length > 0) {
          return isProductSelected && discount.isActive && discount.applicationMethod.toLowerCase().includes('automatic') && isDateValid && (!discount.stores || discount.stores.ids.includes(selectedStoreId));
        }
  
        const isBrandAndCategorySelected = discount.brands && discount.productCategories;
        const isOnlyBrandOrCategorySelected = !discount.brands !== !discount.productCategories;
  
        return discount.isActive && discount.applicationMethod.toLowerCase().includes('automatic') && isDateValid && (!discount.stores || discount.stores.ids.includes(selectedStoreId)) && (
          (isBrandAndCategorySelected && isBrandSelected && isCategorySelected) ||
          (isOnlyBrandOrCategorySelected && (isBrandSelected || isCategorySelected)) ||
          (!discount.brands && !discount.productCategories)
        );
      });
  
      let validityPeriod = null;
      if (discount) {
        const validUntil = new Date(discount.validUntil);
        validityPeriod = `Valid Through: ${validUntil.toLocaleDateString()}`;
      }
  
      return { 
        ...product, 
        isOnSale: !!discount, 
        discountName: discount ? discount.discountName.replace(/[-|]/g, '\n') : null,
        discountAmount: discount ? discount.discountAmount : 0,
        discountMethod: discount ? discount.discountMethod : 'percent off',
        minimumItemsRequired: discount ? discount.minimumItemsRequired : 1,
        discountType: discount ? discount.discountType : '',
        validityPeriod,
      };
    });
  }
  

  function checkScrollableDirections(productRow) {
    const leftArrow = productRow.querySelector('.left-arrow');
    const rightArrow = productRow.querySelector('.right-arrow');
    
    if (productRow.scrollLeft > 0) {
      leftArrow.style.opacity = 1;
    } else {
      leftArrow.style.opacity = 0;
    }
  
    if (productRow.scrollLeft < productRow.scrollWidth - productRow.clientWidth) {
      rightArrow.style.opacity = 1;
    } else {
      rightArrow.style.opacity = 0;
    }
  }
  

  function handleScroll(e) {
    checkScrollableDirections(e.target);
  }


  function LoadingModal() {
    return (
        <div className="loading-modal">
            <div className="loading-message">
                <p>Loading, please wait...</p>
            </div>
        </div>
    );
}















function CustomerFacingMenu() {
    const [data, setData] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedCategoryOrBrand, setSelectedCategoryOrBrand] = useState(null);
    const selectedStore = JSON.parse(localStorage.getItem('selectedStore'));
    const selectedStoreId = selectedStore ? selectedStore.id : null;
    const [discounts, setDiscounts] = useState([]);

    const mappedProducts = mapDiscountsToProducts(data, discounts, selectedStoreId);

    const [backgroundURL, setBackgroundURL] = useState(null);
    const [sortPreference, setSortPreference] = useState('category');
    const [isLoading, setIsLoading] = useState(true);



    useEffect(() => {
        setIsLoading(true);
        fetch(`${process.env.REACT_APP_API_BASE_URL}/users/get-inventory/`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'Selected-Store-Id': selectedStoreId
            }
        })
        .then(res => res.json())
        .then(fetchedData => {
            const uniqueProducts = [];
            const seenProductIds = new Set();

            for (const product of fetchedData) {
                if (!seenProductIds.has(product.productId)) {
                    seenProductIds.add(product.productId);
                    uniqueProducts.push(product);
                }
            }

            setData(uniqueProducts);
        })
        .catch(error => console.error("Error fetching the inventory data:", error));

            // Fetch discount data
        fetch(`${process.env.REACT_APP_API_BASE_URL}/users/get-all-deals/`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'Selected-Store-Id': selectedStoreId
            }
        })
        .then(res => res.json())
        .then(fetchedDiscounts => setDiscounts(fetchedDiscounts))
        .catch(error => console.error("Error fetching the discount data:", error));


        // Fetch store details for background image
        if (selectedStoreId) {
            fetch(`${process.env.REACT_APP_API_BASE_URL}/users/stores/${selectedStoreId}/store-details/`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            })
            .then(response => response.json())
            .then(data => {
                setBackgroundURL(data.queue_bg_url);
            })
            .catch(error => console.error("Error fetching the store details:", error))
            .finally(() => setIsLoading(false));
        }
    }, [selectedStoreId]);

    const productRowRef = useRef(null);

    useLayoutEffect(() => {
    if (productRowRef.current) {
        checkScrollableDirections(productRowRef.current);
    }
    }, [data]);

    const [view, setView] = useState('selection'); // 'selection', 'category', or 'brand'

    const categories = Array.from(new Set(data.map(item => item.category || '').filter(Boolean))).sort((a, b) => a.localeCompare(b));
    const brands = Array.from(new Set(data.map(item => item.brandName || '').filter(Boolean))).sort((a, b) => a.localeCompare(b));

    const groupedByBrand = data.reduce((acc, product) => {
        if (product.brandName) {
        if (!acc[product.brandName]) acc[product.brandName] = [];
        acc[product.brandName].push(product);
        }
        return acc;
    }, {});

    const groupedByCategory = data.reduce((acc, product) => {
        if (product.category) {
        if (!acc[product.category]) acc[product.category] = [];
        acc[product.category].push(product);
        }
        return acc;
    }, {});

    const handleSortPreferenceChange = (preference) => {
        setSortPreference(preference);
        setSelectedCategoryOrBrand(null);  // Reset the selected category or brand
        setView('selection');  // Reset the view to selection
    };

    const categoriesGroupedByMasterCategory = data.reduce((acc, product) => {
        if (product.category && product.masterCategory) {
            if (!acc[product.masterCategory]) acc[product.masterCategory] = new Set();
            acc[product.masterCategory].add(product.category);
        }
        return acc;
    }, {});

    const renderGroupedCategories = () => {
        return Object.entries(categoriesGroupedByMasterCategory).map(([masterCategory, categories]) => (
            <div key={masterCategory} className="master-category-container">
                <div className="master-category-title">{masterCategory}</div>
                <div className="cfd-button-container">
                    {Array.from(categories).sort().map(category => (
                        <button
                            key={category}
                            className="selection-button"
                            onClick={() => {
                            setSelectedCategoryOrBrand(category);
                            setView('products');
                            }}
                        >
                            {category}
                        </button>
                        
                    ))}
                </div>
            </div>
        ));
    };


    const renderProducts = (products) => {
        return products.map(product => {
          const onSaleProduct = mappedProducts.find(p => p.productId === product.productId);
      
          // Replace vertical bars in product names with newlines
          const productNameLines = product.productName.replace(/\|/g, '\n').split('\n');
      
          return (
            <div key={product.productId} className="product">
              {onSaleProduct && onSaleProduct.isOnSale && (
                <div className="discount-container">
                  <div className="sale-banner">ON SALE</div>
                  <div className="discount-name">{onSaleProduct.discountName}</div>
                </div>
              )}
              {product.imageUrl ? 
                <img src={product.imageUrl} alt={product.productName} /> :
                <div className="placeholder-image">No Image</div>
              }
              <h3>
                {productNameLines.map((line, index) => (
                  <div key={index}>{line}</div>
                ))}
              </h3>
              {onSaleProduct && onSaleProduct.isOnSale ? (
                <>
                  <p className="original-price">Price: ${product.unitPrice}</p>
                  <p className="discounted-price">{calculateDiscountedPrice(product.unitPrice, onSaleProduct.discountAmount, onSaleProduct.discountMethod, onSaleProduct.minimumItemsRequired, onSaleProduct.discountType)}</p>
                  <p className="validity-period">{onSaleProduct.validityPeriod}</p>
                </>
              ) : (
                <p>Price: ${product.unitPrice}</p>
              )}
            </div>
          );
        });
      };

      
      const calculateDiscountedPrice = (originalPrice, discountAmount, discountMethod, minimumItemsRequired, discountType) => {
        const originalPriceNumber = parseFloat(originalPrice);
        
        if (isNaN(originalPriceNumber)) {
          console.error('Invalid price:', originalPrice);
          return originalPrice; // Return original price if calculation is not possible
        }
    
        // Check for the special case you mentioned
        if (discountMethod.toLowerCase().includes('percent') && discountType.toLowerCase().includes('percent') && minimumItemsRequired > 1) {
            const discountPercentage = Math.round(discountAmount * 100);
            const formattedDiscount = minimumItemsRequired === 2 ? 'BOGO' : `B${minimumItemsRequired - 1}G1`;
            return `${formattedDiscount} ${discountPercentage === 100 ? 'FREE' : discountPercentage + '% OFF'}`;
      }
      
        if (discountMethod.toLowerCase().includes('flat')) {
          const discountedPrice = originalPriceNumber - discountAmount;
          return `$${discountedPrice.toFixed(2)}`;
        } else if (minimumItemsRequired > 1) {
          if (!discountType.toLowerCase().includes('total amount')) {
            discountAmount = discountAmount * minimumItemsRequired;
          }
          const pricePerItem = discountAmount / minimumItemsRequired;
          return `${minimumItemsRequired} for $${discountAmount.toFixed(0)} ($${pricePerItem.toFixed(0)} ea.)`;
        } else if (discountMethod.toLowerCase().includes('price to amount')) {
          // Set price to the discount amount
          return `$${discountAmount.toFixed(2)}`;
        } else {
          let discountedPricePerItem;
          if (discountMethod.toLowerCase().includes('amount off')) {
            discountedPricePerItem = originalPriceNumber - discountAmount;
          } else if (discountMethod.toLowerCase().includes('percent')) {
            discountedPricePerItem = originalPriceNumber - (originalPriceNumber * discountAmount);
          } else {
            console.error('Invalid discount method:', discountMethod);
            return originalPrice;
          }
          return `$${discountedPricePerItem.toFixed(2)}`;
        }
    };
    
    
    
      
      
      

    const renderSelection = () => (
        <div className="cfd-button-container">
            {(sortPreference === 'category' ? categories : brands).map(item => (
                <button 
                    key={item} 
                    className="selection-button"
                    onClick={() => {
                        setSelectedCategoryOrBrand(item);
                        setView('products');
                    }}
                >
                    {item}
                </button>
            ))}
        </div>
    );
    
    const renderProductsView = () => (
        <div className="products">
            {Object.keys(sortPreference === 'category' ? groupedByBrand : groupedByCategory).map(key => {
                const products = (sortPreference === 'category' ? groupedByBrand : groupedByCategory)[key].filter(item => 
                    (sortPreference === 'category' ? item.category : item.brandName) === selectedCategoryOrBrand
                );
                if (products.length === 0) return null;
                return (
                    <div key={key}>
                        {/* Add onClick handlers to the headers */}
                        {sortPreference === 'category' ? (
                            <h2 className="brand-label" onClick={() => handleBrandHeaderClick(key)}>{key}</h2>
                        ) : (
                            <h2 className="brand-label" onClick={() => handleCategoryHeaderClick(key)}>{key}</h2>
                        )}
                        <div className="product-row" ref={productRowRef} onScroll={handleScroll}>
                            <i className="fas fa-chevron-left arrow left-arrow"></i>
                            {renderProducts(products)}
                            <i className="fas fa-chevron-right arrow right-arrow"></i>
                        </div>
                    </div>
                );
            })}
        </div>
    );

    const renderProductsByBrand = () => {
        const productsForBrand = data.filter(product => product.brandName === selectedCategoryOrBrand);

    // Here, if you have selected a category before, you want to filter the brands within that category
    if (selectedCategory) {
        productsForBrand = productsForBrand.filter(product => product.category === selectedCategory);
    }

    const categoriesForBrand = Array.from(new Set(productsForBrand.map(product => product.category || '').filter(Boolean))).sort((a, b) => a.localeCompare(b));

        return categoriesForBrand.map(category => {
            const uniqueProducts = productsForBrand.filter(product => product.category === category);
            const seenProductIds = new Set();
            const filteredProducts = uniqueProducts.filter(product => {
                if (!seenProductIds.has(product.productId)) {
                    seenProductIds.add(product.productId);
                    return true;
                }
                return false;
            });
    
            if (filteredProducts.length === 0) return null;
    
            return (
                <div key={category}>
                    <h2 className="brand-label" onClick={() => handleCategoryHeaderClick(category)}>{category}</h2>
                    <div className="product-row" ref={productRowRef} onScroll={handleScroll}>
                        <i className="fas fa-chevron-left arrow left-arrow"></i>
                        {renderProducts(filteredProducts)}
                        <i className="fas fa-chevron-right arrow right-arrow"></i>
                    </div>
                </div>
            );
        });
    };
    
    const renderProductsByCategory = () => {
        let productsForCategory = data.filter(product => product.category === selectedCategoryOrBrand);

        // Here, if you have selected a brand before, you want to filter the categories within that brand
        if (selectedCategory) {
            productsForCategory = productsForCategory.filter(product => product.brandName === selectedCategory);
        }

        const brandsForCategory = Array.from(new Set(productsForCategory.map(product => product.brandName || '').filter(Boolean))).sort((a, b) => a.localeCompare(b));

        return brandsForCategory.map(brand => {
            const uniqueProducts = productsForCategory.filter(product => product.brandName === brand);
            const seenProductIds = new Set();
            const filteredProducts = uniqueProducts.filter(product => {
                if (!seenProductIds.has(product.productId)) {
                    seenProductIds.add(product.productId);
                    return true;
                }
                return false;
            });
    
            if (filteredProducts.length === 0) return null;
    
            return (
                <div key={brand}>
                    <h2 className="brand-label" onClick={() => handleBrandHeaderClick(brand)}>{brand}</h2>
                    <div className="product-row" ref={productRowRef} onScroll={handleScroll}>
                        <i className="fas fa-chevron-left arrow left-arrow"></i>
                        {renderProducts(filteredProducts)}
                        <i className="fas fa-chevron-right arrow right-arrow"></i>
                    </div>
                </div>
            );
        });
    };
    
    

    function handleBrandHeaderClick(brand) {
        setSelectedCategoryOrBrand(brand);
        setSortPreference('brand'); // Change sort preference to brand when a brand header is clicked
        setView('productsByBrand'); // Adjust this view to handle rendering products by brand
    }
    
    function handleCategoryHeaderClick(category) {
        setSelectedCategoryOrBrand(category);
        setSortPreference('category'); // Change sort preference to category when a category header is clicked
        setView('productsByCategory'); // Adjust this view to handle rendering products by category
    }
      
    
      return (
        <div style={{ backgroundImage: `url(${backgroundURL})`, backgroundSize: 'cover', backgroundPosition: 'center',overflowX: 'hidden', height: '100vh'}}>
          {isLoading && <LoadingModal />}
    
          <div className="sort-buttons">
                <button className='SortButton' onClick={() => handleSortPreferenceChange('category')}>Sort by Category</button>
                <button className='SortButton' onClick={() => handleSortPreferenceChange('brand')}>Sort by Brand</button>
            </div>
    
          {view === 'productsByBrand' && renderProductsByBrand()}
          {view === 'productsByCategory' && renderProductsByCategory()}
          {view === 'products' && renderProductsView()}
          {view === 'selection' && sortPreference === 'category' && renderGroupedCategories()}
          {view === 'selection' && sortPreference === 'brand' && renderSelection()}
    
          {view === 'products' && (
            <button className="back-button" onClick={() => setView('selection')}>
              Back to {sortPreference === 'category' ? 'Categories' : 'Brands'}
            </button>
          )}
        </div>
      );
    }
    
    export default CustomerFacingMenu; 