import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './StoreSelectionPage.css';

function DeviceSetupPage() {
    const navigate = useNavigate();
    const [deviceName, setDeviceName] = useState('');

    useEffect(() => {
        const selectedDevice = JSON.parse(localStorage.getItem('selectedDevice'));
        if (selectedDevice) {
            switch (selectedDevice.type) {
                case 'slideshow':
                    localStorage.setItem('currentSlideshowId', selectedDevice.target);
                    navigate('/slideshowplayer');
                    break;
                case 'queue':
                    const selectedStore = JSON.parse(localStorage.getItem('selectedStore'));
                    if (selectedStore && selectedStore.id !== selectedDevice.target) {
                        localStorage.setItem('selectedStore', JSON.stringify({ ...selectedStore, id: selectedDevice.target }));
                    }
                    navigate('/queue');
                    break;
                case null:
                    if (selectedDevice.id != null) {
                        navigate('/function-select');
                    }
                    break;
                default:
                    console.log('Unknown device type or no specific action required');
            }
        }
    }, [navigate]);

    const handleDeviceNameChange = (event) => {
        setDeviceName(event.target.value);
    };

    const handleActionButtonClick = async () => {
        if (deviceName.trim()) {
            try {
                const selectedStore = JSON.parse(localStorage.getItem('selectedStore'));
                const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/devices/create`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    },
                    body: JSON.stringify({ name: deviceName, store: selectedStore.id }),
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const newDevice = await response.json();
                localStorage.setItem('selectedDevice', JSON.stringify(newDevice));
                alert('Device created successfully');
                navigate('/function-select');
            } catch (error) {
                console.error("Error creating device:", error);
            }
        } else {
            navigateToSelectDevice();
        }
    };

    const navigateToSelectDevice = () => {
        navigate('/select-device');
    };

    const actionButtonText = deviceName.trim() ? "Create New Device" : "Select Existing Device";

    return (
        <div className="store-selection-page">
            <div className='tv-wrapper'>
                <h2 className="title">Device Setup</h2>
                <input
                    type="text"
                    placeholder="Enter Device Name"
                    className="device-name-input"
                    value={deviceName}
                    onChange={handleDeviceNameChange}
                />
                <div className="vertical-button-container button-container">
                    <button className="setup-button" onClick={handleActionButtonClick}>{actionButtonText}</button>
                </div>
            </div>
        </div>
    );
}

export default DeviceSetupPage;
