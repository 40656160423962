import React from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { useState, useEffect } from 'react';
import './DraggableDeal.css';
import IndividualDealInfoModal from './IndividualDealInfoModal';


const DraggableDeal = ({ deal, dealInfo, index, handleDealChange, categoryIndex, moveDeal, maxHitsInCategory, onDelete }) => {
    const ref = React.useRef(null);

    

    const [isHovered, setIsHovered] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false); // State to manage modal visibility


    const [, drag] = useDrag({
        type: 'DEAL',
        item: { id: deal.discountId, index, categoryIndex }
    });


    const [, drop] = useDrop({
        accept: 'DEAL',
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;

            // Check if the deal belongs to the same category
            if (item.categoryIndex !== categoryIndex) {
                return;  // Do nothing if the deal is from a different category
            }

            if (dragIndex === hoverIndex) {
                return;
            }

            // Move the item
            moveDeal(dragIndex, hoverIndex);

            // Update the index for dragged item directly to avoid expensive index searches
            item.index = hoverIndex;

        }
    });

    drag(drop(ref));

    const openModal = () => {
        setIsModalOpen(true);  // Open the modal
    };

    const closeModal = () => {
        setIsModalOpen(false);  // Close the modal
    };

    const getBackgroundColor = (daysSupply) => {
        if (daysSupply >= 10) {
            return 'green';
        } else if (daysSupply <= 0) {
            return 'red';
        } else {
            const greenRatio = daysSupply / 10;
            const redRatio = 1 - greenRatio;
            const greenValue = Math.floor(255 * greenRatio);
            const redValue = Math.floor(255 * redRatio);
            return `rgb(${redValue}, ${greenValue}, 0)`;
        }
    };

    const getEmoji = (dealInfo) => {
        let emoji = '';
    
        if (dealInfo) {
            if (dealInfo.ExpirationDays !== undefined && dealInfo.ExpirationDays < 3) {
                emoji += '💀 '; // Skull emoji if expiration is less than 3 days
            }
            
            if (dealInfo.UsedWeek >= maxHitsInCategory) {
                emoji += '🔥 ';  // Fire emoji for the deal with the most hits
            } else if (dealInfo.DaysSupply <= 3) {
                emoji += '⚠️ ';
            } else if (dealInfo.UsedWeek < 5) {
                emoji += '❄️ ';  // Snowflake emoji for deals with fewer hits
            }
        }
    
        return emoji;
    };

    const getStatusDisplay = () => {
        // If deal is expired, show only the skull emoji
        if (!dealInfo || dealInfo.DaysSupply === undefined || dealInfo.DaysSupply === null || dealInfo.DaysSupply < 0) {
            return "💀EXPIRED -";  // Skull emoji for expired deals
        }
    
        // No need to show other expiration statuses
        return '';  // Return nothing for deals not expiring soon or expired
    };
    
    


    if (deal.isDutchieDeal) {
        const firstCharOfStatus = dealInfo && dealInfo.ExpirationStatus ? dealInfo.ExpirationStatus.charAt(0) : ''
        return (
            <div 
                ref={ref}
                key={deal.discountId} 
                className="linked-deal flex-container" 
                style={{
                    position: 'relative',
                    backgroundColor: dealInfo ? getBackgroundColor(dealInfo.DaysSupply) : 'grey',
                }}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                <div>
                {dealInfo && (
                    <i 
                        className="fa fa-terminal"
                        style={{
                            position: 'absolute',
                            top: '10px',
                            left: '10px',
                            cursor: 'pointer',
                            color: 'black'
                        }}
                        onClick={openModal}  // Open modal on click
                    ></i>
                )}

                </div>
                
                {getEmoji(dealInfo)}{getStatusDisplay()}{deal.description}
                {!dealInfo && (
                    <div className="loading-wave">
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                    </div>
                )}
    
                {/* Display "x" button for isDutchieDeal deals that don't have dealInfo */}
                {1 && (
                    <button 
                        onClick={() => onDelete(deal)}
                        style={{
                            position: 'absolute',
                            top: '50%',
                            right: '10px',
                            transform: 'translateY(-50%)',
                            background: 'red',
                            color: 'white',
                            border: 'none',
                            borderRadius: '50%',
                            width: '20px',
                            height: '20px',
                            cursor: 'pointer',
                            fontSize: '16px',
                            lineHeight: '20px',
                            textAlign: 'center'
                        }}
                    >
                        <i class="fa fa-times" aria-hidden="true"></i>

                    </button>
                )}
    
                {dealInfo && (
                    <>
                        {/*<div className="hover-info">{dealInfo.DaysRunning} Days Active | ${dealInfo.AvgCost.toFixed(2)} Average Cost</div> */}
                        <div className='dealInfo flex-item'>{dealInfo.DaysSupply} Day Supply ({dealInfo.DiscountSupply.toFixed(0)} Ct) | Hits/wk: {dealInfo.UsedWeek}</div>
                        
                    </>
                )}
                {isModalOpen && (
                    <IndividualDealInfoModal 
                        deal={deal}
                        dealInfo={dealInfo}
                        onClose={closeModal}  // Pass the close function
                        maxHitsInCategory={maxHitsInCategory}
                    />
                )}
            </div>
        );
    } else {
        return (
            <input 
                className='manual-deal'
                ref={ref}
                key={index} 
                placeholder="Deal" 
                value={deal.description || ''}
                onChange={(e) => handleDealChange(e, categoryIndex, index)}
            />
        );
    }
};

export default DraggableDeal;