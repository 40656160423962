import React, { useState } from 'react';
import HeaderUploadModal from '../HeaderUploadModal';
import ConfirmationModal from '../ConfirmationModal';

const HeaderSelectionStep = ({ asset, setAsset, headerImages, dealSheetId, addHeaderImage, refreshHeaderImage }) => {
    const [showHeaderModal, setShowHeaderModal] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [selectedHeaderForDeletion, setSelectedHeaderForDeletion] = useState(null);
    const [hoveredItem, setHoveredItem] = useState(null);

    const handleOpenModal = () => setShowHeaderModal(true);

    const handleDeleteClick = (e, image) => {
        e.stopPropagation(); // Prevent the click from selecting the image
        setSelectedHeaderForDeletion(image);
        setShowConfirmationModal(true);
    };

    const handleConfirmDeletion = async () => {
        if (selectedHeaderForDeletion) {
            const headerUrl = selectedHeaderForDeletion; // Assuming selectedHeaderForDeletion holds the URL of the header to be deleted

            try {
                const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/deal-sheets/${dealSheetId}/remove-header/`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    },
                    body: JSON.stringify({ header_url: headerUrl }),
                });

                if (response.ok) {
                    // Successfully removed the header image from the server
                    refreshHeaderImage(headerUrl); // Remove the image from the local state
                    setShowConfirmationModal(false);
                } else {
                    throw new Error('Failed to remove header image');
                }
            } catch (error) {
                console.error('Error removing header image:', error);
                alert('An error occurred while removing the header image.');
            }
        }
    };

    const isSelected = (image) => asset.header === image;
    const extractFilename = (url) => url.split('/').pop();

    return (
        <div>
            <h2>Select Header</h2>
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                <button onClick={handleOpenModal} style={{ border: '2px dashed gray', borderRadius: '5px', padding: '2px', display: 'flex', justifyContent: 'center', alignItems: 'center', width: 100, height: 100 }}>
                    Upload New Header
                </button>
                <div
                    onClick={() => setAsset({ ...asset, header: null })}
                    style={{
                        border: asset.header === null ? '2px solid black' : '2px solid transparent',
                        borderRadius: '5px',
                        padding: '2px',
                        width: '100px',
                        height: '100px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        background: '#eee',
                        cursor: 'pointer'
                    }}
                >
                    No Header
                </div>
                {headerImages.map((image, index) => (
                    <div
                        key={index}
                        onClick={() => setAsset({ ...asset, header: image })}
                        onMouseEnter={() => setHoveredItem(index)}
                        onMouseLeave={() => setHoveredItem(null)}
                        style={{
                            border: isSelected(image) ? '2px solid black' : 'none',
                            borderRadius: '5px',
                            padding: '2px',
                            width: '100px',
                            height: '100px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            overflow: 'hidden',
                            position: 'relative',
                        }}
                    >
                        <img
                            src={image}
                            alt={`Header ${index}`}
                            style={{
                                maxWidth: '100%',
                                maxHeight: '100%',
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: hoveredItem === index ? 'translate(-50%, -50%) scale(3.5)' : 'translate(-50%, -50%) scale(1)',
                                transition: 'transform 0.3s ease',
                            }}
                        />
                        <div
                            onClick={(e) => handleDeleteClick(e, image)}
                            style={{
                                position: 'absolute',
                                top: 0,
                                right: 0,
                                background: 'red',
                                borderRadius: '50%',
                                width: '20px',
                                height: '20px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                color: 'white',
                                cursor: 'pointer',
                            }}
                        >
                            ×
                        </div>
                    </div>
                ))}
            </div>

            {asset.header && (
                <>
                    <p>The selected header is:</p>
                    <p>{extractFilename(asset.header)}</p>
                </>
            )}
            {showHeaderModal && (
                <HeaderUploadModal
                    isOpen={showHeaderModal}
                    onClose={() => setShowHeaderModal(false)}
                    dealSheetId={dealSheetId}
                    addHeaderImage={(newHeaderUrl) => {
                        addHeaderImage(newHeaderUrl);
                        setShowHeaderModal(false);
                    }}
                />
            )}
            <ConfirmationModal
                isOpen={showConfirmationModal}
                onClose={() => setShowConfirmationModal(false)}
                onConfirm={handleConfirmDeletion}
                title="Confirm Deletion"
                message="Are you sure you want to delete this header image?"
            />
        </div>
    );
};

export default HeaderSelectionStep;
