import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import './StoresPage.css';  // Reusing existing styles
import EditDealSheetModal from './EditDealSheetModal';
import ExportDealSheetModal from './ExportDealSheetModal';
import ExportDealSheetModal2 from './ExportDealSheetModal2';
import ConfirmationModal from './ConfirmationModal';
import AppHeader from './AppHeader';
import SecureConfirmationModal from './SecureConfirmationModal';

function DealSheetCreator() {

    const navigate = useNavigate();

    const [dealSheets, setDealSheets] = useState([]);
    const [categories, setCategories] = useState([]);
    const [deals, setDeals] = useState([]);
    const store = JSON.parse(localStorage.getItem('selectedStore'));
    const [isEditModalVisible, setEditModalVisible] = useState(false);
    const [currentDealSheet, setCurrentDealSheet] = useState(null);

    const [isExportModalVisible, setExportModalVisible] = useState(false);
    const [currentDealSheetIdForExport, setCurrentDealSheetIdForExport] = useState(null);

    const [isConfirmationModalVisible, setConfirmationModalVisible] = useState(false);
    const [dealSheetToDelete, setDealSheetToDelete] = useState(null);

    const [isSecureConfirmationModalVisible, setSecureConfirmationModalVisible] = useState(false);

    const handleDeleteClick = (dealSheetId) => {
        setDealSheetToDelete(dealSheetId);
        setConfirmationModalVisible(true);
      };
    
      const handleConfirmDelete = () => {
        // Instead of immediately deleting, show the secure confirmation modal
        setConfirmationModalVisible(false); // Close the initial confirmation modal
        setSecureConfirmationModalVisible(true); // Open the secure confirmation modal
    };

    const handleActualDelete = () => {
        if (dealSheetToDelete !== null) {
          handleDeleteDealSheet(dealSheetToDelete);
          setDealSheetToDelete(null);
        }
        setSecureConfirmationModalVisible(false); // Close the secure confirmation modal
    };


    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_BASE_URL}/users/deal-sheets/?store_id=${store.id}`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'Selected-Store-Id': store.id
            }
        })
        .then(res => res.json())
        .then(data => setDealSheets(data))
        .catch(error => console.error("Error fetching deal sheets:", error));
    }, []);

    const handleAddDealSheet = () => {
        fetch(`${process.env.REACT_APP_API_BASE_URL}/users/deal-sheets/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({
                name: 'New DealSheet',
                store: store.id
            })
        })
        .then(res => res.json())
        .then(data => {
            setDealSheets(prevDealSheets => [...prevDealSheets, data]);
        })
        .catch(error => console.error("Error adding deal sheet:", error));
    };

    const handleEditDealSheet = (dealSheetId) => {
        const apiEndpoint = `${process.env.REACT_APP_API_BASE_URL}/users/deal-sheets/${dealSheetId}/?store_id=${store.id}`;

        fetch(apiEndpoint, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            }
        })
        .then(res => res.json())
        .then(data => {
            setCurrentDealSheet(data);
            setEditModalVisible(true);
        })
        .catch(error => console.error("Error fetching deal sheet details:", error));
    };

    const handleDeleteDealSheet = (dealSheetId) => {
        fetch(`${process.env.REACT_APP_API_BASE_URL}/users/deal-sheets/${dealSheetId}/`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            }
        })
        .then(() => {
            setDealSheets(prevDealSheets => prevDealSheets.filter(ds => ds.id !== dealSheetId));
        })
        .catch(error => console.error("Error deleting deal sheet:", error));
    };

    const handleViewDealSheet = (dealSheetId) => {
        localStorage.setItem('currentDealSheetId', dealSheetId);
        navigate('/dealsheetvisualization');
    };

    const handleAddCategory = (dealSheetId) => {
        // Add logic to create a new category for a deal sheet
    };

    const handleAddDeal = (categoryId) => {
        // Add logic to create a new deal for a category
    };

    const handleShowExportModal = (dealSheetId) => {
        setCurrentDealSheetIdForExport(dealSheetId);
        setExportModalVisible(true);
    };

    const handleDuplicateDealSheet = async (dealSheetId) => {
        try {
            const storeId = store.id; // Ensure the store ID is available
    
            // Fetch the original deal sheet data
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/deal-sheets/${dealSheetId}/?store_id=${storeId}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
    
            if (!response.ok) {
                throw new Error('Error fetching deal sheet data');
            }
    
            const originalDealSheet = await response.json();

            
    
            // Prepare the data for the new deal sheet
            const duplicatedDealSheetData = {
                ...originalDealSheet,
                name: `${originalDealSheet.name} Copy`, // Append "Copy" to the name
                id: null, // Remove the id to create a new instance
                store: storeId, // Ensure the store ID is correct
                categories: originalDealSheet.categories.map(category => ({
                    ...category,
                    id: null, // Remove category ID
                    dealsheet: null, // Remove dealsheet ID reference
                    linkeddeals: category.linkeddeals.map(deal => ({
                        ...deal,
                        id: null, // Remove deal ID
                        category: null // Remove category ID reference
                    }))
                }))
            };
    
            // Create the new deal sheet
            const createResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/deal-sheets/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify(duplicatedDealSheetData)
            });
    
            if (!createResponse.ok) {
                throw new Error('Error creating duplicated deal sheet');
            }
    
            const newDealSheet = await createResponse.json();

            // New API call to duplicate assets
            await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/duplicate-assets/`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    original_deal_sheet_id: dealSheetId,
                    new_deal_sheet_id: newDealSheet.id
                })
            });
            
            setDealSheets(prevDealSheets => [...prevDealSheets, newDealSheet]);
    
        } catch (error) {
            console.error("Error duplicating deal sheet:", error);
        }
    };
    
    
    
    

    return (
        <div className="stores-page">
            <AppHeader
                showBackButton={true}
                showSettings={true}
                showLogout={true}
                logoUrl="https://d27oebqltxtb0s.cloudfront.net/GreenMindSimpleLogo.png"
            />
            <div className="center-header">
                <button className="btn-add" onClick={handleAddDealSheet}>
                    <i class="fa fa-plus" aria-hidden="true"></i> New Dealsheet

                </button>
            </div>
            <div className="stores-list-container">
                <div className="stores-list">
                {dealSheets.map(dealSheet => (
                    <div key={dealSheet.id} className="store-row">
                        <button className="btn-play" onClick={() => handleViewDealSheet(dealSheet.id)}>
                            <i class="fa fa-eye" aria-hidden="true"></i>

                        </button>
                        <span className="store-name">{dealSheet.name}</span>
                        <button className="btn-Export" onClick={() => handleShowExportModal(dealSheet.id)}>
                            <i class="fa fa-floppy-o" aria-hidden="true"></i> Export
                        </button>
                        <button className="btn-edit" onClick={() => handleEditDealSheet(dealSheet.id)}>
                            <i class="fa fa-pencil-square-o" aria-hidden="true"></i> Edit
                        </button>
                        <button className="btn-duplicate" onClick={() => handleDuplicateDealSheet(dealSheet.id)}>
                            <i class="fa fa-clone" aria-hidden="true"></i> Duplicate
                        </button>
                        <button className="btn-delete" onClick={() => handleDeleteClick(dealSheet.id)}>
                            <i class="fa fa-trash-o" aria-hidden="true"></i> Delete
                        </button>

                    </div>
                ))}
                </div>
            </div>
            <ConfirmationModal
                isOpen={isConfirmationModalVisible}
                onClose={() => setConfirmationModalVisible(false)}
                onConfirm={handleConfirmDelete}
                title="Confirm Deletion"
                message="Are you sure you want to delete this deal sheet?"
            />
            <SecureConfirmationModal
                isOpen={isSecureConfirmationModalVisible}
                onClose={() => setSecureConfirmationModalVisible(false)}
                onConfirm={handleActualDelete}
                title="Are You Sure?"
                message="This action is permanent and cannot be undone."
            />

            {isEditModalVisible && 
                <div className="modal-background">
                    <div className="center-modal">
                        <EditDealSheetModal 
                            dealSheet={currentDealSheet}
                            categories={currentDealSheet ? currentDealSheet.categories : null}
                            selectedStore={store}
                            onClose={(updatedDealSheet) => {
                                setEditModalVisible(false);
                                if(updatedDealSheet) {
                                    setDealSheets(prevDealSheets => {
                                        return prevDealSheets.map(ds => ds.id === updatedDealSheet.id ? updatedDealSheet : ds);
                                    });
                                }
                            }}
                        />
                    </div>
                </div>
            }

            {isExportModalVisible && 
                <div className="modal-background">
                    <div>
                        <ExportDealSheetModal2
                            dealSheetId={currentDealSheetIdForExport}
                            onClose={() => setExportModalVisible(false)}
                        />
                    </div>
                </div>
            }
        </div>
    );
}

export default DealSheetCreator;
