import React, { useState } from 'react';
import './AIInsightsModal.css'; // Import the CSS file for styling

const AIInsightsModal = ({ isOpen, onClose, inventoryData }) => {
  const [prompt, setPrompt] = useState('');
  const [response, setResponse] = useState('');
  const [isLoading, setIsLoading] = useState(false); // State to track loading

  const selectedStore = JSON.parse(localStorage.getItem('selectedStore'));
  const selectedStoreId = selectedStore ? selectedStore.id : null;


  const handlePromptSubmit = async () => {
    if (!prompt) {
      alert("Please enter a prompt."); // Simple validation
      return;
    }
  
    const jwt = localStorage.getItem('token'); // Retrieve JWT token from local storage
    setIsLoading(true); // Start loading
  
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/gpt-insights/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${jwt}`, // Include JWT token in the Authorization header
          'Selected-Store-Id': selectedStoreId,
        },
        body: JSON.stringify({ prompt, inventoryData })
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      if (data.nl_response) {
        // Extract the content from the first choice's message
        const aiResponse = data.nl_response;
        setResponse(aiResponse);
      } else {
        setResponse('No response from AI');
      }
    } catch (error) {
      console.error('Error:', error);
      setResponse('Failed to get response from AI');
    } finally {
      setIsLoading(false); // End loading
    }
  };
  

  return (
    isOpen && (
      <div className="ai-insights-modal-overlay">
        <div className="ai-insights-modal-content">
          <button onClick={onClose} className="close-button">Close</button>
          <h2>Ask a question about your inventory</h2>
          <textarea
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            placeholder="Type your question here"
            className="prompt-input"
          />
          <button onClick={handlePromptSubmit} className="submit-button" disabled={isLoading}>
            {isLoading ? 'Loading...' : 'Submit'}
          </button>
          <div className="response-area">{response}</div>
        </div>
      </div>
    )
  );
};

export default AIInsightsModal;
