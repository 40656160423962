import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './StoreSelectionPage.css'; // Reuse the CSS

function DeviceSelectionPage() {
    const navigate = useNavigate();
    const [devices, setDevices] = useState([]);
    const [focusedButtonIndex, setFocusedButtonIndex] = useState(0);
    const deviceRefs = useRef([]);

    useEffect(() => {
        const selectedStore = JSON.parse(localStorage.getItem('selectedStore'));
        if (selectedStore) {
            fetchDevices(selectedStore.id);
        }
    }, []);

    useEffect(() => {
        const handleKeyDown = (e) => {
            let newIndex;
            if (e.key === 'ArrowDown') {
                newIndex = (focusedButtonIndex + 1) % devices.length;
                setFocusedButtonIndex(newIndex);
            } else if (e.key === 'ArrowUp') {
                newIndex = (focusedButtonIndex - 1 + devices.length) % devices.length;
                setFocusedButtonIndex(newIndex);
            } else if (e.key === 'Enter') {
                handleDeviceSelect(devices[focusedButtonIndex]);
            }

            if (newIndex !== undefined && deviceRefs.current[newIndex]) {
                deviceRefs.current[newIndex].focus(); // Set focus on the new button
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, [devices, focusedButtonIndex]);

    const fetchDevices = async (storeId) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/get-devices/?store_id=${storeId}`, {
                headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` },
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const devicesData = await response.json();
            setDevices(devicesData);
        } catch (error) {
            console.error("Error fetching devices:", error);
        }
    };

    const handleDeviceSelect = (device) => {
        localStorage.setItem('selectedDevice', JSON.stringify(device));
        navigate('/function-select'); // Navigate to the next page
    };

    return (
        <div className="store-selection-page">
            <div className='tv-wrapper'>
                <h2 className="title select-title">Select Your Device</h2>
                <div className="select-button-container">
                    {devices.map((device, index) => (
                        <button 
                            key={device.id}
                            className={`store-button select-store-button ${index === focusedButtonIndex ? 'focused' : ''}`}
                            onClick={() => handleDeviceSelect(device)}
                            ref={el => deviceRefs.current[index] = el}
                            tabIndex={-1} // Make sure all buttons can be focused programmatically but not via sequential keyboard navigation
                        >
                            {device.name}
                        </button>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default DeviceSelectionPage;
