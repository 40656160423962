// wizardSteps/AssetSizeStep.js
import React, { useState, useEffect } from 'react';
const AssetSizeStep = ({ asset, setAsset, predefinedSizes }) => {

    // Introduce a local state to manage the current size selection
    const [selectedSize, setSelectedSize] = useState(asset.size.name || 'HorizontalDealSheet');

    const [localWidth, setLocalWidth] = useState(asset.size.width || '');
    const [localHeight, setLocalHeight] = useState(asset.size.height || '');

    useEffect(() => {
        if (!predefinedSizes[selectedSize]) {
            if (selectedSize === 'Custom') {
                // Only update asset size for Custom if width and height are not empty
                if (localWidth !== '' && localHeight !== '') {
                    setAsset({
                        ...asset,
                        size: {
                            name: 'Custom',
                            width: parseInt(localWidth, 10),
                            height: parseInt(localHeight, 10),
                        },
                    });
                }
            } else {
                // Default to HorizontalDealSheet if it's not custom or dimensions are explicitly unset
                const defaultSize = predefinedSizes['HorizontalDealSheet'];
                setAsset({
                    ...asset,
                    size: {
                        name: 'HorizontalDealSheet',
                        width: defaultSize.width,
                        height: defaultSize.height,
                    },
                });
            }
        } else {
            // Handle predefined sizes
            const size = predefinedSizes[selectedSize];
            setAsset({
                ...asset,
                size: {
                    name: selectedSize,
                    width: size.width,
                    height: size.height,
                },
            });
        }
    }, [selectedSize, predefinedSizes, setAsset, localWidth, localHeight]);

    useEffect(() => {
        // Determine initial values based on the asset prop
        const initialSizeName = asset.size.name || 'HorizontalDealSheet';
        setSelectedSize(initialSizeName);
    
        // Set initial width and height based on the asset or predefined sizes
        if (initialSizeName === 'Custom') {
            setLocalWidth(asset.size.width.toString());
            setLocalHeight(asset.size.height.toString());
        } else {
            // Update local width and height to reflect predefined size or fallback
            const size = predefinedSizes[initialSizeName] || predefinedSizes['HorizontalDealSheet'];
            setLocalWidth(size.width.toString());
            setLocalHeight(size.height.toString());
            // Ensure the asset is updated only if not matching predefined size
            if (asset.size.name !== initialSizeName || asset.size.width !== size.width || asset.size.height !== size.height) {
                setAsset({
                    ...asset,
                    size: {
                        name: initialSizeName,
                        width: size.width,
                        height: size.height,
                    },
                });
            }
        }
    }, [asset, predefinedSizes, setAsset]); // This effect synchronizes the component with the asset prop
    
    

    const handleSizeChange = (sizeName) => {
        setSelectedSize(sizeName);
    };

    const getSizeDescription = (sizeName) => {
        switch(sizeName) {
            case 'VerticalDealSheet':
                return "This size is best for a letter size printout with a vertical layout.";
            case 'HorizontalDealSheet':
                return "This size is best for a letter size printout with a horizontal layout.";
            case 'TVAsset':
                return "This size fits an HDTV, 1920x1080 resolution.";
            case 'VerticalTVAsset':
                return "This size fits a Vertically Mounted HDTV, 1080x1920 resolution.";
            case 'Custom':
                return "Specify a custom size of the image in pixels.";
            default:
                return ""; // Default case if needed
        }
    };

    return (
        <div>
            <h2>Define Size</h2>
            <p>This will control the total size of the asset image.</p>
            <select value={selectedSize} onChange={e => handleSizeChange(e.target.value)}>
                <option value="VerticalDealSheet">Vertical Deal Sheet</option>
                <option value="HorizontalDealSheet">Horizontal Deal Sheet</option>
                <option value="TVAsset">TV Asset</option>
                <option value="VerticalTVAsset">Vertical TV Asset</option>
                <option value="Custom">Custom Size</option>
            </select>
            <p>{getSizeDescription(asset.size.name)}</p>
            {selectedSize === 'Custom' && (
                <>
                    <label htmlFor="width">Width:</label>
                    <input
                        id="width"
                        type="number"
                        placeholder="Width"
                        value={localWidth}
                        onChange={e => setLocalWidth(e.target.value)}
                    />
                    <label htmlFor="height">Height:</label>
                    <input
                        id="height"
                        type="number"
                        placeholder="Height"
                        value={localHeight}
                        onChange={e => setLocalHeight(e.target.value)}
                    />
                </>
            )}
        </div>
    );
};

export default AssetSizeStep;