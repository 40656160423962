import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './DealSheetVisualization.css';

function DealSheetVisualizationForPDF() {
    const [dealSheet, setDealSheet] = useState({ categories: [] });
    const [fetchedDeals, setFetchedDeals] = useState([]);
    const selectedStore = JSON.parse(localStorage.getItem('selectedStore'));
    const selectedStoreId = selectedStore ? selectedStore.id : null;
    const { id } = useParams();

    useEffect(() => {
        fetch(`http://127.0.0.1:8000/users/deal-sheets/${id}/`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then(res => res.json())
        .then(data => setDealSheet(data))
        .catch(error => console.error("Error fetching deal sheet details:", error));
    }, [id]);

    useEffect(() => {
        if (dealSheet && dealSheet.categories) {
            const dutchieDealIds = [];
            
            // Extract all dutchie deal IDs from the deal sheet
            dealSheet.categories.forEach(category => {
                category.linkeddeals.forEach(deal => {
                    if (deal.isDutchieDeal) {
                        dutchieDealIds.push(deal.discountId);
                    }
                });
            });
            
            if (dutchieDealIds.length > 0) {
                fetch(`http://127.0.0.1:8000/users/get-deals-by-ids/`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'Selected-Store-Id': selectedStoreId
                    },
                    body: JSON.stringify({ ids: dutchieDealIds })
                })
                .then(res => res.json())
                .then(data => {
                    setFetchedDeals(data);
                })
                .catch(error => console.error("Error fetching deal details:", error));
            }
        }
    }, [dealSheet, selectedStoreId]);

    const fetchDutchieDeals = () => {
        if (dealSheet && dealSheet.categories) {
            const dutchieDealIds = [];
            
            // Extract all dutchie deal IDs from the deal sheet
            dealSheet.categories.forEach(category => {
                category.linkeddeals.forEach(deal => {
                    if (deal.isDutchieDeal) {
                        dutchieDealIds.push(deal.discountId);
                    }
                });
            });
            
            if (dutchieDealIds.length > 0) {
                fetch(`http://127.0.0.1:8000/users/get-deals-by-ids/`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'Selected-Store-Id': selectedStoreId
                    },
                    body: JSON.stringify({ ids: dutchieDealIds })
                })
                .then(res => res.json())
                .then(data => {
                    setFetchedDeals(data);
                })
                .catch(error => console.error("Error fetching deal details:", error));
            }
        }
    };

    useEffect(() => {
        fetchDutchieDeals();  // Fetch immediately upon mounting
        const interval = setInterval(fetchDutchieDeals, 5 * 60 * 1000);  // Fetch every 5 minutes

        return () => clearInterval(interval);  // Cleanup on component unmount
    }, [dealSheet, selectedStoreId]);
    

    if (!dealSheet) return <div>Loading...</div>;

    return (
        <div className="deal-sheet-visualization-container" style={{ 
            backgroundImage: dealSheet.background_images && dealSheet.background_images.length ? `url(${dealSheet.background_images[0]})` : '',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
        }}>
            <div className="deal-sheet-header" style={{ backgroundImage: `url(${dealSheet.desktop_banner})` }}>
            </div>
            
            <div className="deal-sheet-visualization">
            {dealSheet.categories?.map((category) => (
                <div key={category.id} className="category-section">
                    <h2>{category.name}</h2>
                    <ul className="deals-list">
                        {category.linkeddeals?.map((deal, dealIndex) => {
                            let dealInfo;
                            let discount;
                            
                            if (deal.isDutchieDeal) {
                                const fetchedDeal = Array.isArray(fetchedDeals) ? fetchedDeals.find(d => d.discountId === deal.discountId) : null;
                            
                                if (fetchedDeal) {
                                    const parts = fetchedDeal.discountName.split('|');
                                    dealInfo = parts[0] ? parts[0].trim() : '';
                                    discount = parts[1] ? parts[1].trim() : '';
                                } else {
                                    // Fallback if for some reason the deal isn't found in fetchedDeals.
                                    dealInfo = "Dutchie Deal (Details not found)";
                                }
                            } else {
                                const parts = deal.description.split('|');
                                dealInfo = parts[0] ? parts[0].trim() : '';
                                discount = parts[1] ? parts[1].trim() : '';
                            }

                            return (
                                <li key={dealIndex} className="deal">
                                    <span className="deal-info">{dealInfo}</span>
                                    <div className="deal-divider"></div>
                                    <span className="deal-discount">{discount}</span>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            ))}
            </div>
        </div>
    );
}

export default DealSheetVisualizationForPDF;
