// wizardSteps/AssetTypeStep.js
import React from 'react';

const AssetTypeStep = ({ asset, setAsset, categories }) => {
    // Handler for changing the asset type
    const handleTypeChange = (e) => {
        const newType = e.target.value;
        // Reset category-related fields based on the selected asset type
        const resetCategories = newType === 'singleCategory' ? { category: '', selectedCategories: [] } : { selectedCategories: [] };
        setAsset({ ...asset, type: newType, ...resetCategories });
    };

    // Handler for changing the selected category (for single category type)
    const handleCategoryChange = (e) => {
        const selectedCategoryId = e.target.value;
        // Update the single category field and reset selectedCategories for consistency
        setAsset({ ...asset, category: selectedCategoryId, selectedCategories: selectedCategoryId ? [selectedCategoryId] : [] });
    };

    // Handler for toggling a category in the selectedCategories array (for multiple categories)
    const toggleCategorySelection = (categoryId) => {
        const isSelected = asset.selectedCategories.includes(categoryId);
        const updatedSelectedCategories = isSelected
            ? asset.selectedCategories.filter(id => id !== categoryId) // Remove category
            : [...asset.selectedCategories, categoryId]; // Add category
        
        // Update selectedCategories and reset single category for consistency
        setAsset({ ...asset, selectedCategories: updatedSelectedCategories, category: '' });
    };

    return (
        <div>
            <h2>Choose Asset Categories</h2>
            <select value={asset.type} onChange={handleTypeChange}>
                <option value="fullSheet">All Categories</option>
                <option value="selectCategories">Select Categories</option>
                <option value="singleCategory">Single Category</option>
            </select>
            {asset.type === 'singleCategory' && (
                <select onChange={handleCategoryChange} value={asset.category || ''}>
                    <option value="">Select Category</option>
                    {categories.map((category) => (
                        <option key={category.id} value={category.id}>{category.name}</option>
                    ))}
                </select>
            )}
            {asset.type === 'selectCategories' && (
                <div>
                    {categories.map((category) => (
                        <div key={category.id}>
                            <input
                                type="checkbox"
                                id={`cat-${category.id}`}
                                checked={asset.selectedCategories.includes(category.id)}
                                onChange={() => toggleCategorySelection(category.id)}
                            />
                            <label htmlFor={`cat-${category.id}`}>{category.name}</label>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default AssetTypeStep;
