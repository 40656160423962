import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { CardElement, Elements, useStripe, useElements } from '@stripe/react-stripe-js';
import './ProfileManager.css';  // Reuse the CSS for consistent styling
import ConfirmationModal from './ConfirmationModal';
import SecureConfirmationModal from './SecureConfirmationModal';


const stripePromise = loadStripe("pk_live_51OK7coHpLnGlIFM34rqWQmJTnUYAJm4BJGOUNYBK6RvS98k8bytOoApc6Hm0kfUBCLnJgsUmI2EfeyINbk1Nlx1L00LKPfN64a"); // Set your Stripe publishable key

function StoreSubscriptionManager() {
    const [stores, setStores] = useState([]);
    const [selectedStore, setSelectedStore] = useState(null);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [billingEmail, setBillingEmail] = useState("");
    const [loading, setLoading] = useState(false);
    const [isSecureModalOpen, setIsSecureModalOpen] = useState(false);

    const stripe = useStripe();
    const elements = useElements();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [cancelStoreId, setCancelStoreId] = useState(null);

    const navigate = useNavigate();

    // Function to open confirmation modal
    const openConfirmModal = (storeId) => {
        setCancelStoreId(storeId);
        setIsModalOpen(true);
    };

    // Function to handle the cancel confirmation
    const handleCancelConfirm = () => {
        setIsModalOpen(false); // Close the initial confirmation modal
        setIsSecureModalOpen(true); // Open the secure confirmation modal
    };
    
    const handleSecureCancelConfirm = async () => {
        setIsSecureModalOpen(false); // Close the secure confirmation modal
        if (cancelStoreId) {
            await handleCancelSubscription(cancelStoreId);
            setCancelStoreId(null);
            alert('Subscription canceled successfully.');
        }
    };
    

    const plans = [
        {
            name: 'Enterprise',
            price: '$499', // New price
            originalPrice: '$999', // Original price
            tierValue: 3,
            features: [
                'Advanced Reorder Reporting',
                'Live Customer Queue Line',
                'NextGen Dealsheet Creator',
                { feature: 'Remote Digital Signage', detail: 'unlimited screens' },
                'QR Generator & Tracker',
                'Digital Customer Facing Menu'
            ]
        },
        {
            name: 'Premium',
            price: '$299',
            originalPrice: '$499',
            tierValue: 2,
            features: [
                'Live Customer Queue Line',
                'NextGen Dealsheet Creator',
                { feature: 'Remote Digital Signage', detail: '5 screens' },
                'QR Generator & Tracker',
                'Digital Customer Facing Menu'
            ]
        },
        {
            name: 'Basic',
            price: '$99',
            originalPrice: '$299',
            tierValue: 1,
            features: [
                'Digital Customer Facing Menu',
                'QR Generator & Tracker',
                { feature: 'Remote Digital Signage', detail: '2 screens' },
                { feature: 'NextGen Dealsheet Creator', detail: 'LIMITED', limited: true }
            ]
        }
    ];
    

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_BASE_URL}/users/stores/`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then(res => res.json())
        .then(data => {
            // If data is an array, set it to stores, otherwise set stores to an empty array
            setStores(Array.isArray(data) ? data : []);
        })
        .catch(error => {
            console.error("Error fetching stores:", error);
            setStores([]); // Set to an empty array on error
        });
    }, []);

    // Function to cancel the subscription
    const handleCancelSubscription = async (storeId) => {
        // Send a request to your backend to cancel the subscription
        // You can implement the loading state as well if needed
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/cancel-subscription`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify({ store_id: storeId })
            });
            const data = await response.json();
            if (data.status === 'success') {
                console.log('Subscription cancelled successfully');
                // Optionally, update the UI or state to reflect the cancellation
            } else {
                console.error('Error cancelling subscription:', data.message);
            }
        } catch (error) {
            console.error('Error cancelling subscription:', error);
        }
    };

    const handlePlanSelect = (plan) => {
        const selectedPlan = plans.find(p => p.name === plan);
        setSelectedPlan(selectedPlan ? selectedPlan.tierValue : null);
    };

    const handlePaymentSubmit = async (event, storeId) => {
        event.preventDefault();
        if (!stripe || !elements || !selectedPlan || !billingEmail) {
            console.log('Stripe elements are not ready or no plan selected');
            return;
        }

        setLoading(true); // Disable buttons

        const cardElement = elements.getElement(CardElement);
        const paymentMethodResponse = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
        });

        if (paymentMethodResponse.error) {
            console.error(paymentMethodResponse.error);
            alert('Error creating payment method: ' + paymentMethodResponse.error.message);
            setLoading(false); // Enable buttons
            return;
        }

        
        fetch(`${process.env.REACT_APP_API_BASE_URL}/users/create-subscription`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({
                store_id: storeId,
                tier: selectedPlan,
                payment_method_id: paymentMethodResponse.paymentMethod.id,
                email: billingEmail
            })
        })
        .then(response => response.json())
        .then(data => {
            setLoading(false); // Enable buttons
            if (data.status === 'success') {
                alert('Subscription updated successfully.');
                navigate('/dashboard'); // Navigate to dashboard
            } else {
                alert('Error updating subscription: ' + data.message);
            }
        })
        .catch(error => {
            console.error("Error updating subscription:", error);
            alert('An error occurred while updating the subscription.');
            setLoading(false); // Enable buttons
        });
    };
    

    const renderPlanFeatures = (features) => {
    return features.map((feature, index) => {
        if (typeof feature === 'string') {
            return <li key={index} style={{ fontSize: '0.8em' }}>{feature}</li>;
        } else {
            return <li key={index} style={{ fontSize: '0.8em' }}>{feature.feature} ({feature.detail})</li>;
        }
    });
};

const renderSubscriptionForm = (store) => {
    if (!store) {
        return <div>Loading store information...</div>;
    }

    return (
        <div key={store.id}>
            <h3>Manage Subscription for {store.name}</h3>
            <div className="plans">
                {plans.map(plan => (
                    <div key={plan.name} className="plan">
                        {/*
                        <div className={`plan ${plan.name !== 'Enterprise' ? 'disabled' : ''}`}>
                        */}
                        <div className={`plan disabled`}>
                        <h2>{plan.name}</h2>
                        <p>
                            <span style={{ textDecoration: 'line-through', color: 'red', fontSize: '2.4em' }}>{plan.originalPrice}</span>
                            <span style={{ marginLeft: '10px', fontSize: '3em' }}>{plan.price}</span>
                        </p>
                        <p>Per Month</p>
                        {renderPlanFeatures(plan.features)}
                        <button 
                            className={`plan-button ${selectedPlan === plan.tierValue ? "selected" : ""}`}
                            onClick={() => handlePlanSelect(plan.name)}
                        >
                            Select Plan
                        </button>        
                        </div>   
                    </div>
                ))}
            </div>
            <form className='cardForm' onSubmit={(e) => handlePaymentSubmit(e, store.id)}>
                <CardElement />
                <input
                    type="email"
                    placeholder="Billing Email"
                    value={billingEmail}
                    onChange={(e) => setBillingEmail(e.target.value)}
                    disabled={loading}
                />
                <button type="submit" disabled={!selectedPlan || loading}>Subscribe/Update</button>
            </form>
            <span className='disclaimer'>*By clicking subscribe, you allow Greenmind Studio, LLC. to charge you for future payments in accordance with their terms. You can always cancel your subscription.</span>
        </div>
    );
};


    return (
        <div className='pagewrapper'>
            <div className='background-wrapper'>
                <div className="store-subscription-manager profile-manager">
                    <h2>Store Subscriptions</h2>
                    <div className='stores-scroll'>
                    {Array.isArray(stores) && stores.length > 0 ? (
                        stores.map(store => (
                            selectedStore && selectedStore.id === store.id ?
                                renderSubscriptionForm(selectedStore) :
                                <div key={store.id} className="user-details">
                                    <h3>{store.name}</h3>
                                    <p><strong>Valid Subscription:</strong> {store.subscription_tier ? 'Yes' : 'No'}</p>
                                    <button onClick={() => setSelectedStore(store)}>Manage Subscription</button>
                                    <button 
                                        onClick={() => openConfirmModal(store.id)}
                                        style={{ marginLeft: '10px', backgroundColor: '#f0f0f0', color: '#333' }}
                                    >
                                        Cancel Subscription
                                    </button>
                                </div>
                        ))
                    ) : (
                        <p>Loading stores...</p>
                    )}
                    </div>
                </div>
            </div>
            {/* Confirmation Modal */}
            <ConfirmationModal 
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onConfirm={handleCancelConfirm}
                title="Confirm Cancellation"
                message="Are you sure you want to cancel this subscription?"
                confirmButtonText="Yes, Cancel"
                cancelButtonText="No"
            />
            <SecureConfirmationModal
                isOpen={isSecureModalOpen}
                onClose={() => setIsSecureModalOpen(false)}
                onConfirm={handleSecureCancelConfirm}
                title="We'll miss you!"
                message="You can always rejoin and pick up where you left off. Type 'permanently delete' to confirm."
            />
        </div>
    );
    
    
    
}

function WrappedStoreSubscriptionManager() {
    return (
        <Elements stripe={stripePromise}>
            <StoreSubscriptionManager />
        </Elements>
    );
}

export default WrappedStoreSubscriptionManager;
