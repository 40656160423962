import React from 'react';
import Slider from 'react-slick';
import './CarouselComponent.css';

function CarouselComponent() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 888,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 8000
  };

  return (
    <div className="carousel-container">
      <Slider {...settings}>
        <div>
          <img src="./QRPreview1.png" alt="Image 1" />
        </div>
        <div>
          <img src="./QRPreview2.png" alt="Image 2" />
        </div>
      </Slider>
    </div>
  );
}

export default CarouselComponent;
