import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './StoreSelectionPage.css';
import './EditAppDevicePage.css'; // Reuse the same CSS file
import AppHeader from './AppHeader';

function EditDevicePage() {
    const navigate = useNavigate();
    const [devices, setDevices] = useState([]);
    const [slideshows, setSlideshows] = useState([]);
    const selectedStore = JSON.parse(localStorage.getItem('selectedStore'));
    const [loading, setLoading] = useState(true);
    const [newDeviceName, setNewDeviceName] = useState('New Device');

    useEffect(() => {
        const fetchData = async () => {
            if (selectedStore) {
                await fetchDevices(selectedStore.id);
                await fetchSlideshows(selectedStore.id);
                setLoading(false); // Set loading to false after data is fetched
            }
        };

        fetchData();
    }, [selectedStore.id]); // Ensure useEffect is dependent on selectedStore.id for correctness

    const fetchDevices = async (storeId) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/get-devices/?store_id=${storeId}`, {
                headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` },
            });
            if (!response.ok) {
                throw new Error('Failed to fetch devices');
            }
            const data = await response.json();
            
            // Natural sort the devices by name
            const sortedDevices = data.sort((a, b) => naturalSort(a.name, b.name));
    
            setDevices(sortedDevices);
        } catch (error) {
            console.error("Error fetching devices:", error);
        }
    };
    
    // Natural sort comparison function
    function naturalSort(a, b) {
        return a.localeCompare(b, undefined, {
            numeric: true,
            sensitivity: 'base'
        });
    }

    const fetchSlideshows = async (storeId) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/slideshows/`, {
                headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Selected-Store-Id': storeId,
                },
            });
            if (!response.ok) {
                throw new Error('Failed to fetch slideshows');
            }
            const data = await response.json();
            setSlideshows(data);
        } catch (error) {
            console.error("Error fetching slideshows:", error);
        }
    };

    const updateDevice = async (deviceToUpdate) => {
        try {
            // Ensure the device id is included in the request body
            const updatedDevice = {
                id: deviceToUpdate.id, // Make sure to include the device ID
                type: deviceToUpdate.type,
                target: deviceToUpdate.type === 'queue' ? selectedStore.id : deviceToUpdate.target,
            };
    
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/devices/update`, {
                method: 'POST', // Make sure the method matches the backend expectation
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
                body: JSON.stringify(updatedDevice),
            });
    
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error('Failed to update device: ' + JSON.stringify(errorData));
            }
            
            const responseData = await response.json();
            console.log('Device updated successfully:', responseData);
            // Optionally refresh the devices list to reflect the update
            fetchDevices(selectedStore.id);
        } catch (error) {
            console.error("Error updating device:", error);
        }
    };

    const deleteDevice = async (deviceId) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/devices/delete/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
                body: JSON.stringify({ id: deviceId })
            });
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(`Failed to delete device: ${errorData.error || 'Unknown error'}`);
            }
            // Refetch devices to reflect changes
            setDevices(devices.filter(device => device.id !== deviceId));
        } catch (error) {
            console.error("Error deleting device:", error);
        }
    };

    const handleDeviceTypeChange = (deviceId, newType) => {
        const deviceToUpdate = devices.find(device => device.id === deviceId);
        if (deviceToUpdate) {
            updateDevice({ ...deviceToUpdate, type: newType, target: newType === 'slideshow' ? deviceToUpdate.target : null });
        }
    };

    const handleDeviceNameChange = (event, deviceId) => {
        // Update local state to reflect the name change in the UI
        const updatedDevices = devices.map(device => {
            if (device.id === deviceId) {
                return { ...device, name: event.target.value };
            }
            return device;
        });
        setDevices(updatedDevices);
    };
    
    const saveDeviceName = async (deviceId) => {
        const deviceToUpdate = devices.find(device => device.id === deviceId);
        if (deviceToUpdate) {
            try {
                // Call the backend to update the device, including the new name
                const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/devices/update`, {
                    method: 'POST', // Confirm if this should be 'POST' or 'PUT' based on your API
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    },
                    body: JSON.stringify(deviceToUpdate),
                });
    
                if (!response.ok) {
                    throw new Error('Failed to update device');
                }
    
                // Optionally, refresh the devices list to reflect the update
                await fetchDevices(selectedStore.id);
            } catch (error) {
                console.error("Error updating device:", error);
            }
        }
    };

    const handleSlideshowChange = (deviceId, slideshowId) => {
        const deviceToUpdate = devices.find(device => device.id === deviceId);
        if (deviceToUpdate) {
            updateDevice({ ...deviceToUpdate, target: slideshowId });
        }
    };

    const addNewDevice = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/devices/create`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
                body: JSON.stringify({
                    name: 'My Device', // Default name
                    type: 'queue', // Default type
                    store: selectedStore.id, // Link to the current store
                }),
            });
    
            if (!response.ok) {
                throw new Error('Failed to create a new device');
            }
    
            // Refresh device list to include the new device
            await fetchDevices(selectedStore.id);
        } catch (error) {
            console.error("Error adding new device:", error);
        }
    };

    if (loading) {
        return <div>Loading...</div>; // Prevent rendering until data is fetched
    }

    return (
        <div className="appdevice-wholeDeviceAppPageContainer">
            <AppHeader
                showBackButton={true}
                showSettings={true}
                showLogout={true}
                logoUrl="https://d27oebqltxtb0s.cloudfront.net/GreenMindSimpleLogo.png"
            />
            <div className="appdevice-adddevicepageedits appdevice-store-selection-page2">
                <div className='appdevice-appdevicepageedits appdevice-tv-wrapper'>
                    <div className="appdevice-new-device-input">
                        <input
                            type="text"
                            placeholder="Enter Device Name"
                            value={newDeviceName}
                            onChange={e => setNewDeviceName(e.target.value)}
                            className="appdevice-input-field"
                        />
                        <button className="appdevice-btn-add" onClick={addNewDevice}>Add Device</button>
                    </div>
                    <div className="appdevice-scrollable-box">
                        {devices.map(device => (
                            <div key={device.id} className="appdevice-device-item-wrapper">
                                <div className="appdevice-device-item">
                                    <input
                                        type="text"
                                        value={device.name || ''}
                                        onChange={(e) => handleDeviceNameChange(e, device.id)}
                                        onBlur={() => saveDeviceName(device.id)}
                                        placeholder={`Device Name`}
                                        className="appdevice-input-field"
                                    />
                                    <select
                                        value={device.type || ''}
                                        onChange={(e) => handleDeviceTypeChange(device.id, e.target.value)}
                                        className="appdevice-btn-select"
                                    >
                                        <option value="slideshow">Slideshow</option>
                                        <option value="queue">Queue</option>
                                    </select>
                                    {device.type === 'slideshow' && (
                                        <select
                                            value={device.target || ''}
                                            onChange={(e) => handleSlideshowChange(device.id, e.target.value)}
                                            className="appdevice-btn-select-slideshow"
                                        >
                                            <option value="">Choose Slideshow</option>
                                            {slideshows.map(slideshow => (
                                                <option key={slideshow.id} value={slideshow.id}>{slideshow.name}</option>
                                            ))}
                                        </select>
                                    )}
                                    <button className="appdevice-btn-delete" onClick={() => deleteDevice(device.id)}>Delete</button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EditDevicePage;
