import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './StoreSelectionPage.css';

function StoreSelectionPage() {
    const navigate = useNavigate();
    const [stores, setStores] = useState([]);
    const [focusedButtonIndex, setFocusedButtonIndex] = useState(0); // State for tracking focused button
    const [isStoreSelected, setIsStoreSelected] = useState(false);
    const storeRefs = useRef([]); // Refs for buttons

    useEffect(() => {
        const savedStore = localStorage.getItem('selectedStore');
        if (savedStore) {
            navigateToFunctionSelect();
        } else {
            fetchStores();
        }
    }, []);

    useEffect(() => {
        storeRefs.current = storeRefs.current.slice(0, stores.length); // Resize refs array
    }, [stores]);

    useEffect(() => {
        const handleKeyDown = (e) => {
            let newIndex;
            if (e.key === 'ArrowDown') {
                newIndex = (focusedButtonIndex + 1) % stores.length;
                setFocusedButtonIndex(newIndex);
            } else if (e.key === 'ArrowUp') {
                newIndex = (focusedButtonIndex - 1 + stores.length) % stores.length;
                setFocusedButtonIndex(newIndex);
            } else if (e.key === 'Enter') {
                handleStoreSelect(stores[focusedButtonIndex]);
            }

            if (newIndex !== undefined) {
                storeRefs.current[newIndex].focus(); // Set focus on the new button
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, [stores, focusedButtonIndex]);

    const fetchStores = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/stores/`, {
                headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const storesData = await response.json();
            setStores(storesData);
        } catch (error) {
            console.error("Error fetching stores:", error);
        }
    };

    const handleStoreSelect = (store) => {
        if (isStoreSelected) {
            const confirmMessage = `Do you want to proceed with the store: ${store.name}?`;
            if (window.confirm(confirmMessage)) {
                localStorage.setItem('selectedStore', JSON.stringify(store));
                navigateToFunctionSelect();
            }
        } else {
            setIsStoreSelected(true); // Mark the store as selected
            localStorage.setItem('selectedStore', JSON.stringify(store));
        }
    };

    const navigateToFunctionSelect = () => {
        navigate('/setup-device');
    };

    return (
        <div className="store-selection-page">
            <div className='tv-wrapper'>
                <h2 className="title select-title">Choose Your Store</h2>
                <div className="select-button-container">
                    {stores.map((store, index) => (
                        <button 
                            key={store.id}
                            className={`store-button select-store-button ${index === focusedButtonIndex ? 'focused' : ''}`}
                            onClick={() => handleStoreSelect(store)}
                            tabIndex={0}
                            ref={el => storeRefs.current[index] = el} // Assign ref
                        >
                            {store.name}
                        </button>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default StoreSelectionPage;