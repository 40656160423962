import React, { useRef, useState } from 'react';
import StepWizard from 'react-step-wizard';
import IntroductionStep from './wizardSteps/IntroductionStep';
import NameYourAssetStep from './wizardSteps/NameYourAssetStep';
import BackgroundSelectionStep from './wizardSteps/BackgroundSelectionStep';
import AssetTypeStep from './wizardSteps/AssetTypeStep';
import AssetSizeStep from './wizardSteps/AssetSizeStep';
import AdjustZoomStep from './wizardSteps/AdjustZoomStep';
import SyncOptionsStep from './wizardSteps/SyncOptionsStep';
import HeaderSelectionStep from './wizardSteps/HeaderSelectionStep';
import PreviewDealSheetStep from './wizardSteps/PreviewDealSheetStep';

import './AssetCreationWizard.css';
import { v4 as uuidv4 } from 'uuid';
// Import other necessary data like backgroundImages, predefinedSizes

const AssetCreationWizard = ({ onClose, onSave, backgroundImages, headerImages, addHeaderImage, predefinedSizes, categories, slideshows, dealSheetId, addBackgroundImage, refreshBackgroundImage, refreshHeaderImage }) => {    const [asset, setAsset] = useState({
      name: '',
      background: null,
      type: 'fullSheet',
      size: { name: 'HorizontalDealSheet', width: 1920, height: 1080 },
      zoom: 1,
      shouldSync: false,
      sync: false,
      download: true,
      export: true,
      selectedCategories: [],
      smartScheduleEnabled: false,
      font: 'Arial',
    });

    const wizard = useRef();
    const [currentStep, setCurrentStep] = useState(1);
    const [isPreviewStepActive, setIsPreviewStepActive] = useState(false);
    const totalSteps = 8; // Manually set based on the number of steps you have

    const handleNext = () => {
        if (currentStep < totalSteps) {
            setCurrentStep(currentStep + 1);
            wizard.current.nextStep();
        }
    };

    const handlePrevious = () => {
        if (currentStep > 1) {
            setCurrentStep(currentStep - 1);
            wizard.current.previousStep();
        }
    };

    const handleStepChange = (stats) => {
        console.log(stats); // Debugging line to see the stats object
        const previewStepIndex = 8; // Adjust based on your actual step order
        setIsPreviewStepActive(stats.activeStep === previewStepIndex);
    };

    const handleSave = () => {
        if (onSave) {
            const newAssetWithIdent = {
                ...asset,
                ident: uuidv4(), // Add a unique identifier to the asset
            };
            onSave(newAssetWithIdent); // Pass the asset with ident to the onSave callback
            onClose(); // Optionally close the wizard after saving
        }
    };

    return (
        <div className="wizard-modal-background">
            <div className="wizard-modal-content">
                <StepWizard ref={wizard} onStepChange={handleStepChange}>
                    <IntroductionStep />
                    <NameYourAssetStep asset={asset} setAsset={setAsset} />
                    <BackgroundSelectionStep asset={asset} setAsset={setAsset} backgroundImages={backgroundImages} dealSheetId={dealSheetId} addBackgroundImage={addBackgroundImage} refreshBackgroundImage={refreshBackgroundImage} />
                    <HeaderSelectionStep asset={asset} setAsset={setAsset} headerImages={headerImages} dealSheetId={dealSheetId} addHeaderImage={addHeaderImage} refreshHeaderImage={refreshHeaderImage} />
                    <AssetTypeStep asset={asset} setAsset={setAsset} categories={categories} />
                    <AssetSizeStep asset={asset} setAsset={setAsset} predefinedSizes={predefinedSizes} />
                    <PreviewDealSheetStep asset={asset} setAsset={setAsset} dealSheetId={dealSheetId} isActive={isPreviewStepActive} />
                    <SyncOptionsStep asset={asset} setAsset={setAsset} slideshows={slideshows} categories={categories} />
                </StepWizard>
                <div className="wizard-controls">
                    {currentStep > 1 && <button onClick={handlePrevious}>Previous</button>}
                    {currentStep < totalSteps && <button onClick={handleNext}>Next</button>}
                    {currentStep === totalSteps && <button onClick={handleSave}>Save</button>}
                    <button className="wizard-close-button" onClick={onClose}>Close</button>
                </div>
            </div>
        </div>
    );
};

export default AssetCreationWizard;