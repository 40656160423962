import React, { useState, useEffect } from 'react';
import './QueueSettingsPage.css';

function QueueSettingsPage() {
    const [logoFile, setLogoFile] = useState(null);
    const [store, setStore] = useState(null);
    const [queueBackgroundImage, setQueueBackgroundImage] = useState(null);
    const [primaryTextColor, setPrimaryTextColor] = useState("#FFFFFF");
    const [secondaryTextColor, setSecondaryTextColor] = useState("#FFFFFF");
    const [queueRoomName, setQueueRoomName] = useState(store ? store.queue_room_name : 'Walk In/Lobby');
    const [logoSizeMultiplier, setLogoSizeMultiplier] = useState(store ? store.logo_size_multiplier : 1.0);
    const [maxNamesToDisplay, setMaxNamesToDisplay] = useState(store ? store.max_names_to_display : 10);
    const [nextInLineText, setNextInLineText] = useState(store ? store.next_in_line_text : 'Next In Line:');


    useEffect(() => {
        const rawStore = localStorage.getItem('selectedStore');
            const idstore = rawStore ? JSON.parse(rawStore) : null;
            const selectedStoreId = idstore ? idstore.id : null;
        if (selectedStoreId) {
            fetch(`${process.env.REACT_APP_API_BASE_URL}/users/stores/${selectedStoreId}/store-details/`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            })
            .then(res => res.json())
            .then(data => {
                setStore(data);
                setPrimaryTextColor(data.primary_text_color || "#FFFFFF");
                setSecondaryTextColor(data.secondary_text_color || "#FFFFFF");
                setLogoSizeMultiplier(data.logo_size_multiplier || 1.0);
                setQueueRoomName(data.queue_room_name || "Walk In/Lobby");
                setMaxNamesToDisplay(data.max_names_to_display || 10);
                setNextInLineText(data.next_in_line_text || "Next In Line:");
            })
            .catch(error => console.error("Error fetching store data:", error));
        }
    }, []);

    const handleLogoChange = (e) => {
        setLogoFile(e.target.files[0]);
    };

    const handleQueueBackgroundImageChange = (e) => {
        setQueueBackgroundImage(e.target.files[0]);
    };

    const uploadFile = async (file) => {
        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/upload_file/`, {
                method: 'POST',
                body: formData,
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            const jsonResponse = await response.json();

            if (response.ok) {
                return jsonResponse.fileURL.split("?")[0].replace(/ /g, '+');
            } else {
                console.error("Error uploading file. Status:", response.status, "Text:", await response.text());
                return null;
            }
        } catch(err) {
            console.error("Error uploading file:", err);
            return null;
        }
    };

    const saveQueueSettings = async () => {

        let logoUrl = store.logo_url; // Default to current logo URL
        if(logoFile) {
            logoUrl = await uploadFile(logoFile);
        }

        let queueBgUrl = store.queue_bg_url;
        if(queueBackgroundImage) {
            queueBgUrl = await uploadFile(queueBackgroundImage);
        }

        const updatedStore = { 
            ...store,
            logo_url: logoUrl,
            queue_bg_url: queueBgUrl,
            primary_text_color: primaryTextColor,
            secondary_text_color: secondaryTextColor,
            queue_room_name: queueRoomName,
            logo_size_multiplier: logoSizeMultiplier,
            max_names_to_display: maxNamesToDisplay,
            next_in_line_text: nextInLineText,
        };

        fetch(`${process.env.REACT_APP_API_BASE_URL}/users/stores/${store.id}/`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify(updatedStore)
        })
        .then(res => {
            if (res.ok) {
                setStore(updatedStore);
                alert('Queue settings updated successfully');
            } else {
                alert('Failed to update queue settings');
            }
        })
        .catch(error => console.error("Error updating store:", error));
    };

    if (!store) {
        return <div>Loading...</div>;
    }

    return (
        <div className="queue-background-settings">
        <div className="queue-settings-container">
            <h2>Edit Queue Settings for {store.name}</h2>
            <div className="queue-settings-field">
                    <label htmlFor="uploadLogo">Upload Logo:</label>
                    <input 
                        id="uploadLogo"
                        type="file" 
                        onChange={handleLogoChange}
                    />
                </div>
                <div className="queue-settings-field">
                <label>Logo Size Multiplier:</label>
                <input 
                    type="range" 
                    min="0.5" 
                    max="2.0" 
                    step="0.1" 
                    value={logoSizeMultiplier} 
                    onChange={(e) => setLogoSizeMultiplier(parseFloat(e.target.value))}
                />
            </div>
            <div className="queue-settings-field">
                <label>Queue Background Image:</label>
                <input type="file" onChange={handleQueueBackgroundImageChange} />
            </div>
            <div className="queue-settings-field">
                <label>Dutchie POS Sales Floor Room Name:</label>
                <input type="text" value={queueRoomName} onChange={(e) => setQueueRoomName(e.target.value)} />
            </div>
            <div className="queue-settings-field">
                <label>Primary Text Color:</label>
                <input type="color" value={primaryTextColor} onChange={(e) => setPrimaryTextColor(e.target.value)} />
            </div>
            <div className="queue-settings-field">
                <label>Secondary Text Color:</label>
                <input type="color" value={secondaryTextColor} onChange={(e) => setSecondaryTextColor(e.target.value)} />
            </div>
            <div className="queue-settings-field">
                <label htmlFor="maxNamesToDisplay">Number of names to list:</label>
                <input
                    id="maxNamesToDisplay"
                    type="number"
                    min="1"
                    max="20" // Assuming a reasonable maximum
                    value={maxNamesToDisplay}
                    onChange={(e) => setMaxNamesToDisplay(parseInt(e.target.value, 10))}
                />
            </div>
            <div className="queue-settings-field">
                <label htmlFor="nextInLineText">"Next In Line" Text:</label>
                <input
                    id="nextInLineText"
                    type="text"
                    value={nextInLineText}
                    onChange={(e) => setNextInLineText(e.target.value)}
                />
            </div>

            <button className="save-button" onClick={saveQueueSettings}>Save Queue Settings</button>
        </div>
        </div>
    );
}

export default QueueSettingsPage;
